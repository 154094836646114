@keyframes illFade {

    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.ill_img {
    vertical-align: top;

    &:not(.imgLoaded) {
        background: url(../svg/loader.svg) center no-repeat;
    }

    > img {
        vertical-align: top;
        animation: illFade 0.6s linear;
    }
}
