/********************/
/*     STYLES      */
/********************/
@import "var/fonts";

/* IMPORT OWN FONTS*/
@import"../scss/var/fonts";
@import "var/colors";

@import "../../../_app/_src/scss/desktop/app";

@import "mixins/clearfix";
@import "mixins/sprite_svg";
@import "mixins/wshopFont";
@import "mixins/wshopFormItems";
@import "mixins/wshopTruncate";
@import "mixins/wshopQueries";


@import "components/set_sprites";



@import "var/buttons";
@import "var/forms";

/* IMPORT BANK THANKS */
@import "../../../_app/_src/scss/desktop/pages/bank/bank_thanks";

@import "components/reset";
@import "components/global";
@import "components/header";
@import "components/breadcrumbs";
@import "components/footer";
@import "components/gondoles";
@import "components/w-forms";
@import "components/lightbox";


@import "plugin/_swiper_slider";
@import "plugin/_lionbars";

@import "components/slider";
@import "pages/home";
@import "pages/account";
@import "pages/satellite";
@import "pages/boutique";
@import "pages/cart";
@import "pages/product";
@import "intlTelInput/intlTelInput.scss";

