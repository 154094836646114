/* GONDOLES */
.gondoleHome {
    .gondoleWrapper {
        background: #202429;

        &:nth-child(2) {
            background: #1b1d20;
        }

        &:nth-child(3) {
            background: #151515;
        }

        &:nth-child(4) {
            background: #101010;
        }

        &:nth-child(5) {
            background: #020202;
        }

        .itemWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            width: 95%;
            margin: 0 auto;
            min-height: 500px;
        }

        .gondoleImgWrapper {
            position: relative;
            margin: 35px 0 35px 0;
            width: 62%;
            max-width: 957px;
            background-repeat: no-repeat;
            background-size: 100% auto;
            background-position: center;

            @include queries(mediumDesk) {
                width: 55%;
            }

            .hiddenImg {
                opacity: 0;
                width: 100%;
                height: auto;
                vertical-align: top;
            }

            .gondoleType,
            .gondoleReseaux,
            .gondoleGallery {
                position: absolute;
            }

            .gondoleType {
                top: 1vw;
                left: 1vw;
                @include wshopFont($dinFont, 14px, 0.1rem, $bold, #1b2028);
                text-transform: uppercase;
            }

            .gondoleReseaux {
                bottom: 0.5vw;
                left: 1vw;

                .socialIcon {
                    width: 1.3vw;
                    height: 1.3vw;
                    cursor: pointer;
                }
            }

            .gondoleGallery {
                bottom: 0.5vw;
                right: 0.5vw;
                @include wshopFont($dinFont,
                    12px,
                    $letterSpacing,
                    $bold,
                    #1b2028);
                text-transform: uppercase;
                background-color: rgba(#fff, 1);
                padding: 3px;
                border-radius: 3px;

                .gallery {
                    vertical-align: -0.3vw;
                }

                .labelGondoleGallery {
                    text-decoration: underline;
                }
            }
        }

        .numGondole {
            width: 150px;
            @include wshopFont($adamFont,
                146px,
                $letterSpacing,
                $medium,
                $white );
            line-height: 160px;
            position: absolute;
            top: 6%;
            right: -100px;

            @include queries(smallDesk) {
                width: 90px;
                font-size: 85px;
                right: -60px;
            }

            span {
                display: block;
                @include wshopFont($dinFont,
                    12px,
                    $letterSpacing,
                    $medium,
                    $white );
                line-height: 18px;
                text-align: center;
                padding: 0 0 0 30px;

                @include queries(smallDesk) {
                    padding: 0 0 0 10px;
                }

                &:before {
                    content: '';
                    display: block;
                    width: 12px;
                    margin: 0 auto;
                    border-top: 2px solid #fff;
                }

                &:after {
                    content: '';
                    display: block;
                    width: 3vw;
                    height: 3vw;
                    @include bgIconPath($pathIcon);
                    @include bgIconCoord(4, $col, $row);
                    margin: 0 auto;
                    transform: rotate(180deg);
                }
            }
        }

        .gondoleInfosWrapper {
            width: 38%;
            padding: 0 0 0 144px;

            @include queries(mediumDesk) {
                width: 45%;
            }

            @include queries(tablet) {
                padding: 0 0 0 86px;
            }

            h2 {
                display: inline-block;
                @include wshopFont($dinFont, 45px, -0.2rem, $bold, $white);
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 2.25px;

                &:after {
                    content: '';
                    display: block;
                    border-bottom: 1px solid #9d9d9d;
                }

                @include queries(mediumDesk) {
                    font-size: 42px;
                }

                @include queries(smallDesk) {
                    font-size: 36px;
                }
            }

            p {
                display: block;
                @include wshopFont($vidalokaFont,
                    14px,
                    $letterSpacing,
                    $regular,
                    $white );
                font-style: italic;
                padding: 1em 0 0 0;

                span {
                    font-size: 14px !important;
                }

                @include queries(smallDesk) {
                    font-size: 13px;
                }
            }

            .bottomInfosWrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .wrapper_price {
                    width: 50%;

                    .pricetag {
                        @include wshopFont($dinFont,
                            69px,
                            -0.2vw,
                            $bold,
                            $red1 );
                        line-height: 1;
                        color: $red1;

                        sup {
                            font-size: 0.5em;
                            vertical-align: 1.5rem;
                        }

                        @include queries(largeDesk) {
                            font-size: 59px;

                            sup {
                                vertical-align: 1.1rem;
                            }
                        }

                        @include queries(smallDesk) {
                            font-size: 45px;

                            sup {
                                vertical-align: 1.1rem;
                            }
                        }
                    }
                }

                button {
                    @include wshopFont($dinFont, 12px, 1px, $thin, $white);
                    max-width: 286px;
                    min-width: 50%;
                    flex-grow: 1;

                    @include queries(largeDesk) {
                        font-size: 0.75em;
                        letter-spacing: 0.5px;
                        width: 200px;
                    }

                    @include queries(smallDesk) {
                        font-size: 0.656rem;
                        letter-spacing: 0;
                        width: 170px;
                    }
                }
            }
        }

        /* North American T28 Corsair F4U-5N */
        &:nth-child(7) {
            .itemWrapper {
                .gondoleGallery {
                    display: none;
                }
            }

            .reservationSlide {
                &#reservationBox_105 {
                    .reservationFormWrapper {
                        .formLine.dateTime {
                            .formItem {
                                &.select {
                                    margin-right: 0;
                                    margin-left: 4.5rem;

                                    label {
                                        margin: 0.7rem 0 0 -4.4rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.caracGondole {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    .caracGondoleWrapper {
        width: 110px;
        height: 110px;
        background: transparent url('../img/bg_carac.png') no-repeat 0 0;
        background-size: cover;
        @include wshopFont($latoFont, 12px, $letterSpacing, $regular, #fcfbf1);
        text-transform: uppercase;
        text-align: center;
        position: relative;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -75%);
        }

        span {
            display: block;
            position: absolute;
            top: 70%;
            left: 0;
            width: 100%;
            text-align: center;
        }
    }
}