.cart,
.body_login.in_tunnel,
.checkout {
    .breadcrumbs_wrapper {
        display: none;
    }

    .contentWrapper {
        width: 100%;
        background: $white;

        .tunnel_track_pusher,
        .wrapper_panier {
            max-width: 1920px;
            margin: 0 auto;
        }
    }
}

.tunnel_track_pusher {
    height: 200px;
    background: transparent url('../img/bandeau_cart.jpg') no-repeat center;
    background-size: cover;

    .tunnel_track {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;

        @media screen and (max-width: 1700px) and (min-width: 600px) {
            left: -1rem;
            position: relative;
        }

        .tunnel_step {
            display: block;
            margin: 0 0.5rem;
            opacity: 0.5;
            text-align: center;

            span {
                @include wshopFont($dinFont, 11px, false, $bold, $black);
                text-transform: uppercase;

                &:before {
                    content: attr(data-step);
                    display: block;
                    @include wshopFont($adamFont, 74px, false, $bold, $black);
                }
            }

            &.on {
                opacity: 1;

                span {
                    font-size: 20px;
                    text-transform: uppercase;

                    &:before {
                        font-size: 113px;
                        border-bottom: 1px solid $black;
                        padding: 0 0 0.5rem 0;
                    }
                }
            }
        }
    }
}

.wrapper_panier {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .left_side {
        width: 60%;

        .empty_cart {
            @include wshopFont($dinFont, 20px, false, $bold, $black);
            text-align: center;
            padding: 12rem 0 0 0;
        }

        .cartShippingInfos {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            padding: 1rem 1rem;
            border-top: 1px solid rgba(0, 0, 0, 0.1);

            h3,
            p,
            .title_part {
                width: 100%;
                max-width: 882px;
            }

            h3 {
                @include wshopFont($dinFont, 18px, false, $bold, $black);
                text-transform: uppercase;
            }

            p {
                @include wshopFont($latoFont, 16px, false, $bold, $black);
            }

            .title_part {
                @include wshopFont($latoFont, 14px, false, $regular, $black);
            }
        }

        .cart_main_table {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 100%;
        }

        .cart_product_line {
            display: flex;
            justify-content: flex-end;
            align-items: stretch;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding: 2.5rem 5% 0 0;
            margin: 0 0 2.5rem 0;
            width: 100%;

            .topCartPlane {
                display: flex;
                justify-content: space-between;
                align-items: stretch;
                background: #202429;
                height: 274px;

                .reservationInfos {
                    color: $white;
                }
            }

            .cart_product_pic {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50%;
                max-width: 416px;
                position: relative;

                .cart_product_img {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .cart_product_desc {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                width: 50%;
                max-width: 416px;
                padding: 0 1rem;
                position: relative;

                .cart_product_title {
                    @include wshopFont($dinFont, 28px, false, $bold, $black);
                    text-transform: uppercase;
                    display: inline-block;
                    margin: 0 0 2rem 0;
                    padding: 0 0 0.4rem 0;
                    border-bottom: 1px solid $black;
                    line-height: 0.9;

                    @include queries(smallDesk) {
                        font-size: 24px;
                    }
                }

                .config_data {
                    width: 100%;
                    line-height: 20px;
                    @include wshopFont($latoFont,
                        14px,
                        false,
                        $regular,
                        $black );

                    .passenger {
                        text-transform: capitalize;
                    }

                    label {
                        font-weight: 700;
                    }
                }

                .cart_product_sizecol {
                    @include wshopFont($latoFont, 14px, false, $bold, $black);
                }

                .cart_product_quantity {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .wrapper_cart_product_price {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                    }

                    .quantityChangeWrapper {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        label {
                            @include wshopFont($dinFont,
                                14px,
                                false,
                                $bold,
                                $black );
                            text-transform: uppercase;
                            margin: 0 1rem 0 0;
                        }

                        .qte_selector {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .selector {
                                display: flex;
                                justify-content: space-around;
                                align-items: center;
                                height: 35px;
                                border: 1px solid rgba(0, 0, 0, 0.1);
                                border-radius: 2px;
                                position: relative;

                                input {
                                    border: 0px;
                                    width: 20px;
                                    text-align: center;
                                    font-family: sans-serif;
                                    @include wshopFont($dinFont,
                                        15px,
                                        false,
                                        $bold,
                                        $red1 );

                                    &.hidden {
                                        visibility: hidden;
                                    }
                                }

                                .change_qte {
                                    cursor: pointer;
                                    border: 0px;
                                    background: $white;
                                }

                                img.load_qte {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-65%, -50%);
                                }
                            }
                        }
                    }

                    .pricetag {
                        @include wshopFont($dinFont, 26px, false, $bold, $red1);
                        margin-top: -5px;

                        sup,
                        .cents {
                            font-size: 0.45em;
                        }

                        sup {
                            vertical-align: 1.1rem;
                        }

                        .cents {
                            margin: 0 0 0 -1rem;
                        }
                    }

                    .pricetag:nth-of-type(2) {
                        display: none;
                    }
                }
            }

            .cart_product_modify {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 100%;

                .product_del {
                    position: absolute;
                    top: 0;
                    right: -2rem;
                    @extend %close;

                    span {
                        display: none;
                    }

                    @media screen and (max-width: 1240px) and (min-width: 760px) {
                        right: 0rem;
                    }
                }

                .modifyBtn {
                    @include wshopFont($dinFont, 13px, false, $medium, $black);
                    text-transform: uppercase;

                    span {
                        text-decoration: underline;

                        &:after {
                            vertical-align: -0.2rem;
                            margin: 0 0 0 0.2rem;
                        }

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }

            &.planes {
                margin: 0;

                .cart_product_pic {
                    background: #202429;
                }

                .cart_product_desc {
                    background: #202429;
                    color: $white;

                    .config_data {
                        width: 100%;

                        #bloc_config_caract {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;

                            .caracGondole {
                                margin: 0.1rem 0;
                            }
                        }
                    }

                    .cart_product_title,
                    .cart_product_quantity .quantityChangeWrapper label {
                        color: $white;
                    }

                    .cart_product_title {
                        border-color: $white;
                    }

                    .cart_product_quantity .quantityChangeWrapper .qte_selector .selector {
                        background: $white;
                    }
                }
            }
        }

        .configInfos {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 1.5rem 5% 0 0;
            margin: 0 0 2.5rem 0;
            width: 100%;

            .calendarWrapper,
            .configCamera {
                width: 50%;
                max-width: 418px;
            }

            .configDate,
            .configCamera {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }

            .dateWrapper,
            .hourWrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                font-family: sans-serif;
                width: 45%;

                label {
                    @include wshopFont($dinFont, 12px, false, $bold, $black);
                    text-transform: uppercase;
                    margin: 0 0 0.2rem 0;
                }

                span {
                    display: inline-block;
                    //padding: 0 .5rem;
                    line-height: 2;
                    //border: 1px solid #bababa;
                    border-radius: 3px;
                }
            }

            .configCamera {
                .camera {
                    width: 2.5rem;
                    height: 2.5rem;
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 20%;
                        right: -0.5rem;
                        width: 1px;
                        height: 70%;
                        background: $black;
                    }
                }

                label {
                    @include wshopFont($dinFont, 12px, false, $medium, $black);
                    text-transform: uppercase;
                    margin: 0 0 0 1.5rem;
                }
            }
        }

        .wrapper_form_adresse {
            .factur_addr {
                &.createAccount {
                    margin-top: 1rem;
                }

                label {
                    @include wshopFont($dinFont, 12px, false, $medium, $black);
                    text-transform: uppercase;
                }
            }

            #adresseForm {
                width: 96%;
                max-width: 940px;
                padding: 2rem 4% 2% 0;
                margin: 0 0 0 auto;

                @media only screen and (width: 1080px) {
                    width: 106%;
                    max-width: 940px;
                    padding: 2rem 0% 2% 21px;
                    margin: 0 0 0 auto;
                }
            }

            .content_form {
                position: relative;
                margin: 0 0 0.5rem 0;

                &#clientPwd {
                    margin: 0 0 2rem 0;
                }

                .valid_form,
                .noaddr.delivery,
                .noaddr.bill {
                    position: absolute;
                    top: 50%;
                    right: 0.5rem;
                    transform: translateY(-50%);
                }

                .noaddr.delivery,
                .noaddr.bill {
                    display: none;
                    color: red;
                    font-family: sans-serif;
                    font-size: 11px;
                    cursor: pointer;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .threeCols {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .content_form {
                    width: 32.5%;
                }
            }

            .title_adr_type,
            .sub_title {
                @include wshopFont($dinFont, 12px, false, $medium, $black);
                text-transform: uppercase;
                line-height: 2;
            }

            .sub_title {
                display: block;
                margin: 1rem 0 0 0;
            }

            /*
            .address_title,
            .title_adr_type {
                display: none;
            }
            */
            .choix_type_adresse {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 66%;
                min-width: 625px;
                margin: 2rem 0 0.9375rem 0;
                height: 60px;

                .type_adresse {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 23%;
                    height: 100%;
                    border: 2px solid #767676;
                    @include wshopFont($dinFont,
                        14px,
                        0.1rem,
                        $semibold,
                        $black );
                    text-transform: uppercase;
                    cursor: pointer;
                    margin-right: 1rem;
                    margin-bottom: 0.9375rem;

                    &.actif {
                        border-color: $red1;
                        color: $red1;
                    }
                }
            }

            input[type='text'],
            input[type='password'],
            .formItem.select {
                height: 40px;
                border: 1px solid rgba(0, 0, 0, 0.3);
                text-indent: 0.5rem;
                box-sizing: border-box;
                font-size: 15px;

                &.inputErr {
                    border-color: red;
                }
            }

            .formItem.select {
                text-indent: 0;
            }

            input[type='text'],
            input[type='password'] {
                width: 100%;
                border-radius: 3px;
            }

            .wrapper_civility {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 40px;

                label:last-of-type {
                    margin: 0 0 0 2rem;
                }
            }

            .content_form.tel {
                width: 32.5%;

                .intl-tel-input {
                    width: 100%;
                }
            }

            .formSubmit {
                width: 200px;
                margin-bottom: 20px;
                min-height: 55px;
            }
        }

        .wrapperInfosDelivery {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;

            .wrapper_payment {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                width: 96%;
                max-width: 890px;
                padding: 35px 0;

                .elem_payment {
                    position: relative;
                    width: 35%;
                    padding: 2rem 0;

                    &:first-child {
                        margin: 0 20% 0 0;

                        &:after {
                            content: '';
                            display: block;
                            width: 1px;
                            height: 50px;
                            background: rgba(0, 0, 0, 0.3);
                            position: absolute;
                            top: 20%;
                            left: 100%;
                        }
                    }

                    &:last-child {}

                    .title_adress_payment {
                        @include wshopFont($dinFont,
                            15px,
                            false,
                            $bold,
                            $black );
                        text-transform: uppercase;
                    }

                    .adr_detail {
                        @include wshopFont($latoFont,
                            13px,
                            false,
                            $regular,
                            $black );
                        padding: 0.5rem 0 1rem 0;
                    }

                    .borderR {
                        width: 150px;
                        color: $red1;
                    }
                }
            }
        }

        .backStep {
            color: $red1;

            &:hover {
                color: $white;
            }
        }

        .bloc_livraison {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            padding: 40px 40px 0 0;

            &.backStep {
                padding: 40px 0 40px 0;

                .backStepWraper {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 96%;
                    max-width: 890px;
                }
            }

            .choix_delivery,
            .adresse_change {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 1px solid $black;
                position: relative;
                width: 96%;
                max-width: 890px;
                padding: 3rem;
            }

            .adresse_change {
                margin: -1px 0 0 0;

                .wrapper_select_adr,
                .formItem.select:not(.content_form) {
                    width: 100%;
                }

                .title_adr {
                    @include wshopFont($latoFont,
                        14px,
                        false,
                        $regular,
                        $black );
                    text-transform: uppercase;
                }

                .wrapper_form_adresse #adresseForm {
                    padding: 2rem 0 0 0;
                }
            }

            .choix_delivery {
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: -13px;
                    width: 26px;
                    height: 26px;
                    line-height: 26px;
                    text-align: center;
                    transform: translateY(-50%);
                    border: 1px solid $black;
                    background: $white;
                }

                &:hover:before,
                &.activate:before {
                    content: '✔';
                }

                .deliveryAddress {
                    width: 55%;
                    position: relative;

                    &:after {
                        content: '';
                        display: block;
                        width: 1px;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        right: 0;
                        background: $black;
                    }

                    p {
                        @include wshopFont($latoFont,
                            13px,
                            false,
                            $regular,
                            $black );

                        &:first-child {
                            font-weight: $bold;
                            font-size: 16px;
                            text-transform: uppercase;
                            margin: 0 0 0.5rem 0;
                        }

                        &:last-of-type {
                            margin: 0 0 1rem 0;
                        }
                    }
                }

                .logoTransporteur {
                    width: 30%;
                    text-align: center;

                    img {
                        margin: 0 0 0.6rem 0;
                    }

                    span {
                        @include wshopFont($latoFont,
                            13px,
                            false,
                            $regular,
                            $black );
                        display: block;
                    }
                }

                .deliveryPrice {
                    width: 10%;
                    text-align: right;
                    @include wshopFont($dinFont, 38px, false, $bold, $black);

                    .devise,
                    .cents {
                        font-size: 0.4em;
                    }

                    .devise {
                        vertical-align: 1rem;
                    }

                    .cents {
                        margin: 0 0 0 -0.5rem;
                    }
                }
            }
        }

        .encart_selection_offert {
            display: none;
        }

        .w-input-group {
            .w-form-line:last-of-type {
                .w-input-container {
                    @media only screen and (width: 1080px) {
                        width: 93%;
                    }
                }
            }
        }
    }

    .right_side,
    .bank_right_side {
        width: 40%;
        min-height: 750px;
        background: #f7f7f7;

        .tunnel_sticky {
            width: 372px;
            position: relative;
            margin: 0 0 0% 5%;
            padding-bottom: 3rem;
        }

        .main_table {
            border: 1px solid $black;
            background: $white;
            padding: 1rem 2rem;
            margin: 0 0 2rem 0;

            .order_total,
            .order_discount_ce,
            .order_promo_code,
            .bill_total,
            .coupon {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                height: 2.5rem;

                .mc_texte {
                    @include wshopFont($latoFont, 13px, false, $bold, #858585);
                    text-transform: uppercase;
                }

                .price {
                    @include wshopFont($dinFont, 26px, false, $bold, $black);

                    .devise,
                    .cents {
                        font-size: 0.4em;
                    }

                    .devise {
                        vertical-align: 0.7rem;
                    }

                    .cents {
                        margin: 0 0 0 -0.4rem;
                    }
                }
            }

            .bill_total {
                height: 4rem;

                .mc_texte {
                    font-size: 20px;
                    color: $black;
                }

                .price {
                    font-size: 38px;

                    .devise {
                        vertical-align: 1rem;
                    }
                }
            }

            .coupon_form {
                position: relative;
                width: 100%;
                height: 2.5rem;

                input[type='text'] {
                    width: 100%;
                    height: 100%;
                    border: 1px solid rgba(0, 0, 0, 0.3);
                    border-radius: 3px;
                    text-indent: 0.5rem;
                }

                input[type='submit'] {
                    cursor: pointer;
                    position: absolute;
                    right: 0.5rem;
                    top: 50%;
                    transform: translateY(-50%);
                    background: transparent;
                    border: 0px;
                    @include wshopFont($dinFont, 13px, false, $bold, $black);
                }
            }

            .wrapper_used_cp {
                display: flex;
                justify-content: space-between;
                align-items: center;
                @include wshopFont($dinFont, 13px, false, $bold, $black);

                .elem_right {
                    font-size: 16px;
                }
            }
        }

        #btn_cmd_valid {
            width: 100%;
            margin-bottom: 40px;
        }

        p.mention_consent {
            display: block !important;
            text-align: center;
            font-family: $primaryFont;
            font-size: 0.9rem;
            color: #444444;
            margin-top: 1.5rem;

            a {
                color: #444444;
                text-decoration: underline;
            }
        }

        .bloc_question {
            margin: 2rem 0 0 0;

            .title {
                @include wshopFont($latoFont, 15px, false, $bold, $black);
                text-transform: uppercase;
                margin: 0 0 0.5rem 0;
            }

            .intitule_question {
                @include wshopFont($latoFont, 14px, false, $regular, #444);
                margin: 0 0 0.2rem 0;
                cursor: pointer;
            }

            .texte_question {
                @include wshopFont($latoFont, 13px, false, $regular, #444);
                margin: 1rem 0 2rem 1rem;
            }

            .questions_frequentes {
                margin: 2rem 0 0 0;
            }

            .other_question {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                margin: 0 0 1rem 0;
                @include wshopFont($latoFont, 14px, false, $regular, #444);

                label {
                    display: block;
                    width: 96px;
                }

                .contact_schedule {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;

                    span + span {
                        font-size: 11px;
                    }
                }
            }

            a {
                @include wshopFont($latoFont, 14px, false, $regular, #444);
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.cartGoodies {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .btn_buy_wrapper {
        position: relative;
        width: 120px;
        height: 35px;
        line-height: 35px;
    }

    .cartGoodiesTitle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        width: 100%;

        div {
            width: 100%;
            max-width: 890px;

            p.goodiesTitle {
                text-align: center;
                @include wshopFont($dinFont, 46px, false, $bold, $black);
                text-transform: uppercase;
                line-height: 1;
                padding: 2rem 0 0 0;

                span {
                    position: relative;
                    display: inline-block;

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 1px;
                        top: 100%;
                        left: 0;
                        background: $black;
                    }
                }
            }

            p.goodiesSubTitle {
                @include wshopFont($vidalokaFont,
                    16px,
                    false,
                    $regular,
                    $black );
                text-align: center;
                font-style: italic;
                text-transform: initial;
                margin: 0.5rem 0 0 0;
                padding: 0 0 2rem 0;
            }
        }
    }

    .cartGoodiesWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 890px;
    }

    .goodiesSlider {
        .swiper-slide {
            .assoc_other_views {
                width: 100%;
                height: auto;
            }

            .rollover {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                background: #f4f4f4;
                padding: 1rem 0;
                opacity: 0;
                transition: all 0.3s ease;

                .rollover_contents_wrapper {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;

                    .colors_wrapper {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        label {
                            width: 1rem;
                            height: 1rem;
                            border-radius: 50%;
                            overflow: hidden;

                            &:before,
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }

            .wrapper_infos {
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: 1rem 0;

                h2 {
                    @include wshopFont($dinFont, 14px, false, $bold, $black);
                    text-transform: uppercase;
                    text-decoration: underline;
                }

                .price {
                    @include wshopFont($dinFont, 23px, false, $bold, $red1);

                    .devise,
                    .cents {
                        font-size: 0.4em;
                    }

                    .devise {
                        vertical-align: 0.6rem;
                    }

                    .cents {
                        margin: 0 0 0 -0.3rem;
                    }
                }

                .button {
                    width: 120px;
                    height: 35px;
                    line-height: 35px;
                }
            }

            &:hover {
                .rollover {
                    bottom: 77px;
                    opacity: 1;
                }
            }

            &.swiper-slide-next {
                border-left: 1px solid rgba(0, 0, 0, 0.1);
                border-right: 1px solid rgba(0, 0, 0, 0.1);
            }
        }
    }
}

.checkout {
    .left_side,
    .bank_left_side {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 2% 2% 2% 18.75%;

        @media screen and(max-width:1024px) {
            padding: 2%;
        }

        .method_wrapper.banque_erreur_txt {
            margin: 0 0 40px 0;
            padding: 2rem 2.5rem;
            position: relative;
            border: 1px solid #bababa;
            width: 96%;
            max-width: 750px;

            #bloc_erreur_order {
                .rose.displayNone {
                    display: none;
                }
            }

            .txt_recommencer_paiement  {
                display: none
            }
        }
    }

    .wrapper_panier .right_side .tunnel_sticky {
        margin: 5% 0 0 5%;
    }

    .bank_right_side {
        width: 40%;
        min-height: 750px;
        background: #f7f7f7;

        .top {
            width: 372px;
            margin: 5% 0 0 5%;
            padding: 3rem 3.5rem;
            border: 1px solid $black;
            background: $white;
            @include wshopFont($latoFont, 12px, false, $regular, $black);

            .title {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 18px;
                margin: 0 0 0.5rem 0;
                font-size: 16px;
                font-weight: $bold;
                text-transform: uppercase;
                line-height: 1;

                &:before {
                    @extend .accountIco;
                    width: 1rem;
                    height: 1rem;
                    margin: -0.1rem 0.5rem 0 0;
                }
            }

            p {
                font-family: sans-serif;
                text-align: justify;

                img {
                    vertical-align: middle;
                }
            }

            #wrapper_payement_secur {
                padding: 0 0 15px 0;

                .title:before {
                    @include bgIconCoord(12, $col, $row);
                }
            }

            #wrapper_need_help {
                padding: 15px 0 0 0;
                border-top: 1px solid #e6e6e6;

                .title:before {
                    @include bgIconCoord(11, $col, $row);
                }
            }

            a {
                color: $black;
                text-decoration: underline;
                text-transform: uppercase;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .bloc_question {
            margin: 5% 0 0 5%;
        }
    }

    .wrapper_bloc_banque,
    .bloc_paiement {
        margin: 0 0 40px 0;
        padding: 2rem 2.5rem;
        position: relative;
        border: 1px solid $ashGrey;
        width: 96%;
        max-width: 750px;

        h2 {
            @include wshopFont($dinFont, 16px, false, $bold, $black);
            text-transform: uppercase;
            margin: 0 0 1rem 0;
        }

        &#bloc_cb {
            padding: 2rem;

            h2 {
                float: left;
            }
        }

        &#bloc_cb_directlink {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .part {
                width: 100%;
                @include wshopFont($latoFont, 12px, false, $regular, $black);
            }

            .montant_trans {
                padding: 30px 0 0 0;
                font-size: 16px;
                font-weight: $bold;

                .pricetag {
                    margin: 0 0 0 1rem;
                    @include wshopFont($dinFont, 34px, false, $bold, $black);

                    .devise,
                    .cents {
                        font-size: 0.4em;
                    }

                    .devise {
                        vertical-align: 0.8rem;
                    }

                    .cents {
                        margin: 0 0 0 -0.5rem;
                    }
                }
            }

            .right_sides {
                width: 100%;
                padding: 20px 0 0 0;

                #form_global,
                .paiment_cb_option_carte,
                .form_line {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-end;
                }

                #form_global {
                    .cb_input_holder {
                        width: 50%;
                    }
                }

                .form_line {
                    margin: 15px 0 0 0;

                    .form_itm {
                        width: 33%;
                    }

                    &.cardNum {
                        justify-content: space-between;

                        .date_expiration {
                            padding: 0 0 0 5%;
                        }

                        @include queries(smallDesk) {
                            display: block;

                            .form_itm {
                                display: inline-block;
                                width: 45%;

                                &.cb_input_holder {
                                    display: block;
                                    width: 100%;
                                    margin: 0 0 1rem 0;
                                }
                            }

                            .date_expiration {
                                padding: 0;
                            }
                        }
                    }
                }

                .cb_input_holder {}

                .paiment_cb_option_carte {
                    width: 100%;
                    height: 34px;
                    justify-content: space-between;
                    align-items: center;
                    margin: 0 0 0 20px;

                    .form_select {
                        height: 25px;

                        img {
                            height: 100%;
                        }
                    }
                }

                p.mention_consent {
                    display: block !important;
                    text-align: center;
                    font-family: $primaryFont;
                    font-size: 0.9rem;
                    margin-top: 1.5rem;
                    padding: 0 3rem;

                    a {
                        text-decoration: underline;
                    }
                }
            }

            label {
                display: block;
                margin: 0 0 2px 0;
                @include wshopFont($latoFont, 12px, false, $regular, $black);
            }

            #name_without_card,
            #numero_without_card {
                width: 100%;
                height: 40px;
                border: 1px solid #bababa;
                border-radius: 2px;
                text-indent: 0.5rem;
            }

            #date_exp_mois_without_card,
            #date_exp_annee_without_card {
                width: 70px;
                height: 40px;
                border: 1px solid #bababa;
                border-radius: 2px;
                text-indent: 0.5rem;
            }

            #date_exp_mois_without_card {
                margin: 0 10px 0 0;
            }

            #cvc_without_card {
                width: 80px;
                height: 40px;
                border: 1px solid #bababa;
                border-radius: 2px;
                text-indent: 0.5rem;
            }

            #bt_valider {
                margin: 2rem 0 0 0;
                display: flex;
                justify-content: flex-end;

                .form_submit {
                    width: 200px;
                    height: 54px;
                }
            }
        }

        &#bloc_paypal {
            cursor: pointer;

            form {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        &:before {
            content: '✔';
            display: block;
            position: absolute;
            top: 50%;
            left: -12px;
            width: 24px;
            height: 24px;
            text-align: center;
            border: 1px solid #bababa;
            border-radius: 3px;
            background: $white;
            color: $white;
            margin: -12px 0 0 0;
        }

        &:hover:before,
        &.active:before {
            color: $black;
            box-shadow: inset 0px 0px 0px 1px $white;
        }

        &.banque_erreur_txt {
            text-align: center;

            p {
                font-size: 24px;
                margin: 0 0 2rem 0;
            }

            a {
                color: $black;
            }

            &:before {
                display: none;
            }
        }
    }

    #modbox_error {
        top: 50%;
        transform: translate(-50%, -50%);

        .light_title {
            @include wshopFont($dinFont, 26px, false, $bold, $black);
            text-transform: uppercase;
            text-align: center;
            padding: 3rem 0 2rem 0;
        }

        p {
            width: 500px;
            margin: 0 auto;
            padding: 0 6rem;
            text-align: center;
            @include wshopFont($latoFont, 14px, false, $regular, $black);
        }

        .form_submit {
            margin: 2rem 0 3rem 0;
            text-align: center;
        }
    }

    .bankThanksBtn {
        margin: 0 auto;
    }
}

.bank_confiance,
.total_transaction,
.bank_subtitle {
    font-family: sans-serif;
    font-size: 13px;
}

.bank_confiance {
    img {
        vertical-align: middle;
    }
}

.total_transaction {
    .pricetag {
        font-size: 20px;
        font-weight: bold;
        color: $red1;

        sup {
            font-size: 20px;
            vertical-align: baseline;
        }
    }
}

.alias_list_wrapper {
    margin-bottom: 35px;

    .alias_list {
        font-family: sans-serif;
        margin-top: 2rem;
        font-size: 12px;

        li {
            margin-bottom: 1rem;

            form {
                display: flex;
                justify-content: space-between;
                align-align: center;

                .card_logo,
                .card_details,
                .cardholder_name,
                .cvc_card,
                .delete_card,
                .use_card {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }

                .card_logo {
                    margin-right: 1rem;
                }

                .card_details {
                    width: 60px;
                    font-size: 11px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;

                    span:first-child {
                        font-weight: bold;
                    }
                }

                .cardholder_name {
                    width: 80px;
                }

                .cvc_card {
                    width: 60px;
                    height: 35px;

                    input {
                        width: 100%;
                        height: 100%;
                        border: 1px solid #ccc;
                        text-indent: 0.5rem;
                        padding: 0;
                    }
                }

                .delete_card {
                    button {
                        font-size: 11px;
                        appearance: none;
                        background: none;
                        border: 0;
                        text-decoration: underline;
                        cursor: pointer;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                .use_card {
                    button {
                        appearance: none;
                        border: 0;
                        height: 35px;
                        padding: 0 0.6rem;
                        background: #1f1f1f;
                        color: #fff;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.checkout {
    .cards-wrapper {
        margin-top: 1rem;
    }

    .flexco {
        .cards-wrapper {
            display: flex;
            align-items: center;
            margin-top: 0.5rem;

            img {
                vertical-align: top;
                width: auto;
                height: 1.9375rem;

                + img {
                    margin-left: 0.5rem;
                }
            }
        }

        #flex {
            width: 100%;
            height: 315px;
            border: 0;
            margin-top: 1rem;
        }
    }
}

.thanksWrapper {
    .bankThanksTop {
        max-width: 1340px;
        margin: 0 auto;

        h1 {
            @include wshopFont($dinFont, 24px, false, $bold, $black);
            text-transform: uppercase;
            margin: 2rem 0;
        }

        #around_me_map {
            margin: 0 auto;
        }
    }

    .thanksRecap {
        align-items: stretch;
        text-align: center;

        .thanksLeft {
            width: 60%;
            padding-top: 3rem;

            p {
                @include wshopFont($latoFont, 14px, false, $regular, $black);

                span {
                    display: block;
                    font-weight: $bold;
                }

                a {
                    color: $black;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }

                &.thanksTrust {
                    padding: 2rem 0;
                }
            }

            #clientForm {
                margin: 2rem 0;

                .input_container {
                    width: 400px;
                    height: 40px;
                    margin: 0 auto 0.5rem auto;
                    position: relative;

                    input {
                        width: 100%;
                        height: 100%;
                        border-radius: 3px;
                        border: 1px solid #ccc;
                        text-indent: 0.5rem;

                        &.inputErr {
                            border-color: $red1;
                        }
                    }
                }
            }

            a.bankThanksBtn {
                @extend .button;
                @extend .redB;
            }
        }

        .thanksRight {
            width: 40%;
            height: 650px;
            background-color: #f7f7f7;
            font-family: sans-serif;
            padding: 3rem 0 0 3rem;
            text-align: left;

            p {
                margin: 0 0 0.5rem 0;
                font-family: serif;
                font-size: 14px;

                &.txt_thanks_transaction {
                    text-transform: initial;
                }
            }
        }
    }
}

.banque_remerciements_container {
    width: 98%;
    text-align: center;
    margin: 0 0 2rem 0;
}

.what_is_it {
    position: relative;
    @include wshopFont($latoFont, 12px, false, $regular, $black);
    cursor: pointer;

    .show_what_is_it {
        display: none;
        position: absolute;
        background: $white;
        width: 330px;
        padding: 8px;
        border: 1px solid #e6e6e6;
    }
}

.wrapper_form_adresse {
    &.tunnel_form {
        .birthday {
            display: flex;
            align-items: center;

            .formItem {
                margin-right: 8px;
            }

            .more_info {
                font-size: 0.8em;
            }
        }
    }
}

.upselling_panier p {
    font-family: $latoFont;
    font-size: 14px;
    margin-top: 2rem;
}

/*
    Selection offert popup
*/
#selectionOffertePopup {
    width: 600px;
    max-width: 100%;
    padding: 2rem 3rem 3rem;
    padding: 2rem 3rem 3rem;
    height: unset;
    top: 10vh;

    .disable_valider {
        display: none;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    h2 {
        font-family: $dinFont;
        margin: 1.5rem 0 3rem;
        font-size: 1.3rem;
        line-height: 1.3;
        text-align: center;
    }

    .close_popup {
        img {
            margin-left: auto;
            display: block;
        }
    }

    .wrapper_product {
        .ligne_produit_offert {
            display: flex !important;
            flex-wrap: wrap;
            margin: 0 0 1rem;

            .first_bloc_produit_offert {
                width: 50%;
                max-width: 100%;
                position: relative;
                padding: 0 0 0 2rem;

                .btn_radio {
                    width: 14px;
                    height: 14px;
                    border: 1px solid #bababa;
                    position: absolute;
                    left: 0;
                    top: 0;

                    &.actif {
                        &:before {
                            opacity: 1;
                        }
                    }

                    &:before {
                        content: '';
                        width: 10px;
                        height: 10px;
                        background-color: $black;
                        position: absolute;
                        top: 1px;
                        left: 1px;
                        opacity: 0;
                        transition: opacity 0.2s ease-in;
                    }
                }
            }

            #form_produit_offert_12 #ligne_taille {
                display: none;
            }

            .product_offer_quantity {
                display: none;
            }

            .wrapper_other {
                margin-left: 2rem;
                width: 40%;
                max-width: 100%;
            }

            .product_title {
                font-family: $dinFont;
                font-size: 1.3rem;
                line-height: 1.3;
                font-weight: $semibold;
                margin: 0 0 0.2rem;
            }

            .product_subtitle {
                font-family: $vidalokaFont;
                font-size: 1rem;
                line-height: 1.3;
                font-weight: $light;
                font-style: italic;
                margin: 0 0 0.7rem;
            }

            .product_price {
                font-family: $latoFont;
                font-size: 0.85rem;
                line-height: 1.3;
                font-weight: $regular;
                margin: 0 0 0.3rem;
                text-transform: uppercase;
            }

            .select {
                select {
                    width: 100%;
                    padding: 0.6rem;
                    background: #fff url(../img/select_arrow_br.png) no-repeat 92% center;
                    background-size: 11px 8px;
                    appearance: none;
                    font-size: 0.75rem;
                    border-radius: 2px;
                    border: 1px solid #bababa;

                    &:focus {
                        outline: none;
                    }

                    &::-ms-expand {
                        display: none;
                    }
                }
            }

            .selectStyled {
                height: 35px;

                .clone {
                    height: 35px;
                    border-radius: 2px;
                    border: 1px solid #bababa;
                    font-size: 0.75rem;
                    padding: 0.6rem;
                    background: #fff url(../img/select_arrow_br.png) no-repeat 92% center;
                    background-size: 11px 8px;
                }
            }

            .third_bloc_produit_offert {
                label {
                    font-family: $dinFont;
                    font-size: 0.85rem;
                    line-height: 1.3;
                    font-weight: $medium;
                    text-transform: uppercase;
                    padding: 0.5rem 0;
                    display: inline-block;
                }
            }
        }
    }

    .btn_container {
        text-align: center;
        padding: 1rem 0 0;
    }

    .alert {
        font-family: $latoFont;
        font-size: 0.85rem;
        line-height: 1.3;
        font-weight: $medium;
    }
}

.ds_box {
    background-color: #fff;
    border: 1px solid #999999;
    position: absolute;
    z-index: 9500;
}

.ds_tbl {
    background-color: #dadada;
}

.ds_head {
    background-color: #999999;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    text-align: center;
    letter-spacing: 2px;
}

.ds_subhead2 {
    background-color: #cccccc;
    color: #666;
    font-size: 10px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
}

.ds_subhead {
    background-color: #cccccc;
    color: #000;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    width: 16px;
}

.ds_cell {
    background-color: #f5f5f5;
    color: #000;
    font-size: 11px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    padding: 2px;
    cursor: pointer;
}

.ds_cell2 {
    background-color: #e2e2e2;
    color: #000;
    font-size: 11px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    padding: 2px;
    cursor: pointer;
}

.ds_cell_inactive {
    background-color: #aaa;
    color: #000;
    font-size: 11px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    padding: 2px;
    cursor: default;
}

.ds_cell_inactive2 {
    background-color: #a2a2a2;
    color: #000;
    font-size: 11px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    padding: 2px;
    cursor: default;
}

.ds_cell:hover {
    background-color: #f3f3f3;
}

/* This hover code won't work for IE */
//Hide shipping fees
.hide_shippingfees {
    display: none !important;
}

body.cart {
    .w-input-element.pac-target-input {
        @media only screen and (width: 1080px) {
            width: 93%;
        }
    }
}