/**
 * SET SPRITES
 */

$col: 4;
$row: 4;

$colSocial: 4;
$rowSocial: 4;

$pathIcon: '../img/common.svg';
$pathAccount: '../img/account.svg';
$pathSocial: '../img/social_spritesheet.svg';

$widthIco: 4vw;
$widthAccount: 1em;
$widthSlider: 3.5em;


/* PATH ICON */

.icon {
    content: '';
    @include bgIconPath($pathIcon);
    @include bgIconCoord(8, $col, $row);
    width: $widthIco;
    height: $widthIco;
}

.logo {
    //content: '';
    //@include bgIconCoord(8, $col, $row);
    background-image: url('../img/ava-logo.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    width: 160px;
    height: 160px;
    display: block;
}

.accountIco {
    &:before {
        content: '';
        display: block;
        margin: 0 .5rem 0 0;
        @include bgIconPath($pathAccount);
        width: $widthAccount;
        height: $widthAccount;
    }
    &.infos:before {
        @include bgIconCoord(2, $col, $row);
    }
    &.adresses:before {
        @include bgIconCoord(3, $col, $row);
    }
    &.commandes:before {
        @include bgIconCoord(4, $col, $row);
    }
    &.messages:before {
        @include bgIconCoord(6, $col, $row);
    }
    &.newsletter:before {
        @include bgIconCoord(6, $col, $row);
    }
    &.download {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        line-height: 1;
        margin: 0 0 .5rem 0;
        &:before {
            @include bgIconCoord(13, $col, $row);
            margin: 0 .5rem;
        }
    }
}

%logoBlack {
    content: '';
    @include bgIconPath($pathIcon);
    @include bgIconCoord(13, $col, $row);
    width: 70px;
    height: 70px;
    display: block;
    @include queries(tablet) {
        width: 50px;
        height: 50px;
    }
}

%fleche {
    content: '';
    @include bgIconPath($pathIcon);
    @include bgIconCoord(14, $col, $row);
    width: 1.3rem;
    height: 1.3rem;
    display: block;
}

%arrowRed {
    content: '';
    @include bgIconPath($pathIcon);
    @include bgIconCoord(15, $col, $row);
    width: 1rem;
    height: 1rem;
    display: block;
}


/* SLIDER ARROWS */


/* Homepage slider */

.mainSliderHomeImg,
#galleyLightbox {
    .swiper-button-next:after,
    .swiper-button-next:before,
    .swiper-button-prev:after,
    .swiper-button-prev:before {
        @include bgIconPath($pathIcon);
        @include bgIconCoord(3, $col, $row);
        width: $widthSlider;
        height: $widthSlider;
    }
}


/* PATH SOCIAL */

.socialIcon {
    display: inline-block;
    @include bgIconPath($pathSocial);
    @include bgIconCoord(1, $colSocial, $rowSocial);
}

.facebook {
    @include bgIconCoord(1, $colSocial, $rowSocial);
}

.twitter {
    @include bgIconCoord(2, $colSocial, $rowSocial);
}

.youtube {
    @include bgIconCoord(3, $colSocial, $rowSocial);
}

.facebook.color {
    @include bgIconCoord(5, $colSocial, $rowSocial);
}

.twitter.color {
    @include bgIconCoord(6, $colSocial, $rowSocial);
}

.youtube.color {
    @include bgIconCoord(7, $colSocial, $rowSocial);
}

.facebook.black {
    @include bgIconCoord(9, $colSocial, $rowSocial);
}

.twitter.black {
    @include bgIconCoord(10, $colSocial, $rowSocial);
    margin: 0 0 0 .5vw;
}

.youtube.black {
    @include bgIconCoord(11, $colSocial, $rowSocial);
}


/* Gallery icon */

.gallery {
    display: inline-block;
    @include bgIconCoord(10, $col, $row);
    width: 1vw;
    height: 1vw;
}


/* Helmet in reservation lightbox */

.helmet {
    display: inline-block;
    @include bgIconCoord(11, $col, $row);
    width: $widthSlider;
    height: $widthSlider;
}


/* Camera in reservation lightbox */

.camera {
    display: inline-block;
    @include bgIconCoord(12, $col, $row);
    width: $widthSlider;
    height: $widthSlider;
}