.product_page {
    background: $white;

    .breadcrumbs {
        width: 80%;
        margin: 0.5rem auto 0;
        color: $black;

        a {
            color: $black;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        .breadcrumb:after {
            margin: 0 0.2rem;
        }

        @media screen and (min-width: 1000px) and (max-width: 1300px) {
            position: relative;
            right: -3.5rem;
        }
    }

    .contentWrapper {
        width: 99%;
        max-width: 1560px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 0 auto;
        border: 1px solid rgba(115, 155, 155, 0.4);
        border-top: none;
        border-bottom: none;
    }

    #bloc_add_alert {
        display: none !important;
    }
}

.page_produit {
    width: 60%;
    max-width: 800px;

    .product_main_wrapper {
        position: relative;

        .mainVisuWrapper {
            width: 100%;

            img {
                width: 100%;
                height: auto;
                vertical-align: top;
            }
        }

        .otherViewsWrapper {
            position: absolute;
            bottom: 1rem;
            right: 0.5rem;

            div {
                width: 110px;
                width: 110px;

                img {
                    width: 100%;
                    height: auto;
                    vertical-align: top;
                    cursor: pointer;
                }
            }
        }

        .eclat_product_epuise_fiche_produit {
            display: none;
        }
    }
}

.product_info_wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .product_info {
        display: block;
        width: 350px;

        h1.titleWithSubs {
            font-size: 60px;
            letter-spacing: -0.3rem;
            line-height: 1;
        }

        .item_price {
            display: flex;
            justify-content: center;
            align-items: center;

            .pricetag {
                position: relative;
                @include wshopFont($dinFont, 42px, false, $bold, $red1);

                + .pricetag {
                    color: #bbb;
                    margin-left: 1.5rem;

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 100%;
                        height: 1px;
                        background: #bbb;
                    }
                }
            }
        }

        .item_refext {
            margin: 4rem 0 0.5rem 0;
            @include wshopFont($dinFont, 13px, false, $bold, #bbb);
            text-transform: uppercase;
        }

        .itm_form_group {
            .ligne_form {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 60px;
                border-bottom: 1px solid #bbb;

                &:first-child() {
                    border-top: 1px solid #bbb;
                }

                label {
                    @include wshopFont($dinFont,
                        13px,
                        false,
                        $bold,
                        $bgHeaderFooter );
                    text-transform: uppercase;
                }

                .formItem.check.color {
                    display: inline-block;
                    margin: 0 0 0 1rem;

                    [type='radio']:checked + label:before,
                    [type='radio']:not(:checked) + label:before,
                    [type='radio']:checked + label:after,
                    [type='radio']:not(:checked) + label:after {
                        display: none;
                    }

                    [type='radio']:checked + label,
                    [type='radio']:not(:checked) + label:hover {
                        border-color: $bgHeaderFooter;
                    }
                }

                .formItem.check.size {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    .form_itm.check {
                        display: inline-block;
                        margin: 0 0 0 1rem;
                    }

                    [type='radio']:checked + label,
                    [type='radio']:not(:checked) + label {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 28px;
                        height: 28px;
                        font-size: 11px;
                    }

                    [type='radio']:not(:checked) + label {
                        border: 1px solid #ccc;
                    }

                    [type='radio']:checked + label,
                    [type='radio']:not(:checked) + label:hover {
                        border: 1px solid $bgHeaderFooter;
                    }

                    [type='radio']:checked + label:before,
                    [type='radio']:not(:checked) + label:before,
                    [type='radio']:checked + label:after,
                    [type='radio']:not(:checked) + label:after {
                        display: none;
                    }
                }

                .formItem.select {
                    width: 100%;
                    max-width: 150px;

                    &.no_stock {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }

        .button.redB {
            width: 100%;
        }

        .button.fwd {
            width: 100%;
            max-width: 350px;
        }

        #liste_qte.no_stock::after {
            display: none;
        }
    }

    .social_media {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;
        padding-bottom: 2rem;

        span {
            display: block;
            width: 2rem;
            height: 2rem;
            cursor: pointer;

            &.email {
                @include bgIconPath($pathSocial);
                @include bgIconCoord(1, $colSocial, $rowSocial);
                @include bgIconCoord(13, $colSocial, $rowSocial);
                background: url(../svg/mail_share.svg) no-repeat;
                background-position: 50%;
                background-size: 75% auto;
                margin-left: 1rem;
            }
        }
    }
}

#product_description {
    margin-bottom: 2rem;

    label {
        display: block;
        margin-top: 1.5rem;
        @include wshopFont($dinFont, 13px, false, $bold, $bgHeaderFooter);
        text-transform: uppercase;
    }

    p.item_descr {
        margin-top: 0.5rem;
        @include wshopFont($latoFont, 13px, false, $regular, #737272);
        line-height: 1.2;
    }
}

#sendfriendbox {
    position: fixed !important;
    // overflow-y: auto;
    height: 750px;
    width: 650px;
    padding: 17px 40px 15px;
    box-sizing: border-box;

    .lb-v-scrollbar {
        right: 0px;
        width: 6px;
        transform: scaleY(0.95);
        top: 0rem;

        &:before {
            content: '';
            width: 1px;
            background-color: rgba(0, 0, 0, 0.085);
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
        }

        .lb-v-scrollbar-slider {
            background-color: transparent;
            width: 6px;
            right: 0;
            left: 0;
            margin: auto;

            &:before {
                content: '';
                width: 1px;
                background-color: rgba(0, 0, 0, 0.35);
                height: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
            }

            &:after {
                content: '';
                width: 6px;
                height: 6px;
                background-color: rgba(0, 0, 0, 0.75);
                position: absolute;
                border-radius: 100%;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
            }
        }
    }

    .lb-wrap {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;

        /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
    }

    #content_sendfriendbox {
        overflow-y: auto;
        height: auto;
        max-height: 20rem;
        margin-top: 0.3rem;
    }

    .form_title {
        width: 100%;
        justify-content: flex-start;
        display: flex;
    }

    .popup_title {
        @include wshopFont($dinFont, 26px, false, $bold, $bgHeaderFooter);
        text-transform: uppercase;
        text-align: center;
        margin: 0 0 1rem 0;
    }

    #sendfriend_prodinfos {
        display: flex;
        justify-content: space-between;
        align-items: center;

        #wrapper_sendfriend_photo,
        .send_frd_pdt_details {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            white-space: normal;
            flex-wrap: wrap;
        }

        #wrapper_sendfriend_photo {
            width: 210px;

            img {
                width: 100%;
                height: auto;
            }
        }

        .send_frd_pdt_details {
            display: block;
            @include wshopFont($latoFont,
                13px,
                false,
                $regular,
                $bgHeaderFooter );

            #product_title {
                font-family: $dinFont;
                font-size: 20px;
                font-weight: $bold;
                text-transform: uppercase;
                width: 100%;
                margin-top: -0.5rem;
            }

            #product_description {
                margin-bottom: 0rem;
                display: -webkit-box;
                -webkit-line-clamp: 15;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            .priceWrapper {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin: 1rem 0;
                font-size: 20px;

                .product_saleprice {
                    margin-right: 1.5rem;
                    margin-top: -0.5rem;
                }
            }
        }
    }

    #sendfriend_form_froms {
        margin-top: 1rem;
    }

    #sendfriend_form_to,
    #sendfriend_form_froms {
        display: flex;
        justify-content: center;
        align-items: flex-start;

        .w-form-line {
            margin-bottom: 1.8rem;

            .w-input-container {
                .w-input {
                    input {
                        height: 3em;
                    }

                    textarea {
                        height: 7em;
                    }
                }
            }
        }

        .form_title {
            margin-bottom: 0.5rem;

            &#title_de_la_part {
                display: none;
            }
        }

        table {
            width: 600px;
            margin: 0 3rem;
            @include wshopFont($latoFont,
                13px,
                false,
                $regular,
                $bgHeaderFooter );

            td {
                position: relative;
                vertical-align: middle;
                height: 40px;

                input[type='text'] {
                    width: 100%;
                    height: 25px;
                    text-indent: 0.5rem;
                }

                textarea {
                    width: 100%;
                    height: 40px;
                    padding: 0.5rem;
                    box-sizing: border-box;
                }

                &#second_sendfriend_form_to {
                    padding: 0.4rem 0 0.8rem;
                }

                &#fifth_sendfriend_form_to {
                    vertical-align: top;
                    padding-top: 0.6rem;
                    height: auto;
                }

                &#sixth_sendfriend_form_to {
                    height: auto;
                    padding-top: 0.4rem;
                }
            }

            .form_title {
                @include wshopFont($dinFont,
                    13px,
                    false,
                    $bold,
                    $bgHeaderFooter );
                text-transform: uppercase;
                padding: 1rem 0;
            }

            .labelForm {
                font-weight: $bold;
                width: 120px;
                padding-right: 1rem;
            }

            .plusieur_add {
                position: absolute;
                left: 0;
                top: 80%;
                font-family: sans-serif;
                font-size: 11px;
            }

            #sendfriend_form_needed {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                margin: 1rem 0;
                position: relative;

                .send_frd_btm_txt {
                    position: absolute;
                    right: 0;
                    font-family: sans-serif;
                    font-size: 11px;
                }
            }
        }
    }

    #sendfriend_form_froms {
        flex-wrap: wrap;
        margin-top: 0;

        .g-recaptcha {
            width: 100%;
            transform: scale(0.85);

            & > div {
                margin: 0% auto 3.5%;
            }
        }
    }

    #product_sendfriend_nok {
        width: 600px;
        margin: 0 auto 1rem auto;
        font-family: sans-serif;
        font-size: 12px;
        color: red;
    }

    .w-share-form {
        padding-bottom: 1rem;
    }

    #sendfriend_form_buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 600px;
        margin: 0 auto;
        padding-bottom: 1rem;
    }

    #sendfriend_alert_mail {
        text-align: center;
    }

    .sendfriendErrorConf {
        margin: 2rem 0;
        @include wshopFont($latoFont, 15px, false, $regular, $bgHeaderFooter);
    }
}