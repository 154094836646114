/********************/


/*    CLEARFIX      */


/********************/

//Exemple
// @include clearfix()
@mixin clearfix() {
    clear: both;
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}