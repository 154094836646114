#is_not_client_form {
    #LoginErr {
        display: block;
        p {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: arial, sans-serif;
            font-size: 11px;
            width: 300px;
            margin: 1rem auto;
            color: red;
            img {
                height: 18px;
                vertical-align: middle;
                margin-right: .5rem;
            }
            span {
                display: block;
                text-align: justify;
            }
        }
    }
}

.accountTitle {
    padding: 2rem 0 0 0;
    text-align: center;
    h1 {
        display: inline-block;
        clear: both;
        margin: 0 auto .5rem auto;
        position: relative;
        @include wshopFont($dinFont, 46px, .2rem, $bold, $white);
        text-transform: uppercase;
        &:after {
            display: block;
            content: '';
            width: 100%;
            height: 1px;
            background: $white;
        }
    }
    p {
        @include wshopFont($vidalokaFont, 21px, 0, $regular, $white);
        font-style: italic;
    }
}

.accountWrapper {
    width: 100%;
    max-width: 1680px;
    margin: 0 auto;
    &.login {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 3rem 0;
        .wrapper_login_left,
        .wrapper_login_right {
            position: relative;
            max-width: 662px;
            width: 40%;
            min-height: 550px;
            background: $white;
            border: 1px solid $black;
            padding: 6rem 0 0 0;
            &.actif {
                border: 1px solid $red1;
            }
            .valid_form {
                position: absolute;
                top: 50%;
                right: -25px;
                transform: translateY(-50%);
            }
            form {
                width: 376px;
                margin: 0 auto;
                .message_mail_exist {
                    @include wshopFont($dinFont, 13px, 0rem, $semibold, $white);
                    background-color: $red1;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    margin: -40px 0 0 0;
                    &:not(.on) {
                        display: none;
                    }
                }

                .w-lines-group {
                    .w-fieldset-label {
                        font-family: $primaryFont;
                        font-weight: 400;
                        font-size: .75rem;
                        color: $mediumGray;
                        margin-bottom: 0.5rem;
                    }

                    .w-form-line {
                        .w-radio-group {
                            .w-radio-input {
                                .w-input-label {
                                    &:before {
                                        height: 8px;
                                        width: 8px;
                                        min-width: inherit;
                                        border-color: transparent;
                                        box-shadow: inherit;
                                        border-radius: 100%;
                                        top: 5px;
                                        left: 3px;
                                    }

                                    &:after {
                                        content: '';
                                        height: 12px;
                                        width: 12px;
                                        border: 1px solid $black;
                                        left: 1px;
                                        top: 3px;
                                        position: absolute;
                                        border-radius: 100%;
                                    }
                                }
                            }
                        }
                    }
                }

                .w-social-connect {
                    .fb-connect {
                        cursor:pointer;
                        display: flex;
                    }

                    #googleConnect {
                        width: 1.4rem;
                        margin-left: 0.5rem;

                        #googleConnectLabel {
                            display: none;
                        }

                        .g_id_signin {
                            background-image: url(../svg/social_fb.svg);
                            background-position: 98% 21px;
                            background-size: cover;
                            position: relative;
                            width: 1.3rem;
                            height: 1.3rem;

                            > div {
                                display: flex;
                                opacity: 0;                                
                            }
                        }
                    }

                    input[type="text"],
                    input[type="password"] {
                        width: 100%;
                        height: 100%;
                        border: 1px solid #bababa;
                    }

                    .img_error,
                    .valid_form{
                        right: -2rem;
                    }
                }
                .formSubmit {
                    display: block;
                    text-align: center;
                    width: 255px;
                    height: 54px;
                    margin: 30px auto 0 auto;
                    .button {
                        width: 100%;
                    }
                }
                .link_lost_mpd {
                    margin: .6rem 0 0 0;
                    text-align: center;
                    a {
                        @include wshopFont($dinFont, 13px, false, $regular, $red1);
                        text-decoration: underline;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
                #lostpwdSucces2 {
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    .button {
                        width: 48%;
                        &.loader {
                            left: auto;
                            right: 0;
                        }
                    }
                }

                #lostpwdSucces3{
                    .button.blackB{
                        margin: 1.5rem auto;
                        display: block;
                    }

                    p{
                        font-family: $dinFont;
                    }
                }
            }
            .choix_login_btn {
                position: relative;
                text-align: center;
                .choix_login_txt {
                    position: relative;
                    cursor: pointer;
                    @include wshopFont($dinFont, 30px, 0, $bold, $black);
                    letter-spacing: .2px;
                    text-transform: uppercase;
                    display: inline-block;
                    margin: 0 0 2rem 0;
                    &:after {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 100%;
                        margin: .5rem 0 0 0;
                        border-bottom: 1px solid $black;
                    }
                    .radio_choix {
                        display: block;
                        position: absolute;
                        width: 23px;
                        height: 23px;
                        background: $black;
                        border-radius: 3px;
                        top: .5rem;
                        left: -34px;
                        transition: all .3s ease;
                        &:after {
                            @extend %fleche;
                            margin: .13rem 0 0 .13rem;
                        }
                        &.actif {
                            background: $red1;
                            transform: rotate(90deg);
                        }
                    }
                }
            }
            #lostpwdSuccesTitle {
                @include wshopFont($dinFont, 16px, .1rem, $regular, $black);
                text-align: center;
                text-transform: uppercase;
                margin: 0 0 .8rem 0;
            }
            .text_email_send {
                text-align: center;
                @include wshopFont($latoFont, 14px, .1rem, $regular, $black);
                width: 50%;
                margin: 2rem auto 0 auto
            }
        }
        .wrapper_login_left {
            border-right: 1px solid transparent;
            &.actif {
                border-right: 1px solid $red1;
            }
            &:after {
                @extend %arrowRed;
                position: absolute;
                z-index: 1;
                width: 3rem;
                height: 3rem;
                transform: rotate(90deg);
                top: 45%;
                right: -1.5rem;
                transition: all .3s ease;
            }
            &.actif:after {
                transform: rotate(-90deg);
            }
            .inputErr{
                border: 1px solid red!important;
            }
            img.img_error{
                right: -2rem;
            } 
            
        }
        .wrapper_login_right {
            border-left: 1px solid transparent;
            &.actif {
                border-left: 1px solid $red1;
            }

            .w-lines-group {
                margin-bottom: 0;

                .w-form-line {
                    margin-bottom: 1.5rem;
                }
            }

            .w-form-line {
                margin-bottom: 1rem;

                &.w-submit  {
                    margin-bottom: 0;
                }
            }
        }
    }

   .w-lines-group {
        margin-bottom: 0.5rem;
        
        .w-form-line {
            margin-bottom: 1.5rem;
        }

        .w-input-note.w-input-error {
            top: 90%!important;
        }
    }
}

.customer .breadcrumbs_wrapper,
.newsletter .breadcrumbs_wrapper,
.order .breadcrumbs_wrapper,
.contact_faq .breadcrumbs_wrapper {
    display: none;
}

.accountMainWrapper {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    background: $white;
    .accountMenuWrapper {
        width: 33%;
        min-height: 900px;
        background: #fff url('../img/bg_menu_compte.jpg') no-repeat center top;
        background-size: cover;
        text-align: center;
        &.notLogged {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 700px;
            background-position: center;
            h1 {
                margin: 0;
            }
        }
        h1 {
            @include wshopFont($dinFont,
            46px,
            .1rem,
            $bold,
            $black);
            display: inline-block;
            border-bottom: 1px solid $black;
            margin: 4rem 0 0 0;
            text-transform: uppercase;
        }
        .clientName {
            display: block;
            @include wshopFont($vidalokaFont,
            21px,
            .1rem,
            $regular,
            $black);
            text-transform: inherit;
            font-style: italic;
            margin: .5rem 0 3rem 0;
        }
        .menu_compte_links {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            a {
                display: inline-block;
                position: relative;
                padding: 0 2rem;
                height: 52px;
                line-height: 52px;
                margin: 0 0 .1rem 0;
                @include wshopFont($dinFont,
                19px,
                .1rem,
                $regular,
                $black);
                text-transform: uppercase;
                .corner {
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    opacity: 0;
                    transition: all .3s ease;
                    &.Tleft {
                        border-top: 2px solid $black;
                        border-left: 2px solid $black;
                        left: -8px;
                        top: -8px;
                    }
                    &.Bleft {
                        border-bottom: 2px solid $black;
                        border-left: 2px solid $black;
                        left: -8px;
                        bottom: -8px;
                    }
                    &.Tright {
                        border-top: 2px solid $black;
                        border-right: 2px solid $black;
                        right: -8px;
                        top: -8px;
                    }
                    &.Bright {
                        border-right: 2px solid $black;
                        border-bottom: 2px solid $black;
                        right: -8px;
                        bottom: -8px;
                    }
                }
                &.actif,
                &:hover {
                    font-weight: $bold;
                    .corner {
                        opacity: 1;
                        &.Tleft {
                            left: 0;
                            top: 0;
                        }
                        &.Bleft {
                            left: 0;
                            bottom: 0;
                        }
                        &.Tright {
                            right: 0;
                            top: 0;
                        }
                        &.Bright {
                            right: 0;
                            bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .mon_compte_right {
        width: 64%;
        padding: 0 0 0 4%;
        &.not_logged {
            width: 420px;
            margin: 3rem auto 10rem auto;
            padding: 0;
        }
        .content_password {
            .pwd_forget {
                margin: 2rem 0;
            }
            .content_input {
                width: 415px;
                height: 40px;
                position: relative;
                margin: 0 0 .5rem 0;
                input {
                    width: 100%;
                    height: 100%;
                    border: 1px solid #ccc;
                    border-radius: 3px;
                    text-indent: .5rem;
                    &.inputErr {
                        border-color: $red1;
                    }
                }
            }
            .formSubmit {
                width: 250px;
                .button {
                    width: 100%;
                }
            }
        }
        .valid_password {
            margin: 2rem 0;
            @include wshopFont($latoFont,
            15px,
            false,
            $regular,
            $black);
        }
        h2 {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            @include wshopFont($dinFont,
            30px,
            0,
            $regular,
            $black);
            margin: 4rem 0 2rem 0;
            text-transform: uppercase;
            line-height: 1;
            span {
                span {
                    display: block;
                    font-size: 13px;
                    font-weight: $regular;
                    text-transform: initial;
                }
            }
        }

        .update_success {
            color: $success;
        }
        
        &.wrappeur_content_mes_infos {
            #clientForm {
                width: 96%;
                max-width: 600px;
                margin: 3rem 0 0 0;
            }
            .formSep {
                width: 100%;
                height: 30px;
                border-bottom: 1px solid #ececec;
                margin: 0 0 30px 0;
            }

            .w-form {
                .w-form-line {
                    &:first-of-type {
                        margin-bottom: .5rem;
                    }
                }
            }

            .formRow {
                .wrapper_labels {
                    width: 180px;
                    @include wshopFont($dinFont,
                    13px,
                    0,
                    $bold,
                    $black);
                    text-transform: uppercase;
                }
                .formItem {
                    width: calc(100% - 180px);
                    height: 40px;
                    margin: 0 0 .4rem 0;
                    &.wrapper_input_numero {
                        width: 224px;
                    }
                    input[type="text"],
                    input[type="password"],
                    select {
                        border: 1px solid #ececec;
                        &.inputErr {
                            border-color: red;
                        }
                    }
                    .wrapperRadios {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        height: 40px;
                        .elem_civilite {
                            margin: 0 1rem 0 0;
                            @include wshopFont($latoFont,
                            14px,
                            0,
                            $regular,
                            $black);
                        }
                    }
                    .date_input {
                        width: 110px;
                        height: 100%;
                    }
                    .wrapperSelects {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        height: 40px;
                        .formItem.select {
                            width: 15%;
                            margin: 0 .5rem 0 0;
                            border: 1px solid #ececec;
                            background-position: 90% center;
                            .clone {
                                line-height: 40px;
                                @include wshopFont($latoFont,
                                14px,
                                0,
                                $regular,
                                $black);
                                text-indent: .5rem;
                            }
                        }
                    }
                    .wrapperSelects {
                        .more_info {
                            width: 51%;
                            margin-left: 1%;
                            font-size: 0.7em;
                        }
                    }
                }
                .wrapper_conf.hide {
                    width: 100%;
                    .confWrapper {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        height: 40px;
                    }
                }
                .text_civilite,
                .text_news,
                .text_news,
                .wrapper_input_news {
                    @include wshopFont($latoFont,
                    14px,
                    0,
                    $regular,
                    $black);
                    line-height: 40px;
                    a {
                        color: $black;
                        text-decoration: underline;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
                .wrapper_input_news a {
                    float: right;
                }
                .intl-tel-input {
                    height: 100%;
                    .flag-container {}
                }
                .valid_form,
                .img_error {
                    position: absolute;
                    top: 50%;
                    right: .5rem;
                    transform: translateY(-50%);
                }
            }
            .btn_submit {
                margin: 30px 0 0 0;
            }
        }
        &.content_commandes {
            .wrapper_msg_bounce {
                font-family: din,sans-serif;
                font-size: 13px;
                padding-top: 2rem;
            }

            .wrapper_tableau {
                width: 96%;
                max-width: 1024px;
                height: 78px;
                background: #f3f3f3;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 2rem 0 0 0;
                .formItem.select.filtres_commandes {
                    width: 200px;
                    height: 35px;
                    background: $white url('../img/select_arrow_cmd.png') no-repeat right center;
                    border: 1px solid $white;
                    border-radius: 0px;
                    margin: 0 0 0 1.4rem;
                }
                #com_onglet {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 100%;
                    li {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 100%;
                        padding: 0 .8rem;
                        border: 3px solid transparent;
                        @include queriesWshop($milleDeuxQuatreVingt,
                        max) {
                            padding: 0 .3rem;
                        }
                        .disabled,
                        .enabled {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            @include wshopFont($dinFont,
                            14px,
                            false,
                            $bold,
                            $black);
                            text-transform: uppercase;
                            .nb_order {
                                display: inline-block;
                                margin: 0 0 0 .4rem;
                                border-radius: 50%;
                                width: 20px;
                                height: 20px;
                                line-height: 20px;
                                text-align: center;
                                background: $black;
                                color: $white;
                            }
                            @include queriesWshop($milleDeuxQuatreVingt,
                            max) {
                                font-size: 10px;
                            }
                        }
                        .disabled {
                            color: #767676;
                            .nb_order {
                                background: #767676;
                            }
                        }
                        &:hover,
                        &.actif {
                            border: 3px solid $red1;
                            background: #fafafa;
                        }
                    }
                }

                .filtres_commandes {
                    &:after {
                        display: none;
                    }
                }
            }
            #orders_by_type {
                margin: 1rem 0 0 0;
                width: 96%;
                max-width: 1024px;
                table {
                    border-collapse: collapse;
                    .header {
                        td {
                            height: 25px;
                            @include wshopFont($dinFont,
                            14px,
                            .1rem,
                            $semibold,
                            $black);
                            text-transform: uppercase;
                            padding: 0;
                        }
                    }
                    td {
                        text-align: left;
                        text-indent: .6rem;
                        border: 1px solid #f3f3f3;
                        font-family: sans-serif;
                        padding: 1.4rem 0;
                        @include wshopFont($dinFont,
                        12px,
                        false,
                        $bold,
                        $black);
                        a {
                            color: $black;
                            text-decoration: underline;
                            &:hover {
                                text-decoration: none;
                            }
                            &.download {
                                text-decoration: none;
                            }
                        }
                        &.nothing {
                            text-align: center;
                            padding: 2rem 0;
                            @include wshopFont($dinFont,
                            14px,
                            .1rem,
                            $regular,
                            $black);
                        }
                        &.last.montant {
                            vertical-align: middle;
                            @include wshopFont($dinFont,
                            25px,
                            false,
                            $bold,
                            #5c5c5c);
                            .cents {
                                font-size: .4em;
                                vertical-align: .7rem;
                            }
                            input {
                                float: right;
                                width: 53px;
                                height: 27px;
                                border: 2px solid $black;
                                @include wshopFont($dinFont,
                                10px,
                                false,
                                $bold,
                                $black);
                                background: $white;
                                margin: .2rem .5rem 0 0;
                                cursor: pointer;
                                transition: all .3s ease;
                                &:hover {
                                    background: $black;
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }
        }
        .wrapper_message {
            width: 96%;
            max-width: 1000px;
            margin: 2rem 0 2.5rem;
            .button.blackB {
                float: right;
                height: 36px;
                line-height: 36px;
                margin: 0 0 1rem 0;
                span {
                    font-family: sans-serif;
                    font-weight: 900;
                    color: $red1;
                }
            }
            table {
                border-collapse: collapse;
                border-bottom: 1px solid #e4e4e4;
                thead {
                    td {
                        @include wshopFont($dinFont,
                        13px,
                        false,
                        $bold,
                        $black);
                        text-transform: uppercase;
                        border: 1px solid #e4e4e4;
                        height: 34px;
                        &:nth-child(2),
                        &:nth-child(3) {
                            padding: 0 0 0 1rem;
                        }
                    }
                }
                tbody {
                    td {
                        border-left: 1px solid #e4e4e4;
                        border-right: 1px solid #e4e4e4;
                        padding: 1rem 0;
                        @include wshopFont($dinFont,
                        13px,
                        false,
                        $regular,
                        #515151);
                        &.no_message {
                            border: 1px solid #e4e4e4;
                            height: 50px;
                            @include wshopFont($dinFont,
                            13px,
                            false,
                            $regular,
                            $black);
                            text-transform: uppercase;
                        }
                        &.content_time,
                        &.message {
                            padding: 0 0 0 1rem;
                        }
                        &.content_time {
                            .date {
                                display: block;
                            }
                        }
                        &.message {
                            font-size: 14px;
                            span {
                                display: block;
                                text-transform: uppercase;
                                font-weight: $bold;
                            }
                        }
                        &.wrapper_message_detail {
                            padding: 0;
                            .message_detail {
                                background: #fafafa;
                                padding: 2rem;
                                .main_wrapper_messages {
                                    .wrapper:nth-child(2n) {
                                        margin: 1rem 0;
                                        background: $white;
                                        padding: 1rem;
                                    }
                                }
                                .infos_message {
                                    border-bottom: 1px solid #e4e4e4;
                                    margin: 0 0 1rem 0;
                                    padding: 0 0 .2rem 0;
                                    p {
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: center;
                                        line-height: 1.6;
                                        label {
                                            width: 50px;
                                            font-weight: $bold;
                                            text-align: right;
                                            margin: 0 .5rem 0 0;
                                        }
                                    }
                                }
                                .reponse_message {
                                    label {
                                        text-transform: uppercase;
                                        font-weight: $bold;
                                    }
                                    textarea {
                                        width: 100%;
                                        height: 150px;
                                        padding: .5rem;
                                        margin: 1rem 0 0 0;
                                    }
                                }
                                .formSubmit {
                                    display: flex;
                                    justify-content: flex-end;
                                    align-items: center;
                                    margin: 1rem 0;
                                    .loader {
                                        width: 200px;
                                        left: auto;
                                        right: 0;
                                    }
                                }
                            }
                        }
                        .btn_ouvrir_message {
                            display: block;
                            cursor: pointer;
                            width: 102px;
                            height: 26px;
                            line-height: 22px;
                            text-align: center;
                            border: 2px solid $black;
                            color: $black;
                            text-transform: uppercase;
                            font-weight: $bold;
                            transition: all .3s ease;
                            &.actif,
                            &:hover {
                                background: $black;
                                color: $white;
                            }
                        }
                    }
                    .unread,
                    .read {
                        .enveloppe {
                            span {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 2rem;
                                height: 2rem;
                                border-radius: 50%;
                                background: $red1;
                                &:after {
                                    content: '';
                                    display: block;
                                    @include bgIconPath($pathAccount);
                                    @include bgIconCoord(7,
                                    $col,
                                    $row);
                                    width: 60%;
                                    height: 60%;
                                }
                            }
                        }
                    }
                    .read {
                        .enveloppe {
                            span {
                                background: #818080;
                                &:after {
                                    @include bgIconCoord(8,
                                    $col,
                                    $row);
                                }
                            }
                        }
                    }
                }
            }
            .msg_supprimer_mois {
                margin: .5rem 0 0 0;
                @include wshopFont($dinFont,
                12px,
                false,
                $regular,
                $black);
            }
        }
        #newsletterForm {
            width: 96%;
            max-width: 750px;
            margin: 2rem 0 0 0;
            .formRow {
                height: 40px;
                margin: 0 0 .3rem 0;
                position: relative;
                .formItem:not(.civilite,
                .select) {
                    width: calc(100% - 180px);
                    height: 100%;
                    input {
                        border: 1px solid #bababa;
                        &.inputErr {
                            border-color: $red1;
                        }
                    }
                }
                .formItem.select {
                    width: 100px;
                    border: 0px;
                    margin: 0 .3rem 0 0;
                    select {
                        border: 1px solid #bababa;
                        border-radius: 3px;
                        &.inputErr {
                            border-color: $red1;
                        }
                    }
                }
                .elem_civilite {
                    margin: 0 1rem 0 0;
                    label {
                        @include wshopFont($latoFont,
                        13px,
                        false,
                        $regular,
                        $black);
                    }
                    [type=radio]:checked+label:before,
                    [type=radio]:not(:checked)+label:before {
                        border: 1px solid #bababa;
                    }
                }
                &.dateNaiss {
                    margin: 1rem 0;
                    .formRow {
                        margin: 0;
                    }
                }
            }
            label:not(.txt_civilite) {
                width: 180px;
                @include wshopFont($dinFont,
                13px,
                false,
                $bold,
                $black);
                text-transform: uppercase;
            }
            .formSubmit {
                width: 200px;
                margin: 2rem 0 0 0;
            }
        }
        .wrapper_news_insc_conf {
            p {
                @include wshopFont($dinFont,
                20px,
                false,
                $regular,
                $black);
                text-transform: uppercase;
                margin: 5rem 0 1rem 0;
                &:nth-child(2) {
                    @include wshopFont($latoFont,
                    14px,
                    false,
                    $regular,
                    $black);
                    text-transform: initial;
                    margin: 0;
                }
            }
        }
        .wrapper_news_suppr {
            .formRow {
                input[type="text"] {
                    width: 100%;
                    height: 100%;
                    border: 1px solid #bababa;
                    border-radius: 3px;
                    text-indent: .5rem;
                }
            }
            #newsletterForm {
                .formRow {
                    margin: 4rem 0 0 0;
                }
                .formRow+.formRow {
                    margin: 1rem 0 0 0;
                }
                label {
                    width: 100%;
                    @include wshopFont($dinFont,
                    13px,
                    false,
                    $regular,
                    $black);
                    text-transform: initial;
                }
                .formSubmit {
                    width: 250px;
                    .button {
                        width: 100%;
                    }
                }
            }
        }
    }
    .choix_type_adresse {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 66%;
        min-width: 625px;
        margin: 2rem 0 0.9375rem 0;
        height: 60px;
        .type_adresse {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 23%;
            height: 100%;
            border: 2px solid #767676;
            @include wshopFont($dinFont,
            14px,
            .1rem,
            $semibold,
            $black);
            text-transform: uppercase;
            cursor: pointer;
            margin-right: 1rem;
            margin-bottom: 0.9375rem;
            &.actif {
                border-color: $red1;
                color: $red1;
            }
        }
    }
    .wrapper_form_adresse {
        width: 66%;
        min-width: 625px;
        margin: 2rem 0 0 0;
        @include wshopFont($dinFont,
        14px,
        .1rem,
        $regular,
        $black);

        .formItem {
            height: 40px;
            margin: 0 0 .5rem 0;
            input[type='text'] {
                height: 100%;
                border: 1px solid #bababa;
            }
            &.select {
                border-color: #bababa;
            }
            .noaddr.delivery {
                display: none;
                position: absolute;
                right: 3rem;
                top: 50%;
                transform: translateY(-50%);
                color: red;
                font-family: sans-serif;
                font-size: 11px;
                text-decoration: underline;
                cursor: pointer;
                &:hover {
                    text-decoration: none;
                }
            }
            .wrapper_civility {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 100%;
                label:last-of-type {
                    margin: 0 0 0 2rem;
                }
            }
            &.phone {
                width: 32.5%;
                .intl-tel-input,
                .flag-container {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .address_title,
        .factur_addr {
            width: 100%;
        }
        .factur_addr {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .formSubmit {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 420px;
            margin: 2rem 0 0 0;
            #loader_1 {
                position: relative;
                width: 200px;
            }
        }
    }
}

.conteneur_adresse {
    width: 96%;
    max-width: 1024px;
    min-width: 625px;
    margin: 2rem 0 0 0;
    table {
        border-collapse: collapse;
        .header {
            td {
                height: 25px;
                @include wshopFont($dinFont,
                14px,
                .1rem,
                $semibold,
                $black);
                text-transform: uppercase;
                padding: .6rem 0 .6rem .6rem;
            }
        }
        td {
            text-align: left;
            padding: 1rem 0 1rem .6rem;
            border: 1px solid #f3f3f3;
            @include wshopFont($dinFont,
            12px,
            .1rem,
            $semibold,
            $black);
            &.handle {
                text-align: right;
                padding: 1rem .6rem 1rem 0;
                a {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    @include wshopFont($dinFont,
                    12px,
                    .1rem,
                    $semibold,
                    $black);
                    text-decoration: none;
                    margin: .4rem 0;
                    span {
                        display: inline-block;
                        margin: 0 0 0 .3rem;
                        background-color: $black;
                        border-radius: 50%;
                        width: 1rem;
                        height: 1rem;
                        &:after {
                            display: block;
                            content: '';
                            @include bgIconPath($pathAccount);
                            width: .9em;
                            height: .9em;
                            margin: .18rem 0 0 .18rem;
                        }
                    }
                    &.modify span:after {
                        @include bgIconCoord(10,
                        $col,
                        $row);
                    }
                    &.delete span:after {
                        @include bgIconCoord(9,
                        $col,
                        $row);
                    }
                }
            }
            &.select {
                padding: 1rem 0;
                text-align: center;
                .button {
                    height: 30px;
                    line-height: 30px;
                    font-size: 12px;
                    width: 160px;
                }
            }
            .is_bill {
                display: block;
            }
        }
        &.content {
            margin: 0 0 .8rem 0;
        }
    }
    .choisir_adresse {
        width: 320px;
        height: 36px;
        line-height: 36px;
        span:before {
            content: '';
            display: inline-block;
            margin: 0 1rem 0 0;
            @include bgIconPath($pathAccount);
            @include bgIconCoord(10,
            $col,
            $row);
            width: 1.1em;
            height: 1.1em;
            vertical-align: -.2rem;
        }
    }
}

.wrapper_panier .conteneur_adresse {
    width: 100%;
    max-width: 990px;
    margin: 5rem auto;
}

.detailsCmdWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .detailsCmd,
    .deliveryInfos {
        width: 40%;
        @include wshopFont($latoFont,
        14px,
        false,
        $regular,
        $black);
        .title {
            @include wshopFont($dinFont,
            16px,
            false,
            $bold,
            $black);
            text-transform: uppercase;
            margin: 5rem 0 0 0;
        }
        p {
            margin: .5rem 0 0 0;
        }
    }
}

.cmdItemWrapper {
    .title_part {
        @include wshopFont($dinFont,
        16px,
        false,
        $bold,
        $black);
        text-transform: uppercase;
        margin: 1rem 0;
    }
    #resume_commande {
        border-collapse: collapse;
        width: 96%;
        max-width: 1000px;
        @include wshopFont($latoFont,
        14px,
        false,
        $regular,
        $black);
        thead th {
            line-height: 2;
            @include wshopFont($dinFont,
            14px,
            false,
            $bold,
            $black);
            text-transform: uppercase;
            border: 1px solid #f3f3f3;
        }
        td {
            border: 1px solid #f3f3f3;
            text-align: center;
            &.visu {
                width: 149px;
                height: 149px;
            }
            &.name {
                text-align: left;
                padding: 0 0 0 1rem;
            }
            &.price {
                font-size: 20px;
            }
            .unit,
            .devise {
                @include wshopFont($dinFont,
                20px,
                false,
                $bold,
                $black);
            }
            .devise {
                font-size: .5em;
                vertical-align: .45rem;
            }
        }
        .img_panier {
            width: 149px;
            height: auto;
        }
    }
}

#wrapper_bottom_cmd {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 96%;
    max-width: 1000px;
    .table_total {
        margin: 1rem 0 0 0;
        .labels {
            width: 300px;
            @include wshopFont($latoFont,
            15px,
            false,
            $bold,
            $black);
            text-transform: uppercase;
            vertical-align: bottom;
        }
        .montants {
            text-align: right;
            @include wshopFont($dinFont,
            26px,
            false,
            $bold,
            $black);
            .devise {
                font-size: .5em;
                vertical-align: .5rem;
            }
        }
    }
}

.wrapper_satisaction {
    #mes_err_fieldset {
        display: none;
    }
    #mes_err_fieldset,
    #mes_err {
        @include wshopFont($latoFont,
        15px,
        false,
        $regular,
        $black);
        legend {
            padding: 2rem 0 1rem 0;
            color: $red1;
            font-weight: bold;
        }
        p {
            margin: 0 0 .5rem 1rem;
        }
        ul {
            margin: 0 0 0 2rem;
            li {
                list-style-type: disc;
            }
        }
    }
    .confSatisfaction {
        margin: 3rem 0 0 0;
        width: 96%;
        max-width: 800px;
        .labelForm {
            @include wshopFont($dinFont,
            16px,
            false,
            $regular,
            $black);
        }
        button {
            margin: 2rem auto 0 auto;
        }
    }
    .cadre_question {
        margin: 3rem 0 0 0;
        width: 96%;
        max-width: 800px;
        .subtitles_questionnaire {
            @include wshopFont($dinFont,
            16px,
            false,
            $bold,
            $black);
            text-transform: uppercase;
            line-height: 3;
        }
        .explication_range_slider {
            @include wshopFont($latoFont,
            14px,
            false,
            $regular,
            $black);
            padding: 0 0 1rem 0;
        }
        [type=radio]:checked+label:before,
        [type=radio]:not(:checked)+label:before {
            display: inline-block;
            vertical-align: -.2rem;
        }
        [type=radio]:checked+label:after {
            top: 8px;
        }
        table td {
            &.labelForm {
                line-height: 2.5;
                @include wshopFont($latoFont,
                14px,
                false,
                $regular,
                $black);
            }
        }
        .labelFormSatisfaction,
        .labelErr {
            display: block;
            width: 200px;
            @include wshopFont($dinFont,
            14px,
            false,
            $bold,
            $black);
        }
        .labelErr {
            color: $red1;
        }
    }
    #satisfactionForm .label_radio {
        margin: 0 35px 0 5px;
        display: block;
        font-size: 14px;
        color: #000
    }
    .satisfaction_title_08,
    .satisfaction_title_11 {
        tr td.graduationForm {
            position: relative;
            float: left;
            left: 40px;
            width: 486px;
            height: 23px;
            background-repeat: no-repeat
        }
    }
    .satisfaction_title_08 {
        display: none;
    }
    .satisfaction_smiley {
        position: relative;
        margin: .5rem 0 0 0;
        left: 33px;
        &.libmiddle {
            left: 218px;
        }
        &.libright {
            left: 402px;
        }
    }
    #slider_serviceclient,
    #slider_recommandation {
        background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
        border: medium none;
        cursor: pointer;
        height: 37px;
        left: 41px;
        position: relative;
        top: 4px;
        width: 420px;
        a {
            position: absolute;
            background-color: transparent;
            border: medium none;
            border-radius: 0;
            cursor: pointer;
            height: 37px;
            margin-left: -13px;
            width: 30px;
        }
    }
}

/* NEWSLETTER PREFERENCES */

.content_newsletter {
    input[type=text] {
        border: 1px solid #bababa;
        height: 40px;
        margin: 0 0 .3rem 0;
        position: relative;
    }
    #subscriptionForm {
        width: 96%;
        max-width: 750px;
        margin: 2rem 0 0 0;
        .formItem {
            width: 100%;
            margin-bottom: 20px;
        }
        .formSubmit {
            width: 200px;
            margin: 2rem 0 0 0;
        }
    }

    .newsletter_option {
        margin-bottom: 15px;
        label {
            display: block; font-size: 13px; cursor: pointer;font-family: din,sans-serif;position:relative;padding-left:2.5em;
            &:before {
                display: block;
                float: left;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
            }
            &:before, &:after {
                margin-top: 4px;
            }
            strong {
                font-size:14px; font-weight: 900;
            }
        }
    }
}

p.info_site_choice {
    font-family: din,sans-serif;
    text-align: left;
}

p.info_site{ 
    margin-top: 0;
    a {
        text-decoration: underline;
        font-family: din,sans-serif;
        text-align: left;
        font-size: 13px;
        color: #ba2432;
    }
}

.wrapper_optin {
    display: inline-block;
    margin: 7px 0 10px 0;
    .wrapper_optin_error {
        color: red;
    }
    label {
        font-family: din,sans-serif;
        float: left;
        margin-right: 10px;
        line-height: 14px;
        cursor: pointer;
        &.mobilelabelErr:before {
            border-color: red !important;
        }
    }
}

#iconErrwrapper_optin {
    margin: 5px 0 !important;
}

/* POPUP SAVOIR PLUS */

#popup_savoir_plus {
    position: fixed;
    _position: absolute;
    top: 50%;
    left: 50%;
    z-index: 11;
    text-align: left;
    margin: -240px 0 0 -315px;
    padding: 0;
    background-color: #ffffff;
    color: #616161;
    font-size: 11px;
    width: 630px;
    height: 400px;
    display: none;
    -moz-box-shadow: 0 0 20px #111;
    -webkit-box-shadow: 0 0 20px #111;
    box-shadow: 0 0 20px #111;
    #fermer_savoir_plus a.btn_fermer {
        position: absolute;
        top: 6px;
        right: 7px;
        background: url(../img/close_btn.jpg) no-repeat right 1px;
        width: 91px;
        height: 33px;
        display: block;
        margin: 2px;
        z-index: 10;
        cursor: pointer;
    }
    #title_popup_savoir_plus {
        font-family: din,sans-serif;
        margin: 30px 75px;
        font-size: 30px;
        color: #161616;
        font-weight: bold;
        text-transform: uppercase;
    }
    #content_savoir_plus {
        font-family: din,sans-serif;
        padding: 20px 75px;
        ul li {
            font-size: 17px;
            color: #000;
            height: 25px;
            line-height: 25px;
            font-style: italic;
         }
    }

     #bottom_savoir_plus {
        font-family: din,sans-serif;
        font-size: 12.5px;
        color: #2b2b2b;
        padding: 20px 60px;
        div {
            color: #666666;
            padding: 10px 0 0 20px;
        }
        br {
            line-height: 25px;
        }
    }
    .pointilles_savoir_plus {
        text-align: center;
    }
}
.connectMail {
    img.img_error, img.valid_form {
        right: 2.5rem;
    }
}

.rPwd,
.content_input.rPwd,
.input_container.rPwd {
    display: flex;
    justify-content: center;
    align-items: center;
    img.img_error, img.valid_form {
        right: 2.5rem;
    }
    .unmask {
        font-size: 9px;
        text-transform: uppercase;
        position: absolute;
        cursor: pointer;
        right:1px;
        padding-left: 10px;
        top: 50%;
        transform: translateY(-50%);
        &.see{
            width: 38px;
            height: 92%;
            background-color:white;
            background-position:center;
            background-repeat:no-repeat;
            background-image: url(../svg/eye_open.svg);
        }
        &.unsee{
            background-image: url(../svg/eye_shut.svg);
        }
    }
}
.accountMainWrapper .mon_compte_right.wrappeur_content_mes_infos .formRow .rPwd .img_error, 
.accountMainWrapper .mon_compte_right.wrappeur_content_mes_infos .formRow .rPwd .valid_form {
    right: 2.5rem;
}

.wrapper_tunnel{
    #outsite_ue_notice {
        max-width: 100%;
        width: 100%;
        margin: 3.5rem auto 0;
        font-size: 15px;
        font-weight: 400;
        color: #242424;
        text-align: center;
        font-family: $latoFont;
    }
}

#show_top_cart {
    .titleWrapper .titre_panier {
        font-family: $primaryFont;
        letter-spacing: 0;

        span.subtitle {
            top: -0.9rem;
        }
    }

    .cart_thumb_holder img {
        width: 224px;
    }

    .cart_detail_box {
        top: 0;

        .cart_product_charact {
            display: block;
        }

        .cart_product_prix {
            position: absolute;
            bottom: 20px;
            right: 0;
        }

        .cart_product_charact {
            margin-top: 0rem;
        }
    }
}