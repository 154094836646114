/*
Foobar sprite from icns_spritesheet.svg with 6 images (3 by 2)


*	Generates sprites in css, choose your class and your icon in your svg. That create .class:before with your icon

*	@param string $path Path to the spritesheet img file

*	@param string $item select item
*	@param integer $col Number of columns in the spritesheet grid
*	@param integer $row Number of rows in the spritesheet grid


*	** Create by Timothy & Joffrey **

@	Helper function to compute image coordinates within the spritesheet*/

@function imgCoord($l, $x) {
    $out: 0;

    @if $l>1 {
        $out: ((1 / ($l - 1)) * $x) * 100%;
    }

    @return $out;
}


/* EXEMPLE */


/*

|| IF BACKGROUND MASK ||

.class{
	@include maskIconPath('..images/svg/name.svg');
	@include maskIconCoord(2,4,5);
}

*/

@mixin maskIconPath($path) {
    -webkit-mask-image: url($path);
    mask-image: url($path);
}

@mixin maskIconCoord($item, $col, $row) {

    $len: ($col * $row);
    $y: 0;
    -webkit-mask-size: ($col * 100%) ($row * 100%);
    mask-size: ($col * 100%) ($row * 100%);
    @for $i from 1 through $len {
        $x: ($i - 1) % $col;

        @if ($i - 1)>0 and $x==0 {
            $y: $y + 1;
        }
        @if ($item==$i) {
            -webkit-mask-position: imgCoord($col, $x) imgCoord($row, $y);
            mask-position: imgCoord($col, $x) imgCoord($row, $y);
        }
    }
}



/* EXEMPLE */


/*

|| IF BACKGROUND MASK ||

.class{
	@include bgIconPath('../');
	@include bgIconCoord(19, $col, $row)
}

*/

@mixin bgIconPath($path) {
    background-image: url($path);
}

@mixin bgIconCoord($item, $col, $row) {

    $len: ($col * $row);
    $y: 0;
    background-size: ($col * 100%) ($row * 100%);
    @for $i from 1 through $len {
        $x: ($i - 1) % $col;

        @if ($i - 1)>0 and $x==0 {
            $y: $y + 1;
        }
        @if ($item==$i) {
            background-position: imgCoord($col, $x) imgCoord($row, $y);
        }
    }
}