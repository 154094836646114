/***********/
/* BUTTONS */
/***********/

.button {
	appearance: none;
	display: inline-block;
    width: 200px;
    height: 54px;
    line-height: 54px;
    @include wshopFont($dinFont, 15px, .1rem, $thin, $white);
    text-align: center;
    text-transform: uppercase;
    padding: 0;
    border: none;
    border-radius: 4px;
    transition: all .3s ease;
    cursor: pointer;
    &.whiteB {
        background: $white;
        color: $black;
    }
    &.redB {
        background: $red1;
        color: $white;
    }
    &.blackB {
        background: $black;
        color: $white;
    }
    &.loader {
        cursor: default;
    }
    &.arrow span:after {
        @extend %fleche;
        display: inline-block;
        margin: 0 0 0 .5rem;
        vertical-align: -.4rem;
    }
    &.borderR {
        border: 1px solid $red1;
        background: $white;
        color: $black;
        &:hover {
            background: $red1;
            color: $white;
			span {
				color: $white;
			}
        }
    }
    &.borderB {
        border: 1px solid $black;
        background: $white;
        color: $black;
        &:hover {
            background: $black;
            color: $white;
			span {
				color: $white;
			}
        }
		&.show_postal {
			text-transform: initial;
			width: 85%;
			height: 35px;
			line-height: 35px;
		}
    }
}

/* Loaders */

.loader {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 54px;
    line-height: 54px;
    @include wshopFont($dincondFont, 15px, .1rem, $regular, $white);
    text-transform: uppercase;
    text-align: center;
    cursor: default;
    img {
        vertical-align: -.4rem;
        margin: 0 .5rem 0 0;
    }
    span {
        &:after {
            content: '';
            display: inline-block;
            width: 2rem;
            height: 2rem;
            background: transparent url(../img/loader.gif) no-repeat 0 0;
            background-size: 100% auto;
            vertical-align: middle;
            margin: 0 0 0 .5rem;
        }
    }
}

.loading .loader {
    display: block;
}

/* LIGHTBOX CLOSE */
%close{
    background: transparent;
    display: block;
    width: 20px;
    height: 20px;
    position: relative;
    font-size: 0;
    cursor: pointer;
    transform: translateY(-50%), rotate(0deg);
    &:before,
    &:after{
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        margin: 0 auto;
        background: $black;
        width: 100%;
        height: 3px;
    }
    &:before{
        transform: translateY(-50%) rotate(45deg);
    }
    &:after{
        transform: translateY(-50%) rotate(-45deg);
    }
    span {
        display: block;
        width: 100%;
        height: 3px;
        background: $black;
        position: absolute;
        top: 110%;
        left: 0;
    }
}

.modifyBtn {
	span {
		&:after {
			content: '';
			display: inline-block;
			@include bgIconPath($pathAccount);
			@include bgIconCoord(1, $col, $row);
			width: 1rem;
			height: 1rem;
		}
	}
}
