#is_not_client_form {
    #LoginErr {
        display: block;
        p {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: arial, sans-serif;
            font-size: 11px;
            width: 300px;
            margin: 0 auto;
            color: red;
            img {
                height: 18px;
                vertical-align: middle;
                margin-right: .5rem;
            }
            span {
                display: block;
                text-align: justify;
            }
        }
    }
}