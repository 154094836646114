/* NOS AVIONS */
.footer_legalmentions {
    .breadcrumbs_wrapper {
        display: none;
    }

    .contentWrapper {
        display: flex;

        > div {
            color: $white;
            height: 100%;
            background-color: $black;
            display: flex;

            h1 {
                float: left;
                width: 32.4%;
                background: url(../img/bandeau_ml.jpg) no-repeat top left;
                background-size: cover;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    display: block;
                    font-size: 2.4vw;
                    text-transform: uppercase;
                    text-align: center;
                    margin: 0 auto;
                    display: block;
                    font-family: $dinFont;
                    font-size: 46px;
                    letter-spacing: 0;
                    font-weight: 700;
                    color: $white;
                    margin-top: -4rem;
                    position: relative;

                    &:before {
                        content: '';
                        display: block;
                        width: 90.58%;
                        height: 1px;
                        background: $white;
                        margin: 0 0 0 0;
                        position: absolute;
                        bottom: -9px;
                        left: 50%;
                        transform: translateX(-50%);
                    }

                    &:after {
                        content: "Aero Vintage Academy";
                        font-family: $vidalokaFont;
                        font-size: 16px;
                        font-weight: 400;
                        font-style: italic;
                        text-align: center;
                        text-transform: none;
                        color: $white;
                        position: absolute;
                        bottom: -1.85rem;
                        display: block;
                        width: 100%;
                    }
                }
            }

            .content_sat {
                float: right;
                width: 67.6%;
                height: 100%;
                background-color: $black;
                padding: 2.5vw 2vw;
                column-count: 2;
                column-gap: 3vw;
                text-align: justify;

                .row {
                    &#row_bloc_0 {
                        h2 {
                            margin: 0 0 26px 0;
                        }
                    }

                    .content {
                        font-family: $dinFont;
                        font-size: 14px;
                        letter-spacing: 0;
                        font-weight: 500;
                        color: #bfbfbf;
                        letter-spacing: -.05em;
                        line-height: 18px;

                        h2 {
                            font-family: $dinFont;
                            font-size: 18px;
                            letter-spacing: 0;
                            font-weight: 700;
                            color: $white;
                            letter-spacing: .07em;
                            text-transform: uppercase;
                            margin: 31px 0 18px 0;
                            text-align: left;
                        }

                        /*div {
                            font-family: $dinFont;
                            font-size: 14px;
                            letter-spacing: 0;
                            font-weight: 500;
                            color: #bfbfbf;
                            letter-spacing: -.05em;
                            line-height: 18px;
                        }*/
                        a {
                            color: $red1;
                        }
                    }
                }
            }
        }
    }
}

.footer_legalmentions .contentWrapper #middle_box_ml .sat_article .articles ul li::before {
    content: "-";
    display: inline-block;
    width: 10px;
    height: 10px;
}

.footer_contact {
    .breadcrumbs {
        display: none;
    }
}

.contact_page {
    background: $white;
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    #image_box_faq {
        width: 34%;
        background: url("../img/bandeau_ml.jpg") no-repeat center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .big_title_charte {
            span {
                display: inline-block;
                @include wshopFont($dinFont,
                    46px,
                    0,
                    $bold,
                    #fff);
                text-transform: uppercase;
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 1px;
                    background: $white;
                }
            }
        }

        .small_title_charte {
            display: block;
            width: 30vw;
            text-align: center;
            margin: 0 auto;
            @include wshopFont($vidalokaFont,
                16px,
                0,
                $regular,
                #fff);
            font-style: italic;
        }
    }

    #middle_box_faq {
        width: 66%;
        background-color: #fff;
        text-align: justify;
        min-height: 600px;
        float: left;
        color: blue;

        #wrapper_faq {
            width: 96%;
            max-width: 800px;
            margin: 0 auto;
            padding: 0 0 4rem 0;

            .big_title_charte_faq {
                text-align: center;
                padding-top: 82px;
                @include wshopFont($dinFont,
                    36px,
                    0,
                    $bold,
                    #BC212F);
            }

            .small_title_charte_faq {
                text-align: center;
                @include wshopFont($vidalokaFont,
                    19px,
                    0,
                    $regular,
                    #2A3235);

                &:before {
                    content: '—— ';
                    font-family: none;
                }

                &:after {
                    content: ' ——';
                    font-family: none;
                }
            }

            #middle_box_content {
                margin-top: 47px;

                #list_question {
                    padding-top: 10px;
                }

                #field {
                    p {
                        @include wshopFont($latoFont,
                            13px,
                            0,
                            $regular,
                            #737272);
                        line-height: 1.5;
                        margin: 23px 0;

                        a {
                            color: #ba2432;
                        }
                    }

                    #contact_form {
                        .big_title_charte.no_bg.sub_title {
                            @include wshopFont($latoFont,
                                13px,
                                0,
                                $regular,
                                #737272);
                        }

                        h3 {
                            margin-bottom: 0%;
                        }

                        .form_item {
                            @include wshopFont($dinFont,
                                14px,
                                0,
                                $bold,
                                #000);
                            text-transform: uppercase;
                            margin-top: 0px !important;
                            width: 100%;
                            max-width: 800px;

                            tr {
                                display: inline-block;
                                box-sizing: border-box;
                                width: 100%;
                                max-width: 800px;

                                &:nth-child(1),
                                &:nth-child(2),
                                &:nth-child(3) {
                                    width: 32%;
                                    max-width: 256px;

                                    td {
                                        width: 100%;

                                        input {
                                            width: 100%;
                                        }
                                    }
                                }

                                input {
                                    box-sizing: border-box;
                                }

                                margin-top: 1.9%;

                                &:nth-child(2) {
                                    margin-right: 1.8%;
                                    margin-left: 1.8%;
                                }

                                //height: 53px !important;
                                &:nth-child(4),
                                &:nth-child(6) {
                                    display: block;
                                }

                                &:nth-child(6) {
                                    float: right;
                                }

                                td {
                                    display: block;
                                    position: relative;

                                    input,
                                    textarea {
                                        border: 1px solid $black;
                                        //border-radius: 2%;
                                        width: 100%;
                                        height: 40px;
                                        text-indent: 10px;
                                        box-sizing: border-box;

                                        &.inputErr {
                                            border-color: red;
                                            text-indent: 30px;
                                        }
                                    }

                                    .img_error {
                                        right: inherit;
                                        left: 10px;
                                    }

                                    textarea {
                                        & + .img_error {
                                            top: 17.5%;
                                        }
                                    }

                                    #contactRefProduit,
                                    #contactMessage {
                                        width: 41vw;
                                    }

                                    #contactMessage {
                                        padding: 10px;
                                        height: auto;
                                        width: 100%;
                                        max-width: 800px;
                                        //margin-right: 1%;
                                    }
                                }
                            }

                            .formSubmit {
                                float: right;

                                #btn_contact {
                                    & + .loader {
                                        position: relative;
                                        width: 200px;
                                        max-width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }

                .clone {
                    background: rgba(0, 0, 0, 0) url("../img/select_arrow.png") no-repeat scroll 98% center;
                    border: 1px solid #BABABA;
                    white-space: nowrap;
                    border-radius: 2%;
                    cursor: pointer;
                    vertical-align: middle;
                    text-indent: 15px;
                    line-height: 2.5rem;
                    text-transform: lowercase;
                    width: 100%;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }
            }

            #middle_box_content {
                .contact_form_holder {
                    form {
                        width: 100%;
                        max-width: 800px;
                        box-sizing: border-box;
                        margin: auto;
                    }

                    .selectStyled {
                        select {
                            /*text-transform: lowercase;*/
                            opacity: 0;
                            box-sizing: border-box;

                            option {
                                display: block;

                                /*text-transform: lowercase;*/
                                &:first-letter {
                                    /*text-transform: uppercase;*/
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #clear_box_faq {
        clear: both;
    }

    img.valid_form {
        position: absolute;
        right: 3px;
        top: 50%;
    }
}

.cgv,
.misc_cookiesinfo {
    .breadcrumbs {
        display: none;
    }

    .contentWrapper {
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;

        #image_box_cgv {
            width: 33%;
            height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: fixed;
            z-index: 1;
            top: 0;
            left: 0;
            background: url("../img/bandeau_cgv.jpg") no-repeat center top;
            background-size: cover;

            .big_title_charte {
                span {
                    display: inline-block;
                    @include wshopFont($dinFont,
                        46px,
                        0,
                        $bold,
                        #fff);
                    text-transform: uppercase;
                    position: relative;
                    text-align: center;
                    line-height: 1;

                    &:after {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 1px;
                        background: $white;
                        margin: 1rem 0 0 0;
                    }
                }
            }

            .small_title_charte {
                display: block;
                width: 30vw;
                text-align: center;
                margin: .5rem auto 0 auto;
                @include wshopFont($vidalokaFont,
                    16px,
                    0,
                    $regular,
                    #fff);
                font-style: italic;
            }
        }

        #middle_box_cgv {
            float: right;
            width: 67%;
            background-color: $black;
            padding: 2.5vw 3vw;
            column-count: 2;
            column-gap: 3vw;
            text-align: justify;
            margin: 0 0 0 33%;

            .sat_article {
                #sat_article_1 {
                    margin: 0 0 26px 0;
                    text-align: left;
                }

                .big_title_charte {
                    margin: 31px 0 18px 0;
                    text-align: left;

                    span {
                        @include wshopFont($dinFont,
                            18px,
                            0,
                            $bold,
                            #fff);
                        letter-spacing: 0.07em;
                    }
                }

                .articles {
                    @include wshopFont($dinFont,
                        14px,
                        0,
                        $medium,
                        #bfbfbf);
                    letter-spacing: -0.05em;
                    line-height: 18px;

                    a {
                        color: #ba2432;
                    }
                }

                &.updatedate {
                    margin-top: 19px;
                    text-align: right;

                    .articles {
                        color: #ba2432;
                    }
                }
            }
        }

        #clear_box_cgv {
            clear: both;
        }
    }

    .copyrightWrapper {
        .copyright {
            border-top: 1px solid #757575;
        }
    }
}

.misc_cookiesinfo .contentWrapper #middle_box_cgv ul li::before {
    content: "-";
    display: inline-block;
    width: 10px;
    height: 10px;
}

.misc_cookiesinfo .contentWrapper #middle_box_cgv ul li {
    @include wshopFont($dinFont,
        14px,
        0,
        $medium,
        #bfbfbf);
    letter-spacing: -0.05em;
    line-height: 18px;

    a {
        color: #ba2432;
    }
}

.misc_cookiesinfo {
    .contentWrapper {
        > div {
            display: flex;
            align-items: stretch;

            h1 {
                width: 33%;
                height: 100vh;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: fixed;
                z-index: 1;
                top: 0;
                left: 0;
                background: url(../img/bandeau_cgv.jpg) no-repeat center top;
                background-size: cover;

                span {
                    font-family: $dinFont;
                    font-size: 46px;
                    letter-spacing: 0;
                    font-weight: 700;
                    color: #fff;
                    text-transform: uppercase;
                    position: relative;
                    text-align: center;
                    line-height: 1;
                    display: inline-block;

                    &:after {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 1px;
                        background: #fff;
                        margin: 1rem 0 0 0;
                    }
                }
            }

            .content_sat {
                float: right;
                width: 67%;
                background-color: $black;
                padding: 2.5vw 3vw;
                column-count: 2;
                column-gap: 3vw;
                text-align: justify;
                margin: 0 0 0 33%;

                > .row {
                    .content {
                        font-family: $dinFont;
                        font-size: 14px;
                        font-weight: 500;
                        color: #bfbfbf;
                        letter-spacing: -.05em;
                        line-height: 18px;

                        h2 {
                            font-family: $dinFont;
                            font-size: 18px;
                            font-weight: 700;
                            color: $white;
                            letter-spacing: .07em;
                            margin: 31px 0 18px 0;
                            text-align: left;
                            line-height: 1.35;
                        }

                        ul {
                            li {
                                &:before {
                                    content: "-";
                                    display: inline-block;
                                    width: 10px;
                                    height: 10px;
                                }
                            }
                        }

                        a {
                            color: $red1;
                        }
                    }
                }
            }
        }

        /* display: flex;
        align-items: stretch;
        #image_box_cgv {
            width: 33%;
            height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: fixed;
            z-index: 1;
            top: 0;
            left: 0;
            background: url("../img/bandeau_cgv.jpg") no-repeat center top;
            background-size: cover;
        }
        #middle_box_cgv {
            float: right;
            width: 67%;
            background-color: $black;
            padding: 2.5vw 3vw;
            column-count: 2;
            column-gap: 3vw;
            text-align: justify;
            margin: 0 0 0 33%;
        }*/
    }
}

.misc_cookiesinfo .copyrightWrapper {
    position: relative;
    z-index: 2;
}

#message_mail {
    @include wshopFont($latoFont,
        15px,
        false,
        $regular,
        $black);
}

.satellite_planes {
    .breadcrumbs {
        display: none;
    }
}

.planesWrapper,
.page_rayon.experiences,
.page_rayon.formations {
    width: 100%;
    min-width: 990px;
    max-width: 1680px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .planesNavigation {
        position: absolute;
        z-index: 1;
        top: 40px;
        left: 25px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        @include wshopFont($dincondFont,
            16px,
            0,
            $regular,
            $black);
        line-height: 0;
        text-transform: uppercase;

        .stepNum {
            position: relative;

            span {
                display: block;

                &.current {
                    font-size: 63px;
                    font-weight: $bold;
                    line-height: .9;

                    &:before {
                        content: attr(data-txt);
                    }

                    &:after {
                        content: '';
                        display: block;
                        height: 1px;
                        background: $black;
                    }
                }

                &.total {
                    font-size: 19px;
                    line-height: 1.5;
                }
            }
        }

        .stepTxt {
            line-height: 1;
            display: flex;
            flex-direction: column;

            .section {
                display: flex;
                flex-direction: column;
                align-items: center;
                opacity: .6;
                color: $black;
                transition: opacity .3s ease;

                .numSection,
                .titleSection {}

                .titleSection {
                    writing-mode: vertical-rl;
                    text-orientation: sideways;
                }

                &:before {
                    @extend %logoBlack;
                    display: block;
                    width: 57px;
                    height: 57px;
                    opacity: 0;
                    transition: opacity .3s ease;

                    @include queries(tablet) {
                        width: 50px;
                        height: 50px;
                    }
                }

                &.actif {
                    opacity: 1;
                    color: $red1;

                    &:before {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .planeWrapper {
        width: 100%;
        position: relative;

        .planeImg {
            display: block;
            width: 100%;
            min-height: 2rem;
        }

        .infos {
            position: absolute;
            width: 535px;
            box-sizing: border-box;
            padding: 70px 90px;
            background: $bgBody;
            @include wshopFont($latoFont,
                13px,
                0,
                $regular,
                $white);

            @include queriesWshop($mediumDesk,
                max) {
                width: 460px;
                padding: 30px 40px;
            }

            @include queries(tablet) {
                width: 300px;
                padding: 10px 20px;
                font-size: 12px;
            }

            @media screen and (min-width:768px) and (max-width:1024px) and (orientation:portrait) {
                //transform: scale(0.85);
            }

            @media screen and (min-width:768px) and (max-width:1024px) and (orientation:landscape) {}

            .titleWithSubs {
                font-size: 44px;
                color: $white;
                letter-spacing: .5rem;
                text-transform: uppercase;
                padding: 0 0 2rem 0;
                width: 100%;
                margin: 0 auto;

                @include queries(tablet) {
                    font-size: 26px;
                    letter-spacing: 5px;
                    padding: 0 0 1rem 0;
                }

                .lineThrough {
                    margin: 1.4rem 0 0 0;

                    @include queries(tablet) {
                        margin: .5rem 0 0 0;
                    }

                    span {
                        color: $white;
                        background: $bgBody;
                        font-size: 16px;

                        @include queries(tablet) {
                            font-size: 14px;
                        }
                    }

                    &:after {
                        border-color: $white;
                    }
                }
            }

            p {
                text-align: justify;
                line-height: 1.7;
                margin: 0 0 2rem 0;

                @include queriesWshop($mediumDesk,
                    max) {
                    margin: 0 0 1rem 0;
                }

                @include queries(tablet) {
                    margin: 0 0 1rem 0;
                    line-height: 1.2;
                }
            }

            .videoWrapper {
                text-align: center;
                position: relative;

                .playBtn {
                    width: 49px;
                    height: 49px;
                    background: transparent url(../img/planes/btnPlayVid.png) no-repeat 0 0;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    cursor: pointer;
                }
            }
        }

        .planeSpecs {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .vitesse,
            .cylindres,
            .puissance {
                margin: 0 0 30px 0;
            }

            .jauge {
                width: 40px;
                height: 2px;
                background: #8d9191;
                position: relative;

                span {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: inline-block;
                    width: 0%;
                    height: 100%;
                    background: $red1;
                    transition: width 3s ease;
                }
            }

            label {
                @include wshopFont($dinFont,
                    10px,
                    false,
                    $bold,
                    #343434);
                text-transform: uppercase;
            }

            .value {
                @include wshopFont($dinFont,
                    32px,
                    false,
                    $bold,
                    #343434);

                @include queries(tablet) {
                    font-size: 25px;
                }
            }
        }

        &.travelAir,
        &.northAmerican,
        &.b18,
        &.pitts-s2b,
        &.epsilon {
            .infos {
                top: 140px;
                left: 120px;

                @include queries(tablet) {
                    top: 80px;
                    left: 105px;
                }
            }

            .planeSpecs {
                top: 140px;
                left: 730px;

                @include queriesWshop($mediumDesk,
                    max) {
                    left: 635px;
                }

                @include queries(tablet) {
                    top: 80px;
                    left: 415px;
                }

                .vitesse {
                    .jauge.grow {
                        span {
                            width: 50%;
                        }
                    }
                }

                .cylindres {
                    .jauge.grow {
                        span {
                            width: 70%;
                        }
                    }
                }

                .puissance {
                    .jauge.grow {
                        span {
                            width: 65%;
                        }
                    }
                }
            }
        }

        &.stearman,
        &.t28,
        &.j3 {
            .infos {
                top: 50px;
                right: 60px;

                @include queries(tablet) {
                    width: 340px;
                    top: 20px;
                    right: 50px;
                }

                @include orientation(portrait) {
                    width: 350px;
                    right: 20px;
                }
            }

            .planeSpecs {
                top: 50px;
                left: 120px;

                @include queries(tablet) {
                    top: 20px;
                    left: 105px;
                }

                .vitesse {
                    .jauge.grow {
                        span {
                            width: 60%;
                        }
                    }
                }

                .cylindres {
                    .jauge.grow {
                        span {
                            width: 70%;
                        }
                    }
                }

                .puissance {
                    .jauge.grow {
                        span {
                            width: 40%;
                        }
                    }
                }
            }
        }

        &.northAmerican {
            .infos {
                top: 50px;

                @include queries(tablet) {
                    width: 340px;
                    top: 40px;
                }

                @include orientation(portrait) {
                    top: 20px;
                }
            }

            .planeSpecs {
                top: 50px;
                left: 730px;

                @include queriesWshop($mediumDesk,
                    max) {
                    left: 635px;
                }

                @include queries(tablet) {
                    top: 40px;
                    left: 460px;
                }

                @include orientation(portrait) {
                    top: 20px;
                }

                .vitesse {
                    .jauge.grow {
                        span {
                            width: 75%;
                        }
                    }
                }

                .cylindres {
                    .jauge.grow {
                        span {
                            width: 90%;
                        }
                    }
                }

                .puissance {
                    .jauge.grow {
                        span {
                            width: 60%;
                        }
                    }
                }
            }
        }

        &.t28 {
            .planeSpecs {
                .vitesse {
                    .jauge.grow {
                        span {
                            width: 100%;
                        }
                    }
                }

                .cylindres {
                    .jauge.grow {
                        span {
                            width: 90%;
                        }
                    }
                }

                .puissance {
                    .jauge.grow {
                        span {
                            width: 100%;
                        }
                    }
                }
            }
        }

        &.b18,
        &.pitts-s2b,
        &.epsilon {
            .infos {
                top: 65px;

                @include queries(tablet) {
                    width: 340px;
                    top: 30px;
                }

                @include orientation(portrait) {
                    top: 20px;
                }
            }

            .planeSpecs {
                top: 65px;

                @include queriesWshop($mediumDesk,
                    max) {
                    left: 635px;
                }

                @include queries(tablet) {
                    top: 50px;
                    left: 460px;
                }

                @include orientation(portrait) {
                    top: 20px;
                }
            }
        }
    }
}

#article_fourth_box_cgv {
    display: none;
    width: 780px;
    height: 450px;
    background-color: white;
    z-index: 20;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 52px 105px 0px;

    .box_cgv_close {
        position: absolute;
        right: 12px;
        top: 6px;
        font-family: Montserrat, sans-serif;
        cursor: pointer;
        font-size: 18px;
        font-weight: 600;

        &:after {
            content: "";
            position: absolute;
            top: 19px;
            left: -1px;
            right: 0;
            height: 0.5em;
            border-top: 2px solid black;
            width: 14px;
        }
    }

    .title_cgv_box {
        position: relative;
        /*top: 78px;*/
        text-align: center;
        font-family: din, sans-serif;
        font-size: 34px;
        font-weight: 700;
        padding-bottom: 53px;

        &:after {
            content: "";
            position: absolute;
            top: 38px;
            left: 121px;
            right: 0;
            height: .5em;
            border-top: 1px solid $black;
            width: 329px;
        }
    }

    table {
        border-collapse: collapse;
        position: relative;

        /*left: 40px;
        top: 82px;*/
        tr {

            th,
            td {
                padding: 8px;
                font-family: din, sans-serif;
                font-size: 18px;
                /*line-height: 1;*/
                letter-spacing: 0.05em;
                /*font-weight: 300;*/
                color: #000000;
                border: 1px solid #ccc;
                text-align: center;
            }
        }
    }
}

.contact_faq {
    input {
        width: 100%;
        box-sizing: border-box;
    }

    section.contentWrapper {
        display: flex;
        min-height: 66vw;
        justify-content: center;
    }

    img.img_error {
        right: 0.65em;
        top: 50%;
    }

    img#iconErrcontactPhone {
        top: 42%;
    }

    div#wrapper_moncompte {
        width: 50%;
        min-width: 512px;

        .formcontainer {
            background: url(../img/bacontact.png) no-repeat;
            background-size: cover;
            background-position: center center;
            padding-bottom: 33%;

            @media only screen and (max-width: 180px) {
                padding-top: 23.3vw;
            }
        }
    }

    #contact_form .item_holder {
        margin-top: 4em;
    }

    form#contact_form {
        width: 90%;
        max-width: 598px;
        margin: 0 auto;
        height: 42.95rem;

        #message_mail {
            color: $white;
            text-align: center;
        }
    }

    .form_item {
        position: relative;
    }

    .info_popup {
        padding: 10px 0 10px 10px;
        width: 280px;

        .name {
            margin-bottom: 8px;
            text-transform: capitalize;
        }

        .adresse {
            font-size: 13px;
            color: #1d1d1d;
            line-height: 16.9px
        }
    }

    .col2 {
        width: 100%;
        max-width: 598px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        input {
            width: 100%;
            margin: 0;
            min-height: 2.60375rem;
        }

        .wrapper_txt_inputs.form_item {
            width: 49%;
            float: left;

            #contactNom {
                position: relative;
                //left: 23px;
                margin-right: 0;
            }

            #contactPrenom {
                margin-left: 0;
            }
        }

        img.valid_form {
            position: absolute;
            right: 5px;
            top: 51%;
        }
    }

    .col2.faq_contact_more_info {
        padding: 1vw 0 4vw;
        background-color: $black;
        max-width: 100%;

        .block-text {
            font-family: lato, sans-serif;
            color: #7b7b7b;
            line-height: 1.5;
            padding: 0 5%;

            p {
                padding-bottom: 1.8rem;
            }

            span {
                text-transform: uppercase;
                color: #fff;
            }
        }

        .seperator {
            background: #363636;
            height: 4vw;
            margin: 25px 8% 19px;
            width: 2px;
        }
    }

    .clear {
        clear: both;
    }

    .col1 {
        text-align: center;

        input {
            width: 100%;
            margin: 1em auto;
            min-height: 2.60375rem;
        }

        textarea#contactMessage {
            width: 100%;
            box-sizing: border-box;
            margin: 2.5em auto;
            border-radius: 5px;
            min-height: 14em;
            font-style: italic;
            padding: 10px;
        }

        img.valid_form {
            position: absolute;
            right: 6px;
            top: 51%;
        }

        #iconErrcontactMessage,
        #iconValidcontactMessage {
            top: 80%;
        }

        #reservation_formation {
            width: 100%;
            margin: 0 auto 1em;
            padding: 1em 11px;
            color: $black;
            border-radius: 3px;
            border: none;
            background-color: white;
            font: 400 13.3333px Arial;
            font-style: italic;
            border: 0 !important;
            /*Removes border*/
            min-height: 2.60375rem;
            -moz-appearance: none;
            /* Firefox */
            -webkit-appearance: none;
            /* Safari and Chrome */
            appearance: none;
            background: #FFF url(../img/arrow_dwn.png) 98% center no-repeat;
            background-size: auto;

            &::-ms-expand {
                display: none;
            }
        }
    }

    h2.titre.contact {
        text-align: center;

        .content_titre {
            display: inline-block;
            font-size: 2.4vw;
            text-transform: uppercase;
            padding-top: 4rem;
            @include wshopFont($dinFont,
                46px,
                0,
                $bold,
                $white);

            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background: $white;
            }
        }
    }

    .contactAddress {
        text-align: center;
        margin-top: 1rem;
        @include wshopFont($dinFont,
            15px,
            0,
            $regular,
            $white);
    }

    input {
        padding: 1em 11px;
        font-style: italic;
        color: black;
        border-radius: 3px;
        border: none;
    }

    #contactPhone {
        margin-top: 0px;
    }

    input::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        text-transform: uppercase;
    }

    input:-ms-input-placeholder {
        /* IE 10+ */
        text-transform: uppercase;
    }

    input:-moz-placeholder {
        /* Firefox 18- */
        text-transform: uppercase;
    }

    textarea::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        text-transform: uppercase;
    }

    textarea:-ms-input-placeholder {
        /* IE 10+ */
        text-transform: uppercase;
    }

    textarea:-moz-placeholder {
        /* Firefox 18- */
        text-transform: uppercase;
    }

    label {
        display: none;
    }

    div#sendContactFaq {
        text-align: center;

        button#btn_contact {
            width: 230px;
            background: #ba2432;
        }
    }

    div#mes_err_fieldset {
        color: #fff;
        text-align: center;
        padding: 2em;
    }

    .map_wrapper {
        width: 50%;

        #map_container {
            width: 100%;
            height: 100%;
        }
    }
}

.galerie {
    .contentWrapper {
        width: 100%;
        max-width: 1620px;
        min-width: 990px;
        min-height: 700px;
        margin: 0 auto;
        position: relative;
        background: $black;
    }

    #swiper_b18,
    #swiper_stearman,
    #swiper_t6,
    #swiper_t28,
    #swiper_travel,
    #swiper_travel {
        display: none;
    }

    #thumbs_b18,
    #thumbs_stearman,
    #thumbs_t6,
    #thumbs_t28,
    #thumbs_travel {
        display: none;
    }

    .breadcrumbs_wrapper {
        display: none;
    }

    .gallerySideNav {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: absolute;
        top: 6rem;
        left: 4%;

        a {
            color: $white;
            cursor: pointer;
            @include wshopFont($dinFont,
                20px,
                0,
                $bold,
                $white);
            text-transform: uppercase;
            writing-mode: vertical-rl;
            text-orientation: sideways;
            position: relative;
            margin: 0 0 4rem 0;

            &.actif:after {
                content: '';
                display: block;
                @extend .icon;
                @include bgIconCoord(16,
                    $col,
                    $row);
                width: 3rem;
                height: 3rem;
                position: absolute;
                top: 50%;
                left: 100%;
                transform: translateY(-50%) rotate(90deg);
            }
        }
    }

    .galleryWrapper {
        width: 88%;
        max-width: 1609px;
        margin: 0 0 0 auto;
        position: relative;

        .slider_gallery {
            padding: 0 0 0 149px;

            .swiper-slide {
                img {
                    width: 100%;
                    height: auto;
                    opacity: 0;
                    transition: opacity .3s ease;

                    @media screen and (min-width:768px) and (max-width:1024px) and (orientation:landscape) {
                        width: 100vw;
                    }

                    @media screen and (min-width:768px) and (max-width:1024px) and (orientation:portrait) {
                        width: 107vw;
                    }
                }

                &.swiper-slide-active {
                    img {
                        opacity: 1;
                    }
                }
            }

            .numSlide {
                position: absolute;
                width: 149px;
                top: 1rem;
                left: 0;
                text-align: center;

                .currentSlide {
                    @include wshopFont($dinFont,
                        56px,
                        0,
                        $bold,
                        $white);
                    line-height: 1;
                    display: inline-block;
                    margin: 0 auto;

                    &:after {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 1px;
                        background: $white;
                    }
                }

                .totalSlides {
                    display: block;
                    @include wshopFont($dinFont,
                        16px,
                        0,
                        $bold,
                        $white);
                }
            }

            .swiper-button-prev,
            .swiper-button-next {
                position: absolute;
                top: 2.8rem;
                border: solid $white;
                border-width: 0 5px 5px 0;
                display: inline-block;
                padding: 3px;
                background-image: none;
                width: 20px;
                height: 20px;
                margin-top: 0;

                &::after,
                &::before {
                    display: none;
                }
            }

            .swiper-button-prev {
                transform: rotate(135deg);
                left: 1rem;
            }

            .swiper-button-next {
                transform: rotate(-45deg);
                left: 7rem;
            }
        }

        .thumbs_gallery {
            position: absolute;
            left: 0;
            top: 100px;
            width: 298px;

            .swiper-wrapper {
                display: block;
                transform: none !important;

                .swiper-slide {
                    display: block;
                    float: left;
                    width: 149px;
                    height: 99px;
                    position: relative;
                    cursor: pointer;

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 149px;
                        height: 99px;
                        box-sizing: border-box;
                        border: 2px solid transparent;
                        background: rgba(0, 0, 0, .5);
                    }

                    &.swiper-slide-active {
                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 149px;
                            height: 99px;
                            box-sizing: border-box;
                            border: 2px solid $white;
                            background: transparent;
                        }
                    }
                }
            }
        }
    }
}

.publication_index {
    .breadcrumbs {
        display: none;
    }

    .wrapperTwoCol {
        background: #0d0d0e;

        @include queries(smallDesk) {
            .leftCol h1 {
                position: absolute;
                top: 3rem;
            }
        }
    }

    .leftCol {
        background: $black url('../img/visu_events.jpg') no-repeat center top;
        background-size: cover;
        position: relative;

        @include queries(smallDesk) {
            width: calc(100%/4);
        }

        .navigationWrapper {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            text-align: right;

            .prevEvent,
            .nextEvent {
                content: '';
                display: block;
                @extend .icon;
                @include bgIconCoord(4,
                    $col,
                    $row);
                width: 3rem;
                height: 3rem;
                cursor: pointer;
            }

            .prevEvent {
                margin: 0 0 1rem auto;
            }

            .nextEvent {
                margin: 1rem 0 0 auto;
                transform: rotate(180deg);
            }

            .prevDate,
            .currentDate,
            .nextDate {
                margin: 0 20px 0 0;
                transition: all .3s ease;
            }

            .prevDate,
            .nextDate {
                display: block;
                @include wshopFont($adamFont,
                    75px,
                    0,
                    $bold,
                    $white);
                opacity: .5;
                cursor: pointer;
            }

            .currentDate {
                display: flex;
                flex-direction: column;
                align-items: stretch;
                @include wshopFont($adamFont,
                    110px,
                    0,
                    $bold,
                    $white);

                .day {
                    line-height: 1.2;
                    transition: all .3s ease;

                    &:after {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 1px;
                        background: $white;
                    }
                }

                .monthYear {
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        display: block;
                        top: 0;
                        left: 150%;
                        width: 45px;
                        height: 1px;
                        margin: -1px 0 0 0;
                        background: $white;
                    }
                }
            }

            .monthYear {
                display: block;
                @include wshopFont($dinFont,
                    .14em,
                    0,
                    $bold,
                    $white);
                text-transform: uppercase;
                transition: all .3s ease;
            }
        }
    }

    .rightCol {
        flex: 1;

        .publicationSlider {
            height: 800px;
            min-height: 800px;

            .swiper-slide {
                height: auto !important;
                //margin-bottom: 92px !important;
            }

            .eventWrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                opacity: .2;
                transition: opacity .5s ease;

                //height: 487px;
                .eventVisu {
                    position: relative;
                    width: 35%;
                    text-align: center;
                    margin: 0 2% 0 0;

                    @include queries(smallDesk) {
                        width: 30%;
                    }

                    img {
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        max-height: 487px;
                    }
                }

                .eventDetails {
                    width: 40%;
                    text-align: justify;
                    margin: 0 0 0 2%;

                    @include queries(smallDesk) {
                        width: 62%;
                    }

                    h2 {
                        @include wshopFont($dinFont,
                            26px,
                            0,
                            $bold,
                            $white);
                        text-transform: uppercase;
                        text-align: center;
                        margin: 0 0 1rem 0;
                        padding: 0;

                        .lineThrough {
                            font-size: 16px;

                            span {
                                color: $white;
                                background: #0d0d0e;
                            }

                            &:after {
                                border-color: $white;
                            }
                        }
                    }

                    .publication_txt {
                        @include wshopFont($latoFont,
                            13px,
                            0,
                            $bold,
                            #737272);

                        a {
                            color: #737272;
                            text-decoration: underline;

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }

            .swiper-slide-active .eventWrapper {
                opacity: 1;
            }
        }
    }
}

/*atelier*/
.satellite_atelier {
    .breadcrumbs_wrapper {
        display: none;
    }

    .contentWrapper {
        position: relative;
        padding-bottom: 20rem;
    }

    .atelier_wrapper {
        width: 90%;
        height: 115vh;
        margin: 0 auto;
        position: relative;

        .slider_atelier {
            .swiper-wrapper {
                height: 1139px;

                .swiper-slide {
                    overflow: hidden;

                    img {
                        object-fit: cover;
                        object-position: 50% 50%;
                        width: inherit;
                        height: inherit;
                    }
                }
            }

            .swiper-button-prev,
            .swiper-button-next {
                position: absolute;
                display: inline-block;
                padding: 3px;
                width: 67px;
                height: 83px;
                margin-top: 0;
                background-image: url('../img/atelier/icon/slider_arrow.jpg');
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;

                &::after,
                &::before {
                    content: "";
                    display: none;
                }
            }

            .swiper-button-next {
                top: 31rem;

                @media screen and (max-width:1680px) {
                    top: 28rem;
                }
            }
        }
    }

    .wrapper_text {
        color: white;
        background: #1C202B;
        padding: 2rem;
        width: 100%;
        max-width: 480px;
        margin: 3rem 0 0 7rem;
        z-index: 10;
        position: relative;
        bottom: 67rem;

        @media screen and (min-width: 800px) and (max-width:820px) {
            margin-left: 8rem;
        }

        @media screen and (min-width: 768px) and (max-width:790px) {
            margin-left: 6.5rem;
        }

        .block_text {
            margin: 0 auto;
            width: 90%;
            align-items: center;
            text-align: justify;
            font-size: 15.8px;
            line-height: 22px;
            padding: 0.5rem 0 1rem 0;
            font-style: italic;
            font-family: $latoFont;
            font-weight: $regular;
            word-spacing: 2px;
            font-weight: 500;
        }

        .intro {
            font-style: normal;

            h1 {
                padding: 2rem 0 5rem 0;
                text-align: center;
                font-family: $dinFont;
                font-weight: $bold;
                font-size: 37px;
            }

            p {
                font-size: 18px;
                font-family: $latoFont;
                font-weight: $medium;
                /*width: 90%;*/
            }
        }
    }

    .ico_maintenance::after {
        content: url('../img/atelier/icon/atelier_maintenance.jpg');
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2rem 0 2.5rem 0;
    }
}

/*-------------------------------
    Accompagnement
-------------------------------*/
.satellite_devenir_proprietaire {
    #ariane {
        display: none;
    }

    .accompagnementWrapper {
        max-width: 1680px;
        margin: 0 auto;
        background-color: $black;

        img {
            max-width: 100%;
        }

        .ac-header {
            height: auto;
            max-height: 288px;
            position: relative;

            img {
                width: 100%;
            }

            .text {
                position: absolute;
                top: 42%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
            }

            .title {
                font-family: $dinFont;
                font-weight: $bold;
                font-size: 33 / 16 * 1rem;
                line-height: 1.2;
                color: $bgHeaderFooter;
                text-transform: uppercase;

                @media screen and (max-width: 1024px) and (min-width: 768px) {
                    font-size: 28 / 16 * 1rem;
                }
            }

            .sub-title {
                font-family: $latoFont;
                font-weight: $medium;
                font-size: 18 / 16 * 1rem;
                line-height: 1.2;
                color: $bgHeaderFooter;
                font-style: italic;
                margin-top: 0.5rem;

                @media screen and (max-width: 1024px) and (min-width: 768px) {
                    font-size: 1rem;
                }
            }
        }

        .ac-temoignages-wrapper {
            .sub-cats-box {
                width: 1440px;
                max-width: 100%;
                height: 430px;
                margin: -3rem auto 0;
                background-color: #1b1f28;
                padding: 4.5rem 3rem;
                position: relative;

                @media screen and (max-width: 1024px) and (min-width: 768px) {
                    padding: 4.5rem 1rem;
                    margin: -.3rem auto 0;
                }

                .tabs {
                    display: flex;
                    align-items: flex-start;

                    .tab-link {
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        flex: auto;
                        min-width: 140px;
                        position: relative;
                        padding-bottom: 1rem;
                        cursor: pointer;

                        @media screen and (max-width: 1366px) and (min-width: 768px) {
                            min-width: 80px;
                        }

                        &:before {
                            content: "";
                            width: 1px;
                            height: 55px;
                            background-color: #32363e;
                            display: block;
                            position: absolute;
                            top: 0;
                            right: 0;
                        }

                        &:after {
                            content: "";
                            width: 0;
                            height: 1px;
                            display: block;
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            transition: width .2s ease;
                        }

                        &:last-child:before {
                            display: none;
                        }

                        &:hover:after {
                            background-color: $white;
                            width: 70px;
                        }

                        &.current {
                            span.icon {
                                &-elaboration {
                                    background-image: url('../img/accompagnement/icons/elaboration_active.png');
                                }

                                &-research {
                                    background-image: url('../img/accompagnement/icons/research_active.png');
                                }

                                &-proposals {
                                    background-image: url('../img/accompagnement/icons/proposals_active.png');
                                }

                                &-travel {
                                    background-image: url('../img/accompagnement/icons/travel_active.png');
                                }

                                &-talks {
                                    background-image: url('../img/accompagnement/icons/talks_active.png');
                                }

                                &-management {
                                    background-image: url('../img/accompagnement/icons/management_active.png');
                                }

                                &-conveying {
                                    background-image: url('../img/accompagnement/icons/conveying_active.png');
                                }

                                &-settings {
                                    background-image: url('../img/accompagnement/icons/settings_active.png');
                                }

                                &-maintenance {
                                    background-image: url('../img/accompagnement/icons/maintenance_active.png');
                                }
                            }

                            span.cat-text {
                                color: #ef0000;
                            }

                            &:after {
                                width: 70px;
                                background-color: #ef0000;
                            }
                        }

                        span.icon {
                            width: 40px;
                            height: 40px;
                            background-size: 100%;
                            background-repeat: no-repeat;
                            background-position: 50% 50%;
                            display: block;
                            margin: 0 auto 1.5rem;

                            @media screen and (max-width: 1024px) and (min-width: 768px) {
                                width: 35px;
                            }

                            &-elaboration {
                                background-image: url('../img/accompagnement/icons/elaboration.png');

                                & + span.cat-text {
                                    max-width: 81px;
                                }
                            }

                            &-research {
                                background-image: url('../img/accompagnement/icons/research.png');
                                width: 35px;

                                @media screen and (max-width: 1024px) and (min-width: 768px) {
                                    width: 30px;
                                }
                            }

                            &-proposals {
                                background-image: url('../img/accompagnement/icons/proposals.png');
                                width: 47px;

                                @media screen and (max-width: 1024px) and (min-width: 768px) {
                                    width: 42px;
                                }
                            }

                            &-travel {
                                background-image: url('../img/accompagnement/icons/travel.png');
                            }

                            &-talks {
                                background-image: url('../img/accompagnement/icons/talks.png');
                                width: 42px;

                                @media screen and (max-width: 1024px) and (min-width: 768px) {
                                    width: 37px;
                                }
                            }

                            &-management {
                                background-image: url('../img/accompagnement/icons/management.png');
                                width: 37px;

                                @media screen and (max-width: 1024px) and (min-width: 768px) {
                                    width: 32px;
                                }

                                & + span.cat-text {
                                    max-width: 80px;
                                }
                            }

                            &-conveying {
                                background-image: url('../img/accompagnement/icons/conveying.png');
                                width: 43px;

                                @media screen and (max-width: 1024px) and (min-width: 768px) {
                                    width: 38px;
                                }

                                & + span.cat-text {
                                    max-width: 110px;
                                }
                            }

                            &-settings {
                                background-image: url('../img/accompagnement/icons/settings.png');
                                width: 36px;

                                @media screen and (max-width: 1024px) and (min-width: 768px) {
                                    width: 31px;
                                }
                            }

                            &-maintenance {
                                background-image: url('../img/accompagnement/icons/maintenance.png');
                                width: 38px;

                                @media screen and (max-width: 1024px) and (min-width: 768px) {
                                    width: 33px;
                                }
                            }
                        }

                        span.cat-text {
                            display: block;
                            font-family: $latoFont;
                            font-weight: $regular;
                            font-size: 14 / 16 * 1rem;
                            line-height: 1;
                            color: $white;
                            max-width: 100px;
                            transition: color .2s ease;

                            @media screen and (max-width: 1024px) and (min-width: 768px) {
                                font-size: 11 / 16 * 1rem;
                            }
                        }
                    }
                }

                .contents {
                    padding: 3rem;
                    margin-top: 1rem;
                }

                .tab-content {
                    opacity: 0;
                    visibility: hidden;
                    height: 0;
                    transition: opacity 1s ease;
                    text-align: center;
                    max-width: 50%;
                    margin: 0 auto;

                    @media screen and (max-width: 1366px) and (min-width: 768px) {
                        max-width: 100%;
                    }

                    &.current {
                        opacity: 1;
                        visibility: visible;
                        height: 100%;
                    }

                    h1 {
                        font-family: $dinFont;
                        font-weight: $medium;
                        font-size: 20 / 16 * 1rem;
                        line-height: 1.2;
                        color: $white;
                        text-transform: uppercase;
                        margin: 0 0 1rem;

                        &:after,
                        &:before {
                            content: "";
                            width: 70px;
                            height: 1px;
                            background-color: #8d8f94;
                            display: inline-block;
                            transform: translateY(-5px);
                        }

                        &:after {
                            margin-left: 1rem;
                        }

                        &:before {
                            margin-right: 1rem;
                        }
                    }

                    p {
                        font-family: $latoFont;
                        font-weight: $regular;
                        font-size: 15 / 16 * 1rem;
                        line-height: 1.3;
                        color: $white;
                        word-spacing: 2px;
                    }
                }
            }

            .temoignages {
                margin: 3rem auto 0;
                padding: 1rem 0 4rem;
                max-width: 1140px;

                @media screen and (max-width: 1190px) and (min-width: 700px) {
                    padding: 1rem 3rem 4rem;
                }

                .temoignage {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    padding: 3rem 0;

                    .img-wrapper {
                        width: 265px;
                        height: 265px;
                        min-width: 265px;
                    }

                    img {
                        width: 100%;
                        height: auto;
                    }

                    .quote {
                        font-family: $latoFont;
                        font-weight: $regular;
                        color: $white;
                        margin-top: .5rem;

                        &.no-title {
                            margin-top: 1.5rem;

                            .quote-desc p {
                                text-indent: 20px;

                                &:before {
                                    top: -12px;
                                    left: -28px;
                                }

                                &:after {
                                    transform: translateX(-21px);
                                }
                            }
                        }

                        .quote-desc {
                            p {
                                position: relative;
                                font-style: italic;
                                font-size: 13 / 16 * 1rem;
                                line-height: 1.2;
                                text-align: justify;

                                &:before,
                                &:after {
                                    content: '"';
                                    font-size: 50 / 16 * 1rem;
                                    font-weight: $regular;
                                    font-style: italic;
                                    line-height: 1;
                                    position: absolute;
                                    color: #b61623;

                                    @media screen and (min-width:0\0) {
                                        transform: translate(0, 5px);
                                    }
                                }

                                &:before {
                                    top: -42px;
                                    left: -8px;
                                }

                                &:after {
                                    bottom: auto;
                                    right: auto;
                                    transform: translateX(-2px);
                                }
                            }

                            .title {
                                font-weight: $regular;
                                font-style: italic;
                                font-size: 13 / 16 * 1rem;
                                line-height: 1.2;
                                margin-bottom: 1rem;
                                padding-left: 1.5rem;
                            }
                        }

                        .author {
                            font-size: 19 / 16 * 1rem;
                            line-height: 1;
                            color: #b61623;
                            font-style: italic;
                            margin: 1rem 0 0 14rem;

                            @media screen and (max-width: 1366px) and (min-width: 768px) {
                                margin: 1rem 0 0 7rem;
                            }

                            &:before,
                            &:after {
                                content: '-';
                                color: inherit;
                            }

                            &:before {
                                margin-right: .3rem;
                            }

                            &:after {
                                margin-left: .3rem;
                            }
                        }
                    }

                    &.left {
                        .quote {
                            margin-left: 3rem;
                        }
                    }

                    &.right {
                        .quote {
                            margin-right: 3rem;
                        }

                        .img-wrapper {
                            order: 1;
                        }
                    }
                }
            }
        }
    }
}

/* cgv */
.order_cgv {
    #ariane {
        display: none;
    }

    .contentWrapper {
        > div {
            color: $white;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h1 {
                width: 33%;
                height: 100vh;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: fixed;
                z-index: 1;
                top: 0;
                left: 0;
                background: url(../img/bandeau_cgv.jpg) no-repeat center top;
                background-size: cover;

                span {
                    display: inline-block;
                    font-family: $dinFont;
                    font-size: 46px;
                    letter-spacing: 0;
                    font-weight: 700;
                    color: $white;
                    text-transform: uppercase;
                    position: relative;
                    text-align: center;
                    line-height: 1;
                    width: 77%;
                    margin-top: -2.2rem;

                    &:before {
                        content: '';
                        position: absolute;
                        bottom: -1rem;
                        display: block;
                        width: 100%;
                        height: 1px;
                        background: $white;
                        margin: 0 0 0 0;
                    }

                    &:after {
                        content: "Aero Vintage Academy";
                        font-family: $vidalokaFont;
                        font-size: 16px;
                        font-weight: 400;
                        font-style: italic;
                        text-align: center;
                        text-transform: none;
                        color: $white;
                        position: absolute;
                        bottom: -2.5rem;
                        display: block;
                        width: 100%;
                    }
                }
            }

            .content_sat {
                float: right;
                width: 67%;
                background-color: $black;
                padding: 2.5vw 3vw;
                column-count: 2;
                column-gap: 3vw;
                text-align: justify;
                margin: 0 0 0 33%;

                > .row {
                    #bloc_0 {
                        h2 {
                            margin: 0 0 26px 0;
                        }
                    }

                    .content {
                        font-family: $dinFont;
                        font-size: 14px;
                        letter-spacing: 0;
                        font-weight: 500;
                        color: #bfbfbf;
                        letter-spacing: -.05em;
                        line-height: 18px;

                        h2 {
                            font-family: $dinFont;
                            font-size: 18px;
                            letter-spacing: 0;
                            font-weight: 700;
                            color: $white;
                            letter-spacing: .07em;
                            margin: 31px 0 18px 0;
                            text-align: left;
                            line-height: 1.3;
                        }

                        h3 {
                            margin-bottom: 17px;
                            font-size: 14px;
                            text-decoration: underline;
                        }

                        ul {
                            li {}
                        }

                        a {
                            color: $red1;
                        }
                    }

                    #bloc_16 {
                        div {
                            margin-top: 19px;
                            text-align: right;
                            color: $red1;
                        }
                    }
                }
            }
        }
    }

    footer {
        position: relative;
        z-index: 2;
    }
}

body.satellite_planes {
    .planesWrapper {
        @media screen and (max-width: 1112px) {
            min-width: 100%;

            .sectionBloc .infos {
                top: -2rem;
                transform: scale(0.825);
            }
        }

        @media screen and (max-width: 1020px) {
            min-width: 990px;
        }

        @media screen and (max-width: 834px) {
            min-width: 100%;
        }

        @media screen and (max-width: 780px) {
            min-width: 990px;
        }
    }

    .playBtn {
        display: none;
    }
}