/*     GLOBAL      */
$smallDesk: 1200px;
$milleDeuxQuatreVingt: 1280px;

.clearfix {
    @include clearfix();
}

.f_left {
    float: left;
}

.f_right {
    float: right;
}

.cache,
.hide {
    display: none;
}

body {
    margin: 0;
    background: $bgBody;
    padding: 114px 0 0 0;

    &.body_login {
        background: $black;
    }
}

#modboxpromo {
    top: 40%;
    width: 680px;
    height: 280px;
    flex-direction: column;
    text-align: center;
    padding-top: 5%;

    div {
        margin-bottom: 1rem;
        font-size: 20px;
    }

    #btn_cmd_valid {
        width: 200px !important;
    }
}

/* LIGHTBOX */
.modal_shade {
    display: none;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
}

#shad {
    display: none;
    position: fixed;
    z-index: 15;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
}

.lightbox {
    display: none;
    position: absolute;
    z-index: 15;
    background: $white;
    top: 114px;
    left: 50%;
    transform: translate(-50%, 0);

    &.fixed {
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .closeLighbox {
        position: absolute;
        right: 0.8rem;
        top: 0.8rem;
        @extend %close;
    }
}

.closeReservation {
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
    @extend %close;
}

/* COOKIES BOTTOM BAR */
#cookies_block_footer {
    position: fixed;
    z-index: 10;
    width: 100%;
    left: 0;
    bottom: 0;
    background: $black;
    @include wshopFont($dinFont, 11px, false, $regular, $white);
    line-height: 1;
    text-align: center;
    padding: 0.5rem 0;

    a {
        text-decoration: underline;

        span {
            color: #fff !important;
        }
    }

    .cookies_btn {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        margin: 0 0 0 1rem;
        padding: 0.4rem;
        background: $red1;
        font-size: 10px;
        color: $white;
        text-transform: uppercase;
    }
}

/* Lightbox reservation */
.reservationSlide {
    display: none;
}

#reservationLightbox {
    position: relative;
    width: 100%;
    margin: 0 auto 2rem auto;
    height: auto;
    background: $white;

    &.fromHome {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: none;
    }

    #reservation_mention_wrapper {
        margin-top: 17px;

        #reservation_mention_text {
            @include wshopFont($dinFont, 13px, 0rem, $extrabold, $red1);
            font-style: italic;
            text-transform: none;
        }
    }
}

.nos-formations {
    #reservationLightbox {
        width: 100%;
    }
}

.category {
    #reservationLightbox {
        width: 89%;
        margin: 0 0 0 120px;
    }

    .formLine.dateTime {
        .promo_code_exp40 {
            margin-bottom: 1rem;
        }
    }
}

/* WRAPPER WITH 2 COLS boutique - mon compte - events - CGV - contact - FAQ -  */
.wrapperTwoCol {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    position: relative;
    width: 99%;
    min-width: 990px;
    max-width: 1600px;
    margin: 0 auto;

    .leftCol {
        display: flex;
    }

    .leftCol {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: calc(100% / 3);

        h1 {
            display: inline-block;
            @include wshopFont($dinFont, 46px, 0.05rem, $bold, $white);
            text-transform: uppercase;
            position: relative;

            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background: $white;
            }

            span {
                display: block;
                position: absolute;
                width: 100%;
                @include wshopFont($vidalokaFont,
                    13px,
                    0.01rem,
                    $regular,
                    $white );
                text-align: center;
                font-style: italic;
                margin: 0.4rem 0 0 0;
            }
        }
    }
}

.events {
    .wrapperTwoCol .leftCol h1:after {
        display: none;
    }
}

/* TITLES */
/* Titles with subtitles linethrough */
.titleWithSubs {
    display: inline-block;
    @include wshopFont($dinFont, 31px, 0.35rem, $bold, $black);
    text-transform: uppercase;
    text-align: center;
    padding: 2rem 0 2rem 0;

    .lineThrough {
        display: block;
        position: relative;
        @include wshopFont($vidalokaFont,
            19px,
            $letterSpacing,
            $regular,
            #2a3235);
        text-align: center;

        &:after {
            content: '';
            display: inline-block;
            border-bottom: 1px solid #2a3235;
            width: 100%;
            position: absolute;
            left: 0;
            top: 50%;
            z-index: 1;
        }

        span {
            position: relative;
            z-index: 2;
            display: inline-block;
            background: $white;
            text-transform: none;
            font-style: italic;
            padding: 0 0.6rem;
        }
    }
}

.bandeauFormations {
    .titleWithSubs .lineThrough {
        span {
            background: none;
        }

        &:after {
            display: none;
        }
    }
}

fieldset {
    border: 0px;
}

.selectStyled,
.selectStyledProduct {
    position: relative;
    cursor: pointer;

    .clone {
        @include wshopFont($latoFont, 16px, 0, $bold, #2a3235);
        content: '';
        display: inline-block;
        background: url('../img/select_arrow.png') 98% center no-repeat;
        width: 100% !important;
        height: 42px;
        overflow: hidden;
    }

    select {
        width: 100%;
        height: 100%;
        cursor: pointer;
        @include wshopFont($latoFont, 16px, 0, $bold, #2a3235);
    }
}

/* Captcha Google */
.g-recaptcha {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 14px;
}

body.product_page {
    min-width: 1365px;
}

/* masquer le template de recherche au chargement des pages */
[v-cloak] {
    display: none;
}