/********************/


/* 		FONTS 		*/


/********************/


/* WEIGHT */

$thin: 100;
$extralight: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;



/* LETTER SPACING */

$letterSpacing: .02rem;


/* INIT FONT FACE */

@font-face {
    font-family: 'vidaloka-regular';
    src: url(../fonts/vidaloka/vidaloka-regular.woff2) format('woff2'),
    url(../fonts/vidaloka/vidaloka-regular.woff) format('woff');
    font-weight: $regular;
    font-style: normal;
}

@font-face {
    font-family: 'adam';
    src: url(../fonts/adam/regular/adam-regular.woff2) format('woff2'),
    url(../fonts/adam/regular/adam-regular.woff) format('woff');
    font-weight: $regular;
    font-style: normal;
}

@font-face {
    font-family: 'din';
    src: url(../fonts/din/regular/din-regular.woff2) format('woff2'),
    url(../fonts/din/regular/din-regular.woff) format('woff');
    font-weight: $regular;
    font-style: normal;
}

@font-face {
    font-family: 'din';
    src: url(../fonts/din/light/din-light.woff2) format('woff2'),
    url(../fonts/din/light/din-light.woff) format('woff');
    font-weight: $light;
    font-style: normal;
}

@font-face {
    font-family: 'din';
    src: url(../fonts/din/medium/din-medium.woff2) format('woff2'),
    url(../fonts/din/medium/din-medium.woff) format('woff');
    font-weight: $medium;
    font-style: normal;
}

@font-face {
    font-family: 'din';
    src: url(../fonts/din/bold/din-bold.woff2) format('woff2'),
    url(../fonts/din/bold/din-bold.woff) format('woff');
    font-weight: $bold;
    font-style: normal;
}

@font-face {
    font-family: 'dincond';
    src: url(../fonts/dincond/regular/dincond-regular.woff2) format('woff2'),
    url(../fonts/dincond/regular/dincond-regular.woff) format('woff');
    font-weight: $regular;
    font-style: normal;
}

@font-face {
    font-family: 'lato';
    src: url(../fonts/lato/regular/lato-regular.woff2) format('woff2'),
    url(../fonts/lato/regular/lato-regular.woff) format('woff');
    font-weight: $regular;
    font-style: normal;
}

@font-face {
    font-family: 'lato';
    src: url(../fonts/lato/light/lato-light.woff2) format('woff2'),
    url(../fonts/lato/light/lato-light.woff) format('woff');
    font-weight: $light;
    font-style: normal;
}

@font-face {
    font-family: 'lato';
    src: url(../fonts/lato/bold/lato-bold.woff2) format('woff2'),
    url(../fonts/lato/bold/lato-bold.woff) format('woff');
    font-weight: $bold;
    font-style: normal;
}

$vidalokaFont: 'vidaloka-regular', sans-serif;
$adamFont: 'adam', sans-serif;
$dinFont: 'din', sans-serif;
$dincondFont: 'dincond', sans-serif;
$latoFont: 'lato', sans-serif;
$primaryFont: $latoFont;
$secondaryFont: $primaryFont;
