/*****************/
/* FORM ELEMENTS */
/*****************/
/* Errors */
img.img_error,
img.valid_form {
    opacity: 1;
    display: block;
    position: absolute;
    cursor: pointer;
    top: 66.5%;
    right: 5.3rem;
    transform: translate(0, -50%);
}

table.tooltip {
    color: $white;
    font-family: arial, sans-serif;
    font-size: 12px;
    position: absolute;
    text-align: left;
    width: 300px;

    td.content_tooltip {
        border: 1px solid #ccc;
        color: #666;
        min-width: 100px;
        background: #eaeaea;

        div#erreur_msg {
            padding: 10px 15px;
            color: #444;
            text-transform: none;
            text-indent: 0 !important;

            p.title_toolltip {
                color: $red1;
                font-weight: bold;
            }
        }
    }
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-style: italic;
    color: #ccc;
}

::-moz-placeholder {
    /* Firefox 19+ */
    font-style: italic;
    color: #ccc;
}

:-ms-input-placeholder {
    /* IE 10+ */
    font-style: italic;
    color: #ccc;
}

:-moz-placeholder {
    /* Firefox 18- */
    font-style: italic;
    color: #ccc;
}

/* Form element wrappers */
.formItem {
    display: inline-block;
    position: relative;

    input,
    button,
    select {
        @include wshopFont($dinFont, 15px, 0.05rem, $medium, $bgInputs);
        width: 100%;
        height: 100%;
        padding-left: 0.5rem;
    }

    input,
    button {
        border-radius: 3px;
        padding: 0;
        box-sizing: border-box;

        &.whiteB {
            border: 1px solid #000;
            background: #fff;
        }

        &.greyB {
            border: 1px solid #bababa;
            background: #fff;

            &.hasDatepicker {
                padding-left: 0.5rem;
            }
        }

        &.borderR {
            @include wshopFont($dinFont, 15px, 0.05rem, $medium, $bgInputs);
        }
    }

    input {
        text-indent: 0.5rem;
    }

    button {
        text-indent: 0;
    }

    &.select {
        @include selectFormat($selectWidth: null,
            $selectHeight: 40px,
            $selectBackground: white,
            $selectBorder: 1px solid #000,
            $selectBorderRadius: 3px);

        &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 4px 0 4px;
            border-color: #000000 transparent transparent transparent;
            position: absolute;
            top: 50%;
            right: 0.8rem;
            transform: translateY(-50%);
        }
    }

    select {
        background: transparent;
        border: none;
        font-size: 15px;
        appearance: none;
        padding: 0.25rem;

        &::-ms-expand {
            opacity: 0;
            z-index: 5;
        }
    }

    &.content_form {
        select {
            font-size: 15px;
            font-weight: 400;
            font-family: Arial;
        }
    }

    &.check.color {
        display: inline;
        width: 20px;
        height: 20px;
        position: relative;
        margin: 0 1rem 0 0;

        input {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }

        label {
            display: block;
            width: 100%;
            height: 100%;
            border: 1px solid #bababa;
        }
    }

    &.submit {
        cursor: pointer;
    }

    label.inputErr {
        color: $red1;
    }

    input.inputErr {
        border-color: $red1;
    }
}

.txt_civilite {
    @include wshopFont($dinFont, 12px, $letterSpacing, $bold, $black);
    text-transform: uppercase;
}

.formSubmit {
    position: relative;
}

.formRow {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.threeCols {
        justify-content: space-between;

        .formItem {
            width: 32.5%;
        }
    }

    .civilite {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}

/* Checboxes */
[type='checkbox']:not(:checked),
[type='checkbox']:checked {
    position: absolute;
    left: -9999px;

    + label {
        position: relative;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:before {
            content: '';
            width: 18px;
            height: 18px;
            border: 1px solid $black;
            background: $white;
            margin: 0 0.5rem 0 0;
        }

        &:after {
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            background: $black;
        }
    }
}

[type='checkbox']:not(:checked) + label:after {
    opacity: 0;
}

[type='checkbox']:checked + label:after {
    opacity: 1;
    width: 16px;
    height: 16px;
}

#reservationLightbox {

    [type='checkbox']:not(:checked),
    [type='checkbox']:checked {
        + label {
            position: relative;
            cursor: pointer;
            padding: 0 0 0 1rem;

            &:before {
                position: absolute;
                top: 50%;
                left: 7px;
                transform: translateY(-50%);
            }

            &:after {
                top: 50%;
                left: 9px;
                transform: translateY(-50%);
            }
        }
    }
}

/* Radios */
[type='radio']:not(:checked),
[type='radio']:checked {
    position: absolute;
    left: -9999px;

    + label {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        &:before {
            content: '';
            width: 16px;
            height: 16px;
            border: 1px solid $black;
            background: $white;
            border-radius: 50%;
            margin: 0 0.5rem 0 0;
        }

        &:after {
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            background: $black;
        }
    }
}

[type='radio']:not(:checked) + label:after {
    opacity: 0;
}

[type='radio']:checked + label:after {
    opacity: 1;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: $red1;
}

#lightbox_achat_express {
    [type='radio'] {

        &:checked + label:after,
        &:not(:checked) + label:after,
        &:checked + label:before,
        &:not(:checked) + label:before {
            display: none;
        }

        &:checked + label,
        &:not(:checked) + label {
            padding: 0;
        }
    }
}

/* Lightbox cmpid=adwords */
#popup_inscription_home {
    opacity: 0;

    &.active {
        opacity: 1;
    }

    width: 624px;
    height: 470px;

    #formu_inscription_news {
        text-align: center;

        #conf_inscription_news {
            #intro {
                width: 420px;
                margin: 0 auto;
                text-align: left;
                font-family: $latoFont;
                font-size: 14px;
                letter-spacing: 0.02rem;
                font-weight: 600;
                color: #929191;
                padding: 1.5rem 0 2.95rem 0;
            }

            button {
                width: 200px;
                height: 35px;
                background: #bd212e;
                color: #fff;
                border: 0;
                line-height: 35px;
                appearance: none;
                display: inline-block;
                font-family: $dincondFont;
                font-size: 15px;
                letter-spacing: 0.1rem;
                font-weight: 400;
                text-align: center;
                text-transform: uppercase;
                padding: 0;
                border-radius: 4px;
                transition: all 0.3s ease;
                cursor: pointer;
                margin-left: 1rem;
            }
        }
    }

    h3 {
        display: inline-block;
        text-align: center;
        @include wshopFont($dinFont, 31px, -0.1rem, 600, $black);
        text-transform: uppercase;
        padding: 4.5rem 0 2.15rem 0;
        font-size: 31.5px;
        letter-spacing: 0px;
        font-weight: 600;
        line-height: 1.135;

        &:after {
            content: '';
            display: block;
            margin: 0.99rem 0 0 0;
            border-bottom: 1px solid $black;
        }
    }
}

/* Inscription adwords */
#popup_inscription_home {
    label {
        display: block;
        width: 420px;
        margin: 0 auto 3px;
        text-align: left;
        @include wshopFont($dinFont, 13px, $letterSpacing, $bold, $black);
        text-transform: uppercase;
    }

    .button.dark {
        width: 245px;
        height: 45px;
        background: #bd212e;
        color: $white;
        border-color: $red1;
        line-height: 45px;
    }

    #email_inscription_home {
        width: 420px;
        height: 40px;
        border: 1px solid #ccc;
    }

    .cmpidMentions {
        width: 420px;
        margin: 0 auto;
        text-align: left;
        @include wshopFont($latoFont,
            14px,
            $letterSpacing,
            600,
            lighten(#737272, 12%));
        padding: 1.5rem 0 2.95rem 0;
    }
}

/* Reservation form */
.reservationFormWrapper {
    width: 100%;
    display: block;
    margin: 0 auto;
    clear: both;
    padding-bottom: 4rem;

    .select,
    .input,
    input {
        border-color: #bababa;
    }

    #reservation_nb_passagers {
        width: 50px;
    }

    .formItem {
        margin-right: 2rem;

        &:last-child {
            margin-right: 0;
        }
    }

    .formLine {
        padding: 4rem 0;
        border-bottom: 1px solid #dbdbdb;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include queries(mediumDesk) {
            flex-direction: column;
        }

        &.video {
            h3 {
                width: 25%;
                text-align: right;
                margin-right: 4rem;
            }

            div.flex {
                display: flex;
            }

            .vidImgWrapper {
                position: relative;
                margin-left: 2rem;

                img {
                    vertical-align: top;
                }

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 80px;
                    height: 80px;
                    border: 3px solid #fff;
                    border-radius: 50%;
                    background: rgba(0, 0, 0, 0.6);
                }

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 51%;
                    transform: translate(-50%, -50%);
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 18px 0 18px 28px;
                    border-color: transparent transparent transparent #ffffff;
                }
            }
        }

        h3 {
            @include wshopFont($dinFont, 24px, 0, $extrabold, $black);
            text-transform: uppercase;
            width: 50%;
            text-align: center;

            @include queries(mediumDesk) {
                width: auto;
                margin-right: 0;
                margin-bottom: 3rem;
            }
        }

        &.passengers {
            align-items: center;

            h3 {
                width: 25%;
                text-align: right;
                margin-right: 4rem;
                align-self: flex-start;
            }
        }

        .buttonBox {
            width: calc((286px * 2) + 2.5rem);
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 2.5rem;
            padding-bottom: 2rem;

            button {
                width: 286px;
            }
        }

        &.dateTime {
            flex-direction: column;

            h3 {
                margin-bottom: 2rem;
            }

            .formDateWrapper {
                display: flex;
                justify-content: center;
                width: 700px;

                .reservation_hours_select option[disabled] {
                    display: none !important;
                }

                .isSpecificEpsilon ~ .ui-datepicker-trigger {
                    display: none;
                }
            }

            .formItem.date {
                width: 206px;
                height: 40px;

                img {
                    position: absolute;
                    top: 0.5rem;
                    right: 0.5rem;
                }
            }

            label {
                position: absolute;
                margin: -1.4rem 0 0 0;
                @include wshopFont($adamFont, 14px, 0.1rem, $extrabold, $black);
                text-transform: uppercase;
            }

            .formItem.select {
                width: 206px;
                background-position: 88% center;

                &:last-child {
                    margin-right: 2rem;
                }
            }
        }
    }

    .nbPassengers {
        padding: 2rem 0;

        label {
            display: inline-block;
            @include wshopFont($dinFont, 15px, 0.2rem, $bold, $black);
            line-height: 40px;
            text-transform: uppercase;
        }

        .select {
            width: 60px;
            background-position: 86% center;
        }
    }

    .helmetWrapper {
        position: relative;
        display: inline-block;
        text-align: center;
        margin-right: 1rem;

        .helmet {
            width: 2.5em;
            height: 2.5em;

            span {
                @include wshopFont($dinFont, 16px, 0, $bold, $black);
                vertical-align: -1.5rem;
            }
        }
    }

    .passengerInfos {
        position: relative;
        margin: 2rem 0 0 0;
        padding: 2rem 0 0 0;
        border-top: 1px solid #f3f3f3;

        .passengerForm {
            position: relative;

            .primaryInfos {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

            label {
                position: absolute;
                margin: -1.2rem 0 0 0;
                @include wshopFont($dinFont, 14px, 0.1rem, $bold, $black);
                text-transform: uppercase;
            }

            .select {
                visibility: hidden;
                position: relative;

                p {
                    visibility: visible;
                    position: absolute;
                    top: calc(50% - 7px);
                }
            }

            .select,
            p {
                width: 145px;
                background-position: 94% center;
                @include wshopFont($dinFont,
                    14px,
                    $letterSpacing,
                    500,
                    #4d4d4d);
            }

            .input {
                width: 150px;
                height: 40px;
            }

            .email {
                width: 250px;
                height: 40px;
            }
        }

        .deletePassenger {
            @extend %close;
            position: absolute;
            top: 58%;
            right: -4%;
        }

        &:first-child {
            margin: 0;
            padding: 0;
            border-top: 0;
        }
    }

    .videoWrapper {

        [type='checkbox']:not(:checked) + label,
        [type='checkbox']:checked + label {
            padding-left: 40px;
        }

        label {
            @include wshopFont($dinFont, 13px, false, $regular, $black);
            text-transform: uppercase;

            .wrapperLabel {
                border: 1px solid #bababa;
                border-radius: 3px;
                padding: 1.5rem;
                height: 160px;
                display: flex;
                align-items: center;

                .camera {
                    position: relative;
                    margin: 0 0.5rem 0 0;
                    vertical-align: -1rem;
                }

                .labelTxt {
                    padding: 0 0 0 1rem;
                    position: relative;
                    display: inline-block;
                    text-align: left;
                    line-height: 14px;
                    vertical-align: -0.6rem;

                    span {
                        font-size: 18px;
                        display: block;
                    }

                    sup {
                        font-size: 7px;
                    }

                    &:before {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        border-right: 1px solid $black;
                    }
                }
            }
        }
    }

    .btnFormWrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 2.5rem 0 0 0;

        .formItem {
            width: 286px;
            margin-right: 2rem;

            button {
                height: 54px;
                line-height: 52px;
            }
        }
    }

    .error {
        border: 1px red solid !important;
    }
}

#reservationConfirm {
    position: fixed;

    h3 {
        text-align: center;
        padding: 1rem 0 1.5rem 0;

        span {
            display: inline-block;
            @include wshopFont($dinFont, 31px, false, $bold, $black);
            text-transform: uppercase;
            position: relative;
            line-height: 2;

            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background: $black;
            }

            &.subTitle {
                display: block;
                margin: 2rem 0 0 0;
                font-size: 13px;
                line-height: 1;

                &:after {
                    display: none;
                }
            }
        }
    }

    .wrapperInfos {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        height: 200px;

        .imgWrapper {
            width: 50%;
            position: relative;
            text-align: center;

            img {
                width: 100%;
                object-fit: cover;
                height: 200px;
                margin-top: 50%;
                transform: translateY(-80%);
            }
        }

        .reservationInfos {
            width: 50%;
            @include wshopFont($latoFont, 14px, false, $regular, $black);
            padding: 0 1.5rem;

            #config_title {
                @include wshopFont($dinFont, 26px, false, $bold, $black);
                text-transform: uppercase;
                display: inline-block;
                margin: 0 0 1rem 0;
                line-height: 1;

                &:after {
                    content: '';
                    display: block;
                    border-bottom: 1px solid $black;
                    margin: 0.5rem 0 0 0;
                }
            }

            #bloc_config_passenger,
            #configEmail,
            .configDate,
            .configCamera,
            .configDuree {
                line-height: 1.6;
                font-weight: bold;

                label {
                    font-weight: normal;
                    font-style: italic;
                }

                .passagerGondole {
                    text-transform: capitalize;
                }
            }

            .priceWrapper {
                position: absolute;
                bottom: 0;
                right: 8px;

                .nbPassengers {
                    margin: 0.5rem 0 0 0;
                }

                #config_price {
                    @include wshopFont($dinFont, 40px, false, $bold, $red1);
                    line-height: 1;

                    .devise,
                    .cents {
                        font-size: 0.4em;
                    }

                    .devise {
                        vertical-align: 1.1rem;
                    }

                    .cents {
                        margin: 0 0 0 -1rem;
                    }
                }
            }
        }
    }

    .btnFormWrapper {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 2rem 0 0 0;
        padding-bottom: 2rem;

        .formItem {
            width: 280px;

            .button.redB {
                width: 100%;
            }
        }
    }
}

/* Date picker */
.ui-datepicker {
    display: none;
    width: 570px !important;
    background: $white;
    border: 1px solid #000;
    @include wshopFont($dincondFont, 13px, 0, $regular, $black);

    .ui-datepicker-group {
        display: inline-block;
        margin: 1.5vw 0 1.5vw 3vw;

        .ui-datepicker-calendar {
            border-collapse: collapse;

            td {
                width: 1.5vw;
                height: 1.5vw;
                text-align: center;
                border: 1px solid #bababa;
                color: #4d4d4d;
                font-size: 20px;

                a {
                    display: block;
                    color: #4d4d4d;
                }

                &:hover,
                &:hover a {
                    background: $black;
                    color: $white;
                }

                &.ui-state-disabled {
                    border: none;
                    color: #bbbbbb;

                    &:hover {
                        background: $white;
                        color: #bbbbbb;
                    }
                }

                &.ui-datepicker-current-day {
                    background: $black;
                    color: $white;

                    a {
                        color: $white;
                    }
                }
            }
        }

        &.ui-datepicker-group-last {
            margin: 0 0 0 2.4vw;
        }
    }

    .ui_tpicker_unit_hide {
        display: none;
    }

    .ui-datepicker-buttonpane {
        text-align: center;

        .ui-datepicker-current {
            display: none;
        }

        .ui-datepicker-close {
            @extend .button;
            @extend .button.redB;
            width: 248px;
            margin: 0 0 2rem 0;
        }
    }

    .ui-datepicker-prev,
    .ui-datepicker-next {
        position: absolute;
        display: block;
        top: 30%;
        left: 0px;
        cursor: pointer;

        span {
            @extend .icon;
            display: block;
            width: 2.6vw;
            height: 2.6vw;
            @include bgIconCoord(2, $col, $row);
            transform: rotate(-90deg);
        }
    }

    .ui-datepicker-next {
        left: auto;
        right: 0px;

        span {
            transform: rotate(90deg);
        }
    }

    .ui-datepicker-title {
        font-size: 18px;
        text-transform: uppercase;
        text-align: center;
    }

    th {
        font-size: 15px;
        font-weight: $regular;
        text-transform: capitalize;
        padding: 0 0 0.5rem 0;
    }

    .ui-timepicker-div {
        margin: 2rem 0;

        dl {
            display: flex;
            justify-content: center;
        }

        .ui_tpicker_hour_label,
        .ui_tpicker_minute_label {
            font-size: 18px;
            line-height: 40px;
        }

        .ui_tpicker_hour_label {
            text-transform: uppercase;
        }

        .ui_tpicker_minute_label {
            margin: 0 0 0 0.5rem;
        }

        &:after {
            content: '';
            display: block;
            width: 420px;
            height: 1px;
            background: #dbdbdb;
            margin: 2rem auto;
        }
    }

    select {
        width: 80px;
        height: 40px;
        @include wshopFont($dinFont, 18px, 0, $regular, $black);
        margin: 0 0 0 0.5rem;
    }
}

body.homepage {
    position: relative;

    #shad_adwords.active {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 188vh;
        background: rgba(0, 0, 0, 0.5);
        z-index: 15;
    }
}