.breadcrumbs {
    display: flex;
    justify-content: flex-start;
    width: 92%;
    margin: 40px auto 0 auto;
    font-family: arial, sans-serif;
    font-size: 11px;
    line-height: 2;
    color: $white;
    .breadcrumb {
        margin: 0 0 0 .2rem;
        &:after {
            content: '✈';
            display: inline-block;
            color: $red1
        }
        &.current:after {
            display: none;
        }
    }
    a {
        font-family: arial, sans-serif;
        color: $white;
    }
}