// Override from app
.w-checkbox-input,
.w-radio-input {
    .w-input-element {
        &:checked + .w-input-label::before {
            background-color: #000;
        }
    }
}

button,
input,
textarea,
select,
option {
    outline: none;

    &:focus {
        outline: none;
    }
}

textarea {
    -ms-overflow-style: none;
}

.w-visibility-toggle {
    outline: none;
    border: none;

    &:focus,
    &:active {
        outline: none;
        border: none;
    }
}

.w-form-line.w-submit {
    .w-submit-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 38px;
        padding: 0 25px;
        font-family: AvantGarde, sans-serif;
        font-size: 13px;
        letter-spacing: 0;
        font-weight: 400;
        color: #000;
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;
        border: 1px solid black;
        background-color: #fff;
    }
}

.w-submit-button,
button.w-submit-button {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
    font-size: 21 / 16 * 1rem;
    font-family: $vidalokaFont;
    letter-spacing: 0;
    text-transform: inherit;

    &:not(.w-loader):hover,
    &:not(.w-loader):focus {
        background-color: transparent;
        color: #000;
    }

    &.w-loader {
        background: #fff url(../img/loader.gif) center no-repeat;
        background-size: auto 15%;
    }
}

//
//  Formulaire d'inscription à la newsletter
// *********************************************************
.w-newsletter-form {

    .w-input-element:not([type='radio']):not([type='checkbox']),
    input[type='email'].w-input-element {
        padding-right: 60 / 16 * 1rem;
        border-radius: 0;
    }

    .w-form-line.w-submit {
        position: absolute;
        top: 0;
        right: 0;

        .w-submit-button {
            font-size: 12 / 16 * 1rem;
            border: 1px solid #c5c5c5;
        }
    }
}

.w-checkbox-input,
.w-radio-input {
    height: 2.5em;

    .w-input-element {
        + .w-input-label {
            font-family: $vidalokaFont;
            font-size: 16 / 16 * 1rem;
            color: #000;
            letter-spacing: 1px;
        }
    }
}

//
//  Formulaire d'identification (connexion, inscription...)
// *********************************************************
.login_bloc {
    padding: 20% 25%;

    .w-form-line.w-submit {
        margin-top: 0;
    }

    .form-msg {
        height: 0;
    }

    .w-lines-group .w-fieldset-label,
    .w-pwd-reset {
        font-family: $vidalokaFont;
        font-size: 16 / 16 * 1rem;
        color: #000;
        letter-spacing: 1px;
    }

    .w-pwd-reset {
        text-decoration: underline;
    }

    #lostpwdSuccesTitle {
        margin: 15px 0;
    }

    .form-msg.active {
        p {
            margin-bottom: 1rem;
        }
    }
}

//
//  Formulaire d'alerte réassort
// *********************************************************
.w-alertestock-form {
    //margin-top: 8px;
    margin-top: 15 / 16 * 1rem;
    width: 100%;
}

//
//  Formulaire de code promo
// *********************************************************
.w-coupon-form {
    .w-form-line {

        .w-input-element:not([type='radio']):not([type='checkbox']),
        input[type='text'].w-input-element {
            border-radius: 0;
        }

        &.w-submit {
            .w-submit-button {
                font-size: 12 / 16 * 1rem;
                border: 1px solid #c5c5c5;
            }
        }
    }
}

#iconValidetage,
#iconValiddigicode,
#iconValidascenseur,
#iconValidsociete {
    display: none;
}

// Correction Login
.w-input-element {
    background: #fff !important;
}

.w-checkbox-input .w-input-element + .w-input-label,
.w-radio-input .w-input-element + .w-input-label {
    font-size: 0.8rem;
}

.w-checkbox-input .w-input-element + .w-input-label::before,
.w-radio-input .w-input-element + .w-input-label::before {
    border-color: $red1;
}

.login_bloc .w-lines-group .w-fieldset-label,
.login_bloc .w-pwd-reset {
    font-size: 0.8rem;
}

.login_page {
    align-items: stretch !important;
}

.login_bloc {
    padding: 0;

    .w-client-form {
        width: 100%;
    }
}

#resetPass {
    .login_form_wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;

        .w-lostpass-form {
            margin: 0;

            .w-form-line {
                .w-input-error {
                    top: 90%;
                }
            }
        }
    }
}

.mdpSentEmail {
    margin-bottom: 2rem;
}

.wrapper_moncompte_main {
    .w-form-line.w-submit {
        width: 220px;

        .w-submit-button {
            background-color: $red1;
            color: white;
            border-color: $red1;
        }
    }
}

.w-newspreferences-form {
    .multiline {
        margin-left: 1.5em !important;

        strong {
            font-size: 14px;
            font-weight: 900;
        }

        span {
            display: block;
            font-size: 12px;
            font-weight: lighter;
        }
    }
}

#sendfriend_prodinfos {
    display: none;
}

.in_tunnel {
    .intro {
        margin-bottom: 1rem;
    }
}

.w-dropdown::after {
    display: none;
}

.w-submit-button:not(.w-loader):focus,
.w-submit-button:not(.w-loader):hover,
button.w-submit-button:not(.w-loader):focus,
button.w-submit-button:not(.w-loader):hover {
    background-color: #ba2432;
    color: white;
    font-family: dincond, sans-serif;
    font-size: 15px;
    letter-spacing: 0.1rem;
}

.w-submit-button:not(.w-loader),
.w-submit-button:not(.w-loader),
button.w-submit-button:not(.w-loader),
button.w-submit-button:not(.w-loader) {
    background-color: white;
    font-size: 0.875rem;
}

// Formulaire
form.w-form.w-info-form.w-login-form input,
.w-address-form input,
#subscriptionForm input,
.w-form input {
    box-sizing: border-box;
}

.w-input.w-tel-input .selected-flag {
    height: 96%;
    border: none;
    border-right: 1px solid rgba($color: #000000, $alpha: 0.1);
    margin-left: 0.1rem;
    background-color: $white;
}

.w-input.w-tel-input .w-input-label {
    span {
        padding-left: 0.15rem;
    }
}

.w-visibility-toggle {
    border: none;
}

.w-form-line.w-submit .w-submit-button {
    background: #ba2432;
    color: #fff;
    height: 54px;
    border: none;
    font-family: dincond, sans-serif;
    font-size: 15px;
    letter-spacing: 0.1rem;
}

.w-submit-button.w-loader.loading span,
button.w-submit-button.w-loader.loading span {
    display: block;

    &:after {
        content: '';
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background: transparent url(../img/loader.gif) no-repeat 0 0;
        background-size: 100% auto;
        vertical-align: middle;
        margin: 0 0 0 0.5rem;
    }
}

.w-checkbox-input .w-input-element + .w-input-label,
.w-radio-input .w-input-element + .w-input-label {
    letter-spacing: 0;
}

.w-social-connect .w-social-btn.aft:after,
.w-social-connect .w-social-btn.bfr:before,
.w-social-connect .w-social-btn:not(.bfr):not(.aft) {
    background-image: url(../svg/social_fb.svg);
    background-repeat: no-repeat;
    background-size: 400% auto;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    box-sizing: border-box;
}

#formPromo {
    .w-form-line.w-submit .w-submit-button {
        color: #000;
        background-color: transparent;
        border: none;
    }

    .w-input-note {
        top: -24px;
    }
}

.wrapper_panier .left_side .wrapper_form_adresse .formItem.select,
.wrapper_panier .left_side .wrapper_form_adresse input[type='password'],
.wrapper_panier .left_side .wrapper_form_adresse input[type='text'] {
    text-indent: 0 !important;
}

.w-textarea .w-input-element:not([type='radio']):not([type='checkbox']),
.w-textarea input[type='email'].w-input-element,
.w-textarea input[type='password'].w-input-element,
.w-textarea input[type='text'].w-input-element,
.w-textarea textarea.w-input-element {
    box-sizing: border-box;
}

.w-share-form#sendfriendform {
    .formContent {
        width: 90%;
        margin: 0 auto;
    }
}

#sendfriend_form_to {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.w-checkbox-input .w-input-element + .w-input-label,
.w-radio-input .w-input-element + .w-input-label,
.login_bloc .w-lines-group .w-fieldset-label,
.login_bloc .w-pwd-reset,
.form_title,
.intro,
.w-coupon-form .w-form-line.w-submit .w-submit-button {
    font-family: $dinFont;
}

.w-coupon-form .w-form-line.w-submit .w-submit-button {
    border-left: 0;
    font-weight: bold;

    &.w-loader {
        span {
            font-size: 0;
        }
    }
}

.login_bloc .w-pwd-reset {
    color: #ba2432;
}

.w-checkbox-input .w-input-element + .w-input-label,
.w-radio-input .w-input-element + .w-input-label {
    padding-left: 1em;
    padding-top: 0.15em;

    &:before {
        border-color: black;
        min-width: 1em;
        position: absolute;
        top: 3px;
        left: 0;
    }
}

.sameBillAddress,
.accountCreate {
    .w-checkbox-input .w-input-element + .w-input-label {
        padding-left: 0;
    }

    .w-checkbox-input .w-input-element + .w-input-label::before {
        top: 0;
    }
}

.w-checkbox-input .w-input-element:checked + .w-input-label,
.w-radio-input .w-input-element:checked + .w-input-label {
    &:before {
        box-shadow: inset 0 0 0 0.1em #fff;
    }
}

input[type='password']::-webkit-contacts-auto-fill-button,
input[type='password']::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    height: 0;
    width: 0;
    margin: 0;
}

#adresseForm,
.w-login-form {
    .w-form-line {
        .w-radio-group {
            .w-radio-input {
                .w-input-label {
                    &:before {
                        height: 8px;
                        width: 8px;
                        min-width: inherit;
                        border-color: transparent;
                        box-shadow: inherit;
                        border-radius: 100%;
                        top: 2px;
                        left: 3px;
                    }

                    &:after {
                        content: '';
                        height: 12px;
                        width: 12px;
                        border: 1px solid black;
                        left: 1px;
                        top: 0;
                        position: absolute;
                        border-radius: 100%;
                    }
                }
            }
        }
    }
}

.w-pwd-reset {
    justify-content: center;
    display: flex;
}

.w-checkbox-input .w-input-element:checked + .w-input-label::before,
.w-radio-input .w-input-element:checked + .w-input-label::before {
    background-color: #ba2432;
}

#sendfriendbox #sendfriend_form_froms,
#sendfriendbox #sendfriend_form_to {
    justify-content: flex-start;
}

.wrapper_login_left,
.wrapper_login_right {
    .w-form {
        opacity: 0;

        .w-form-line {
            .w-input-container {
                .w-visibility-toggle {
                    border: none;

                    &:focus,
                    &:active {
                        outline: none;
                        border: none;
                    }
                }
            }
            
            .w-input-error {
                top: 90%;
            }
        }
    }

    &.actif {
        .w-form {
            opacity: 1;
        }
    }
}

.w-signup-form {
    fieldset.w-lines-group small.w-input-note.w-input-error {
        top: 62%;
    }
}

.w-social-connect .w-social-btn.aft:after,
.w-social-connect .w-social-btn.bfr:before,
.w-social-connect .w-social-btn:not(.bfr):not(.aft) {
    background-size: 400% auto;
}

.elem_global {
    @media screen and (max-width: 1148px) and (min-width: 800px) {
        left: -0.5rem;
        position: relative;
    }

    .w-form-line {
        @media screen and (max-width: 1148px) and (min-width: 800px) {
            width: 93%;
        }
    }
}