/********************/


/*     QUERIES      */


/********************/

$largeDesk: 1680px; //large Desktop
$mediumDesk: 1440px; //medium Desktop
$smallDesk: 1200px; //small Desktop
$tablet: 1024px; //tablet
$tabletP: 800px; //tablet
$phone: 599px; //phone
@mixin queries($media) {
    @if $media==largeDesk {
        @media only screen and (max-width: $largeDesk) {
            @content;
        }
    }
    @if $media==mediumDesk {
        @media only screen and (max-width: $mediumDesk) {
            @content;
        }
    }
    @if $media==smallDesk {
        @media only screen and (max-width: $smallDesk) {
            @content;
        }
    }
    @else if $media==tablet {
        @media only screen and (max-width: $tablet) {
            @content;
        }
    }
    @else if $media==tabletP {
        @media only screen and (max-width: $tabletP) {
            @content;
        }
    }
    @else if $media==phone {
        @media only screen and (max-width: $phone) {
            @content;
        }
    }
}

@mixin queriesWshop($varMedia, $width) {
    @if ($width==max) {
        @media only screen and (max-width: $varMedia) {
            @content;
        }
    }
    @if ($width==min) {
        @media only screen and (min-width: $varMedia) {
            @content;
        }
    }
}

@mixin orientation($orientation) {
    @if $orientation==portrait {
        @media all and (orientation: portrait) {
            @content;
        }
    }
}

//exemple

/*
.test {
	width: 250px;
	@include queries(smallDesk) {
		width: 100% ;
	}
	@include queries(tablet) {
		width: 125px;
	}
	@include queries(phone) {
		width: 100% ;
	}
}
*/