/*************************/


/*     WSHOP FRONT      */


/*************************/


/* EXEMPLE
@include wshopFont($vidalokaFont, 16px, 24px, $letterSpacing, $bold, $black) */

@mixin wshopFont($family, $size, $letterSpacing, $weight, $color) {
    font-family: $family;
    font-size: $size;
    @if $letterSpacing==false {
        $letterSpacing: initial;
    }
    letter-spacing: $letterSpacing;
    font-weight: $weight;
    color: $color;
}