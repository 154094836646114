/************************/
/*     BANK THANKS      */
/************************/

.thanksWrapper {
    .bankThanksTop {
        width: 100%;
        margin: 0 auto;

        h1 {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 30px;
            font-weight: 400;
            margin-bottom: 1rem;
            color: black;
            letter-spacing: .1rem;

            &:before {
                content: '';
                display: block;
                width: 1.5rem;
                height: 1.5rem;
                background: transparent url(../img/bank_thanks.png) no-repeat 0 0;
                background-size: contain;
                margin-right: 1rem;
            }
        }

        #around_me_map {
            width: 100%;
            height: 400px;
            margin: 0 auto 2rem;
        }
    }

    .thanksRecap {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .img_error,
        .valid_form {
            right: -30px;
        }

        .thanksLeft,
        .thanksRight {
            box-sizing: border-box;
        }

        .thanksLeft {
            width: 65%;

            .seeOrderEvo {
                margin-bottom: 1.5rem;
            }

            .follow_order_messenger {
                label {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                }

                img {
                    vertical-align: middle;
                }
            }

            .createPwdTxt {
                margin-bottom: 1.5rem;
            }

            .input_container {
                position: relative;
                
                &:not(.w-form-line) {
                    height: 35px;
                    margin-bottom: .5rem;

                    input {
                        width: 100%;
                        height: 100%;
                    }
                }

                .img_error,
                .valid_form {
                    position: absolute;
                    top: 50%;
                    right: -30px;
                    transform: translateY(-50%);
                    margin: 0;
                    padding: 0;
                }
            }

            .thanksTrust {
                margin: 1.5rem 0;
            }
        }

        .thanksRight {
            width: 30%;
            text-align: center;

            p {
                margin-bottom: .8rem;

                &.txt_thanks_transaction {
                    text-transform: uppercase;
                }
            }
        }
    }
}