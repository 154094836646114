/**
 * HEADER
 */
.headerWrapper {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    transition: all 0.3s ease;
    z-index: 15;

    &.withActivShade {
        z-index: 25;
    }

    &.sticky {
        z-index: 30;
    }

    .topHead {
        width: 100%;
        height: 34px;
        background: #202020;

        .topHeadWrapper {
            position: relative;
            width: 97%;
            min-width: 990px;
            max-width: 1980px;
            margin: 0 auto;
            color: $white;
            line-height: 34px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            @include wshopFont($dincondFont,
                16px,
                $letterSpacing,
                $regular,
                $white );
            line-height: 34px;
            text-transform: uppercase;

            @media screen and (max-width: 1024px) and (min-width: 768px) {
                min-width: unset;
            }

            .telWrapper,
            .accountTopWrapper,
            .miniCart {
                margin: 0 20px 0 0;
                line-height: 34px;
            }

            .shadeTrigger {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                .cart_btm_btn {
                    button {
                        @include wshopFont($dincondFont,
                            15px,
                            0.1rem,
                            $regular,
                            $white );
                    }
                }
            }

            .miniCart {
                margin: 0;
                cursor: pointer;
                display: flex;
                align-items: center;

                .icon {
                    display: inline-block;
                    width: 1em;
                    height: 1em;
                    margin-right: 5px;
                    position: relative;
                    top: -1px;
                }

                .amounts {
                    margin-left: 5px;
                    position: relative;
                    top: -2px;
                }

                #cart_length {
                    display: none;
                }

                sup {
                    font-size: 8px;
                }
            }

            a {
                @include wshopFont($dincondFont,
                    16px,
                    $letterSpacing,
                    $regular,
                    $white );
                display: inline-block;
            }

            .telWrapper .icon,
            .accountTopWrapper .icon {
                display: inline-block;
                width: 1em;
                height: 1em;
                vertical-align: -0.15rem;
            }

            .telWrapper .icon {
                @include bgIconCoord(7, $col, $row);
            }

            .accountTopWrapper .icon {
                @include bgIconCoord(5, $col, $row);
            }

            .miniCart .icon {
                @include bgIconCoord(6, $col, $row);
            }

            #topMenuConnexionButtonInside {
                display: inline-block;
            }

            #topMenuConnexion {
                display: none;
                position: absolute;
                right: 0;
                width: 360px;
                z-index: 10;
                background: $white;
                padding: 2rem 4rem 3rem 4rem;
                color: $black;
                text-transform: initial;

                .wrapper_menu_top_connected {
                    text-align: center;

                    .titleWithSubs {
                        padding: 0;
                        line-height: 1;
                        margin: 0 0 2rem 0;
                    }

                    section {
                        text-align: left;
                        padding: 0 0 1rem 0;

                        h5 {
                            @include wshopFont($dinFont,
                                14px,
                                false,
                                $medium,
                                $black );
                            text-transform: uppercase;
                            line-height: 1.4;
                        }

                        a {
                            @include wshopFont($latoFont,
                                13px,
                                false,
                                $regular,
                                #8d8c8c);
                            text-decoration: none;
                            display: block;
                            line-height: 1.4;

                            &:hover {
                                text-decoration: underline;
                            }
                        }

                        &:last-of-type {
                            border-bottom: 1px solid rgba(0, 0, 0, 0.14);
                        }
                    }

                    .wrapper_disconnect {
                        margin: 1.2rem 0 0 0;
                        height: 54px;
                        position: relative;

                        .button,
                        .loader {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .bottomHead {
        width: 100%;
        height: 80px;
        background: $bgHeaderFooter;
        transition: all 0.3s ease;

        .bottomHeadWrapper {
            position: relative;
            width: 97%;
            min-width: 990px;
            max-width: 1980px;
            margin: 0 auto;

            @media screen and (max-width: 1024px) and (min-width: 768px) {
                min-width: unset;
                width: 100%;
            }

            .bg_logo {
                display: block;
                position: absolute;
                top: -20px;
                left: 20px;
                content: '';
                position: absolute;
                z-index: 2;
                width: 120px;
                height: 120px;
                border-radius: 50%;
                background: $bgHeaderFooter;
                transition: all 0.3s ease;
            }

            .logo {
                position: absolute;
                z-index: 3;
                top: -40px;
                left: 0;
                text-indent: -10000px;
                transition: all 0.3s ease;
            }

            .menuWrapper {
                color: #fff;

                ul.mainMenu,
                ul.secondaryMenu {
                    display: inline-block;
                    line-height: 80px;
                    transition: all 0.3s ease;

                    li.mainMenuList {
                        display: inline-block;

                        &:after {
                            content: '';
                            display: inline-block;
                            @include bgIconPath($pathIcon);
                            @include bgIconCoord(9, $col, $row);
                            width: 1em;
                            height: 1em;
                            margin: 0 -0.4em 0 0;
                        }

                        &:last-child:after {
                            display: none;
                        }

                        a.mainMenuLink {
                            text-decoration: none;
                            text-transform: uppercase;
                            display: inline-block;
                            transition: color 0.2s ease;
                            font-family: $dincondFont;

                            &:after {
                                content: '';
                                display: block;
                                margin: -25px 0 0 0;
                                border-bottom: 2px solid $white;
                                width: 0px;
                                transition: width 0.2s ease,
                                    border-color 0.2s ease;
                            }
                        }

                        &:hover,
                        &.actif {
                            a.mainMenuLink {
                                color: $red1;

                                &:after {
                                    width: 100%;
                                    border-color: $red1;
                                }
                            }
                        }

                        &.actif {
                            a.mainMenuLink {
                                color: $red1;

                                &:after {
                                    width: 100%;
                                    border-color: $red1;
                                }
                            }
                        }
                    }
                }

                ul.mainMenu {
                    margin: 0 0 0 160px;

                    @media screen and (min-width: 1024px) and (max-width: 1065px) {
                        margin: 0 0 0 155px;
                    }

                    li.mainMenuList {
                        &:after {
                            vertical-align: -0.08em;
                        }

                        a.mainMenuLink {
                            @include wshopFont($dincondFont,
                                19px,
                                $letterSpacing,
                                $regular,
                                $white );
                            padding: 0 26px;
                            transition: all 0.3s ease;

                            @include queries(smallDesk) {
                                padding: 0 12px;
                            }

                            @media screen and (max-width: 1460px) and (min-width: 1242px) {
                                padding: 0 8px;
                            }

                            @media screen and (max-width: 1241px) and (min-width: 991px) {
                                font-size: 14px;
                                padding: 0 9px;
                            }

                            @media screen and (max-width: 990px) and (min-width: 768px) {
                                font-size: 10px;
                                padding: 0 4px;
                            }
                        }
                    }
                }

                ul.secondaryMenu {
                    float: right;

                    @media screen and (max-width: 1024px) and (min-width: 768px) and (orientation: portrait) {
                        position: relative;
                        top: -3.5rem;
                    }

                    @media screen and (min-width: 1024px) and (max-width: 1065px) {
                        margin-right: -5px;
                    }

                    @media screen and (min-width: 1465px) and (max-width: 1477px) {
                        margin-right: -12px;
                    }

                    li {
                        &:after {
                            vertical-align: -0.2em;
                        }

                        a.mainMenuLink {
                            @include wshopFont(arial,
                                12px,
                                $letterSpacing,
                                $regular,
                                #acacac);
                            padding: 0 16px;

                            @include queries(smallDesk) {
                                padding: 0 8px;
                            }

                            @media screen and (max-width: 1365px) and (min-width: 991px) {
                                font-size: 10px;
                            }

                            @media screen and (max-width: 990px) and (min-width: 768px) {
                                font-size: 8px;
                                padding: 0 9px;
                            }
                        }

                        &:last-child a.mainMenuLink {
                            padding: 0 0 0 16px;

                            @include queries(smallDesk) {
                                padding: 0 0.5rem 0 0.5rem;
                            }
                        }
                    }
                }

                .ssMenu {
                    position: absolute;
                    display: none;
                    z-index: -1;
                    opacity: 0;
                    width: 100vw;
                    height: 40px;
                    left: -1.5%;
                    bottom: -39px;
                    background: #fff;
                    justify-content: center;
                    align-items: center;
                    transition: all 0.4s ease;

                    a {
                        display: block;
                        padding: 0 4rem;
                        line-height: 40px !important;
                        @include wshopFont($dinFont,
                            13px,
                            false,
                            $bold,
                            $black );
                        letter-spacing: 0.2rem;
                        text-transform: uppercase;
                        color: #000;
                        position: relative;

                        span {
                            position: relative;

                            &:after {
                                content: '';
                                display: block;
                                position: absolute;
                                top: 100%;
                                left: 0;
                                width: 0%;
                                height: 1px;
                                background-color: $black;
                                transition: all 0.2s ease;
                            }
                        }

                        &:after {
                            content: '';
                            display: inline-block;
                            @include bgIconPath($pathIcon);
                            @include bgIconCoord(9, $col, $row);
                            width: 1em;
                            height: 1em;
                            position: absolute;
                            top: 50%;
                            right: 0;
                            margin: 0 !important;
                            transform: translateY(-50%);
                            opacity: 0.2;
                        }

                        &:last-child:after {
                            display: none;
                        }

                        &:hover,
                        &.actif {
                            color: $red1;

                            span:after {
                                width: 100%;
                                background-color: $red1;
                            }
                        }
                    }
                }

                .mainMenuList:hover {
                    .ssMenu {
                        display: flex;
                        z-index: 1;
                        opacity: 1;
                        border-bottom: 1px solid $bgHeaderFooter;
                    }
                }

                #experiencesMenu,
                #formationsMenu,
                #planesMenu,
                #devenir_proprietaireMenu,
                #galleryMenu {
                    margin-left: 7px;

                    &:after {
                        margin-left: 3px;
                    }
                }
            }
        }
    }
}

.headerWrapper.sticky {
    .bottomHead {
        height: 60px;

        .bottomHeadWrapper {
            .bg_logo {
                top: -19px;
                left: 29px;
                width: 100px;
                height: 100px;
            }

            .logo {
                width: 130px;
                height: 130px;
                top: -34px;
                left: 14px;
            }

            .menuWrapper {

                ul.mainMenu,
                ul.secondaryMenu {
                    line-height: 60px;

                    li.mainMenuList a {
                        line-height: 60px;

                        &:after {
                            margin: -20px 0 0 0;
                        }
                    }
                }
            }
        }
    }
}

.cart_box {
    display: none;
    position: absolute;
    z-index: 12;
    top: 34px;
    right: 0;
    width: 578px;
    background: $white;
    color: $black;
    z-index: 5;

    .lb-v-scrollbar {
        right: 13px;
        background-color: rgba(0, 0, 0, 0.1);

        .lb-v-scrollbar-slider {
            background-color: #000;
        }
    }

    .titleWrapper {
        text-align: center;
        margin: 2rem 0;

        .titre_panier {
            text-align: center;
            @include wshopFont($dincondFont, 31px, 0.2rem, $bold, $black);
            display: inline-block;
            position: relative;

            .subtitle {
                display: block;
                position: relative;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background: $black;
                }

                span {
                    position: relative;
                    z-index: 2;
                    padding: 0 1rem;
                    background: $white;
                    @include wshopFont($vidalokaFont,
                        19px,
                        false,
                        $regular,
                        $black );
                    text-transform: initial;
                    font-style: italic;
                    text-transform: none;
                    letter-spacing: 0;
                }
            }
        }
    }

    .cart_product_listing {
        max-height: 50vh;
        overflow-y: auto;
        display: block;
    }

    .product-link {
        display: flex;
        position: relative;
        align-content: center;
        justify-content: space-between;
        align-items: stretch;
        width: 86%;
        height: 180px;
        margin: 0 auto 2rem auto;

        .cart_thumb_holder {
            width: 265px;
            text-align: center;
            overflow: hidden;

            img {
                width: 130px;
                height: auto;
            }
        }

        .cart_detail_box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            flex: 1;
            margin: 0;
            top: -0.325rem;

            .cart_product_name {
                @include wshopFont($dinFont,
                    16px,
                    false,
                    $regular,
                    $mediumGray );
                text-transform: capitalize;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                width: 100%;

                .config_data {
                    margin-top: 12px;
                    line-height: 20px;
                }
            }

            .remove_prod {
                @extend %close;
                position: absolute;
                top: 4px;
                right: 0;

                @media screen and (min-width: 0\0) {
                    top: 7px;
                }
            }

            .cart_product_category,
            .cart_product_prix {
                a {
                    color: $black;
                }
            }

            .cart_product_category {
                font-family: $dincondFont;
                font-weight: 400;
                font-size: 16px;
                letter-spacing: 0.02rem;

                a {
                    @include wshopFont($dinFont, 20px, false, $bold, $black);
                    text-transform: uppercase;
                    line-height: 1;
                    position: relative;

                    &:after {
                        content: '';
                        display: block;
                        width: 100%;
                        max-width: 200px;
                        height: 1px;
                        margin: 0.5rem 0 0 0;
                        background: $black;
                    }
                }
            }

            .cart_product_charact {
                @include wshopFont($latoFont, 13px, false, $regular, #8d8c8c);
                text-transform: initial;
                margin-top: 1rem;
                display: none;

                p {
                    line-height: 1.4;
                }
            }

            .cart_product_prix {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;

                a {
                    @include wshopFont($dinFont, 41px, false, 500, $red1);

                    sup {
                        font-size: 0.5em;
                        vertical-align: 1rem;
                    }
                }
            }
        }
    }

    .cart_btm_btn {
        width: 260px;
        text-align: center;
        margin: 2rem auto 3rem auto;

        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background: $lightGray;
            margin: 0 0 2rem 0;
        }

        button {
            width: 100%;

            span {
                @include wshopFont($dincondFont,
                    15px,
                    0.1rem,
                    $regular,
                    $white );
            }
        }
    }
}

/**
 * Calage chrome
 */
.chrome .headerWrapper .topHead .topHeadWrapper .miniCart {
    height: 34px;
    line-height: 34px;
}