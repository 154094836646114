/*     FOOTER      */

.cgv {
    .copyrightWrapper {
        position: relative;
        z-index: 2;
    }
}

.copyright {
    width: 100%;
    background: $bgHeaderFooter;
    @include wshopFont($latoFont, 11px, $letterSpacing, $regular, $footerTxt);
    .copyrightWrapper {
        width: 99%;
        min-width: 990px;
        max-width: 1180px;
        margin: 0 auto;

        @media screen and (max-width: 1024px) and (min-width: 768px) {
            padding: 0 1rem;
            min-width: unset;
        }

        .firstLine {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 50px 0 24px 0;
            border-bottom: 1px solid #424242;
            .blocFirstLine {
                width: 22%;
                @include queries(smallDesk) {
                    width: 24.5%;
                }
                &:last-child {
                    width: auto;
                }
                h3 {
                    @include wshopFont($dinFont, 14px, .05em, $bold, $red1);
                    text-transform: uppercase;
                    margin: 0 0 .9em 0;
                    &:after {
                        content: '';
                        display: block;
                        width: 94px;
                        height: 1px;
                        border-bottom: 1px solid $white;
                        margin: .6rem 0 0 0;
                    }
                }
                p {
                    margin: 0 0 1.8em 0;
                    span {
                        font-size: 14px;
                    }
                }
                a {
                    color: $red1;
                    text-decoration: underline;
                    font-style: italic;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
        .secondLine {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .firstCol {
                width: 160px;
                .logo {
                    display: block;
                }
            }
            .secondCol {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                width: calc(100% - 390px);
                padding: 50px 0 0 0;
                ul li {
                    display: block;
                    margin: 0 0 16px 0;
                }
                a {
                    @include wshopFont($dincondFont, 13px, $letterSpacing, $regular, $footerTxt);
                    text-transform: uppercase;
                    transition: color .2s ease;
                    &:hover {
                        color: $red1;
                    }
                }
            }
            .thirdCol {
                position: relative;
                width: 160px;
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 35%;
                    width: 1px;
                    height: 44px;
                    border-left: 1px solid #fff;
                }
                legend {
                    display: inline-block;
                    @include wshopFont($dincondFont, 13px, $letterSpacing, $regular, $footerTxt);
                    text-transform: uppercase;
                    padding: 52px 0 0 50px;
                }
                .socialBotWrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: left;
                    padding: 12px 0 0 44px;
                    .socialIcon {
                        width: 2.2em;
                        height: 2.2em;
                        &.twitter,
                        &.youtube {
                            margin: 0 0 0 3em;
                        }
                    }
                }
            }
        }
        .mentionsBotWrapper {
            text-align: center;
            @include wshopFont($latoFont, 11px, $letterSpacing, $regular, $white);
            line-height: 35px;
            a {
                color: $red1;
                text-decoration: underline;
                margin: 0 .5em;
                &:hover {
                    text-decoration: none;
                }
            }
            .wshop {
                width: 70px;
                vertical-align: -.4rem;
            }
        }
    }
}

#cookiesLightbox {
    font-family: $primaryFont;
    z-index: 10000;
    padding: 45px 0 17px;
    min-height: 230px;

    .cookiesLightbox_container {
        width: 96%;
        max-width: 1440px;
        margin: 0 auto;

        display: grid;
        grid-template-areas: 
        "content content content"
        "customBtn refuseBtn acceptBtn"
        "cookiePrefs cookiePrefs cookiePrefs ";
        column-gap: 2rem;
        row-gap: 24px;
        grid-template-columns: 1fr auto auto;
    }

    .cookiesLightbox_content {
        grid-area: content;
        margin: unset; 
        max-width: none;
    }

    .cookiesLightbox_preferences {
        grid-area: cookiePrefs;
        max-width: none;
        width: 100%;

        a {
            display: block;
            height: 16px;
        }
    }
    
    .cookiesLightbox_custom_btn {
        grid-area: customBtn;
        align-self: center;
        margin: 0;
        margin-right: auto;
        
        
        #jsCookieCustom {
            border: none;
            height: 40px;
            margin: 0;
            font-family: $primaryFont;
            font-size: 13px;
            width: auto;
            background-color: unset;
            color: unset;
            letter-spacing: unset;
            text-decoration: underline;

            &:hover {
                background-color: unset;
                color: unset;
                text-decoration: unset;
                letter-spacing: unset;
            }
        }

        &:last-child {
            button.w-submit-button.w-loader.loading {
                display: none;
            }
        }
    }
    
    .cookiesLightbox_refuse_btn {
        grid-area: refuseBtn;

        #js_cookie_refuse {
            background-color: $white;
            border: 2px solid $red1;
            color: $red1;
            font-family: dincond, sans-serif;
            font-size: 15px;
            letter-spacing: 0.1rem;
        }

        #js_cookie_refuse, #js_cookie_refuse+.w-loader {
            background-color: $white;
            color: $red1;  
            border: 2px solid $red1;
        }

        button.w-submit-button.w-loader.loading {
            font-family: dincond, sans-serif;
            font-size: 15px;
            letter-spacing: .1rem;
        }
    }
    
    .cookiesLightbox_accept_btn {
        grid-area: acceptBtn;

        #js_cookie_accept {
            background-color: #ba2432;
            border: 2px solid #ba2432;
            color: white;
            font-family: dincond, sans-serif;
            font-size: 15px;
            letter-spacing: 0.1rem;
        }

        button.w-submit-button.w-loader.loading {
            font-family: dincond, sans-serif;
            font-size: 15px;
            letter-spacing: .1rem;
            background-color: $red1;
            border: 2px solid $red1;
            color: $white;
        }
    }
    
    .cookiesLightbox_refuse_btn,
    .cookiesLightbox_accept_btn {
        text-transform: uppercase;
        width: 200px;
    }

    span {
        display: block;
    }

    a {
        color: $black;
    }

    .w-checkbox-input .w-input-element+.w-input-label {
        padding-left: unset;
        padding-top: unset;

        &::after {
            top: 4px;
        }
    }

    ul.cookiesInfos {
        #cookiePref02,
        #cookiePref03 {
            .icon {
                display: inline-block;
                height: 16px;
                mask-position: 66.66667% 97%;
            }

            .w-checkbox-input {
                .w-input-element+.w-input-label::after {
                    top: 4px;
                }
            }
        }
    }
}