//
// spriter: a SCSS sprite generator
//
// Generates the CSS rules needed to use a spritesheet with dynamic size
//
// @author Timothy Beard <tb@webpopulation.com>
// @since 2016/12/14
//
// @param   string    $path   Path to the spritesheet img file
// @param   integer   $col    Number of columns in the spritesheet grid
// @param   integer   $row    Number of rows in the spritesheet grid
// @param   string    $name   Base class name
// @param   map       $icons  A map of index: name for icons in the spritesheet
// =========================================================

// Helper function to compute relative coordinates on the given axis
@function computeCoord($l, $x) {

    $out: 0;

    @if $l > 1 {

        $out: ((1 / ($l - 1)) * $x) * 100%;
    }

    @return $out;
}

// Helper function to compute relative coordinates of given image within the grid
@function getImageCoords($i, $w, $h) {

    $len: ($w * $h);

    $x: ($i - 1) % $w;
    $y: floor(($i - 1) / $w);

    @return computeCoord($w, $x) computeCoord($h, $y);
}

@mixin spriter($path, $col, $row, $icons: null) {

    &:not(.bfr):not(.aft), &.bfr:before, &.aft:after {
        background-image: url($path);
        background-repeat: no-repeat;
        background-size: ($col * 100%) auto;
    }

    &.bfr:before,
    &.aft:after {
        background-position: inherit;
    }

    @if $icons != null {

        @each $key, $val in $icons {

            &.#{$val} {
                background-position: getImageCoords($key, $col, $row);
            }
        }
    } @else {

        @for $i from 1 through $len {

            &[data-icon="#{$i}"] {
                background-position: getImageCoords($i, $col, $row);
            }
        }
    }
}
