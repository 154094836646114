/*****************/


/* STYLED SELECT */


/*********************************************************/


/*
#reservationHomeForm .formItem.select {
    @include selectFormat($selectBackground:$black;);
}
/*********************************************************/

@mixin selectFormat( $selectWidth: null,
$selectHeight: null,
$selectBackground: null,
$selectBorder: null,
$selectBorderRadius: null) {
    @if($selectWidth) {
        width: $selectWidth;
    }
    @if($selectHeight) {
        height: $selectHeight;
    }
    @if($selectBackground) {
        background: $selectBackground;
    }
    @if($selectBorder) {
        border: $selectBorder;
    }
    @if($selectBorderRadius) {
        border-radius: $selectBorderRadius;
    }
}