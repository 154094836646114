#cookiesLightbox {
    display: none;
    position: fixed;
    z-index: 200;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 0;
    box-sizing: border-box;
    background-color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: black;

    section,
    .w-form-line.w-submit {
        max-width: 990px;
        margin: 0 auto;
    }

    &.notAccepted {
        display: block;
    }

    h3 {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    a {
        text-decoration: underline;
        text-underline-position: under;
    }

    .toggleCookiePrefs {
        display: flex;
        align-items: center;
        cursor: pointer;
        text-decoration: underline;
        text-underline-position: under;
        margin-top: .5rem;

        &:hover {
            text-decoration: none;
        }

        &:after {
            content: '';
            display: inline-block;
            border: solid black;
            border-width: 0 1px 1px 0;
            padding: 3px;
            transform: rotate(45deg);
            margin: 0 0 0 .5rem;
        }

        &.opened:after {
            transform: rotate(-135deg);
            margin: .5rem 0 0 .5rem;
        }
    }

    ul {
        &.cookiesInfos {
            display: none;
            margin: 1rem 0;

            #cookiePref02,
            #cookiePref03 {
                .icon {
                    display: none;
                    width: 21px;
                    height: 21px;
                    content: " ";
                    mask-image: url(../src/svg/spritesheet-conf.svg);
                    @include maskIconCoord(15, 4, 4);
                    background-color: #272525;
                    margin-left: 4px;

                    a {
                        display: block;
                        width: 21px;
                        height: 21px;
                    }
                }

                .w-checkbox-input .w-input-element + .w-input-label::after {
                    top: 3px;
                }
            }
        }

        li {
            position: relative;
            cursor: pointer;

            .toggleCookieBloc {
                content: '';
                position: absolute;
                top: 14px;
                right: 5px;
                border: solid black;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 4px;
                transform: rotate(45deg);
                transition: all .2s ease-in-out;
                cursor: pointer;

                &.actif {
                    transform: rotate(-135deg);
                }
            }

            .w-checkbox-input {
                height: 40px;
            }

            h4 {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-weight: bold;
                text-transform: uppercase;
                color: black;
            }

            .cookieBlocInfo {
                display: none;
                background-color: rgba(0, 0, 0, .05);
                padding: 1rem;
                box-sizing: border-box;

                p {
                    margin-bottom: .5rem;

                    i {
                        display: inline-block;
                        transform: rotate(45deg);
                        height: 12px;
                        width: 6px;
                        border-bottom: 3px solid #78b13f;
                        border-right: 3px solid #78b13f;
                        margin-right: .5rem;
                    }
                }
            }

            &:first-child {

                .w-input-label{
                    cursor: default ;

                    &::after{
                        background: darkgrey !important;
                    }
                }

                h4 {
                    color: darkgrey;
                }
            }
        }
    }

    .w-form-line.w-submit {
        justify-content: flex-end;
        margin-top: 1rem;

        .w-btn-wrapper {
            width: 200px;

            &+.w-btn-wrapper {
                margin-left: 2rem;
            }
        }

        button.w-loader {
            left: auto;
            right: 0;
        }
    }
}

span.cookieShowLightbox {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
}

#shadCookie {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.2);
    transition: all .3s ease-in-out;

    &.actif {
        z-index: 100;
        opacity: 1;
    }
}