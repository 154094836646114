/*********************/


/*      COLORS       */


/*********************/

$black:          #000;
$white:          #fff;
$mediumGray:     #919191;
$lightGray:      #dbdbdb;
$bgBody:         #1b2028;

/* BG HEADER + FOOTER */
$bgHeaderFooter: #191919;

/* FOOTER TXT */
$footerTxt:      #bebebe;

/* BG RED BUTTONS */
$red1:           #ba2432;


/* FORM ELEMENTS COLOR */
$bgInputs:       #4d4d4d;

/* FACEBOOK */
$bgFacebook:     #3c599b;

/* DISCOUNTED PRICE */
$discountPrice:  #9e9e9f;
$primaryColor:   #000;
$secondaryColor: $red1;

/* SUCCESS / VALID */
$success: #088d08;
$ashGrey: #bababa;