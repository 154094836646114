/******************************************************************************/
/* Lightbox selection offerte */
/******************************************************************************/

#selection-offer {
    width: 850px;
    height: 696px;

    .selection-offer-header {
        text-align: center;

        p {
            font-size: 15px;

            &.selection-offer-title {
                font-size: 25px;
                font-weight: 900;
                margin: 1rem auto 0;
            }

            span#nb_prod_selection_offerte {
                font-size: 18px;
                font-weight: 700;
            }
        }
    }

    .selection-offer-content {
        width: 600px;
        max-height: 496px;
        overflow: auto;
        padding: 1rem 0;
        box-sizing: border-box;
        margin: 1rem auto 0;

        .lb-v-scrollbar,
        .lb-v-scrollbar-slider {
            width: 6px !important;
        }
    }

    #wrap_lion {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 580px;


        .js-form-selection-offer {
            position: relative;
            width: 260px;
            min-height: 465px;
            padding: 1.5rem;
            box-sizing: border-box;
            border: 1px solid #ddd;
            margin: 0 0 2rem;
            opacity: .6;

            &:before {
                content: '';
                position: absolute;
                top: -10px;
                left: 50%;
                transform: translateX(-50%);
                width: 20px;
                height: 20px;
                box-sizing: border-box;
                border: 1px solid #ddd;
                background: black;
                border-radius: 50%;
                box-shadow: inset 0 0 0 14px white;
                transition: all .2s ease-in-out;
            }

            &:hover,
            &.actif {
                opacity: 1;
                border-color: black;
                cursor: pointer;

                &:before {
                    border: 1px solid black;
                    box-shadow: inset 0 0 0 2px white;
                }
            }
        }

        img.visuOfferedProd {
            width: 100%;
            height: auto;
            vertical-align: bottom;
        }

        p.titleOfferedProd {
            font-size: 14px;
            font-weight: 700;
            margin: .4rem 0 .2rem;

            span {
                display: block;
                font-size: .9em;
                font-weight: 400;
            }
        }

        .color_list,
        .size_list {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 30px;
            padding: 0 30px;

            .swiper-slide {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20px;
            }

            .prevColour,
            .nextColour,
            .prevSize,
            .nextSize{
                width: 30px;
                height: 30px;
                position: absolute;
                z-index: 1;
                top: 0;
                background-color: white;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    border: solid black;
                    border-width: 0 1px 1px 0;
                    padding: 4px;
                }

                &.swiper-button-disabled {
                    opacity: .2;
                }
            }

            .prevColour,
            .prevSize {
                left: 0;

                &:before {
                    transform: translateY(-50%) rotate(135deg);
                    left: 5px;
                }
            }

            .nextColour,
            .nextSize {
                right: 0;

                &:before {
                    transform: translateY(-50%) rotate(-45deg);
                    right: 5px;
                }
            }

            input[type="radio"] {
                display: none;

                &+label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                    margin: 0;
                }
            }
        }

        .color_list {
            input[type="radio"] {
                &+label {
                    border-radius: 50%;
                    border: 1px solid #ddd;
                    background-size: contain;
                }

                &:checked + label,
                &+label:hover {
                    border-color: black;
                    box-shadow: inset 0 0 0 2px white;
                }
            }
        }

        .size_list {
            .swiper-slide {
                width: auto;
                min-width: 20px;
            }

            input[type="radio"] {
                &+label {
                    width: auto;
                    min-width: 20px;
                    white-space: nowrap;
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 0%;
                        height: 1px;
                        background-color: black;
                        transition: width .2s ease-in-out;
                    }
                }

                &:checked + label,
                &+label:hover{
                    font-weight: 700;

                    &:after {
                        width: 100%;
                    }
                }
            }
        }
    }

    #errorLabel {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        font-family: sans-serif;
        font-size: 13px;
        text-align: center;
        color: red;
    }

    .selection-offer-submit {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100%;

        .button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 300px;
        }

        &.disabled {
            .button {
                opacity: .5;
                cursor: default;
            }
        }
    }
}