.lightbox {
    position: fixed;
    top: 15vh;
    right: 0;
    left: 0;
    -webkit-transform: translateZ(0) scale(1, 1);
    transform: translateZ(0);
    width: 738px;
    height: 505px;
    background-color: $white;
    padding: 45px;
    margin: 0 auto;
    z-index: 9999;
    -webkit-backface-visibility: hidden;

    @media screen and (max-width: 1280px) {
        top: 15vh;
        padding: 20px;
    }

    .popup_title {
        text-align: center;
        font-size: 1.85rem;
    }

    .close_pop {
        border: none;
        position: relative;
        z-index: 5;
        top: 0rem;
        right: 1rem;
        background-color: transparent;
        cursor: pointer;
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
        transform: translateX(37rem);

        span {
            font-weight: $semibold;
            font-size: 12px;
            color: #434343;
            width: 17px;
            height: 32px;
            display: block;
        }

        &:hover {
            opacity: 0.75;
        }
    }

    .wrapper_img {
        display: none;
    }
}

#exit_overlay_OVI {
    .w-input {
        width: 58%;
    }

    #formu_inscription_news {
        position: relative;
        text-align: center;

        .title_popup_inscription_home {
            font-family: $primaryFont;
            text-transform: uppercase;
            border-bottom: 1px solid black;
            padding-bottom: 1rem;
            margin: 0 auto 2.5rem;
            font-size: 30px;
            color: #272525;
            font-weight: 900;
            line-height: 2.2rem;
            text-align: center;
            width: 40%;

            small {
                font-size: 30px;
            }
        }

        .pop_txt_listing {
            position: absolute;
            color: #999;
            font-size: 0.9rem;
            width: 45%;
            margin: 0 auto 23px auto;
            text-align: left;
            font-family: $primaryFont;
            font-weight: 400;
            text-align: center;
            bottom: 7rem;
            left: 8.6rem;
            font-size: 12px;

            p {
                text-align: left;
            }

            .common_txt {
                position: absolute;
                text-transform: uppercase;
                top: -5.4rem;
                color: #272525;
                bottom: 6.7rem;
                font-weight: 900;
            }
        }

        .w-form {
            .pop_txt_listing {
                position: unset;
            }
        }

        .w-form-line-container {
            text-align: center;

            .w-email-input {
                text-align: center;
                margin: 0 auto;

                input {
                    border-radius: 3px;
                }
            }

            small.w-input-error {
                margin-top: 3.5rem;
            }

            p.w-input-error {
                margin-top: 2rem;
            }

            .w-submit {
                max-width: 230px;
            }

            .w-submit-button {
                background-color: #ba2432;
                border: 1px solid #ba2432;
                font-size: 15px;
                font-weight: 400;
                letter-spacing: 0.1rem;
                font-family: 'dincond', sans-serif;
                display: inline-block;
                height: 54px;
                line-height: 54px;
                width: 230px;
                text-transform: uppercase;
                margin: 2rem auto 25px auto;
                border-radius: 4px !important;

                span {
                    font-weight: 300;
                }
            }

            .w-input-container {
                margin: auto;
            }
        }

        .pop_cgv_listing {
            a {
                text-decoration: underline;
                color: #919191;
            }
        }
    }

    .common_txt {
        span {
            display: none;
        }
    }
}

//Formulaire validé
#conf_inscription_news {
    margin: 7rem auto;
    text-align: center;

    .title_popup_inscription_home {
        font-family: $secondaryFont;
        font-size: 30px;
        text-transform: uppercase;
        color: #272525 !important;
        font-weight: 900;
        border-bottom: 1px solid #272525;
        width: 40%;
        margin: auto;
        padding-bottom: 0.8rem;
    }

    .pop_txt_listing {
        .detail {
            font-family: $secondaryFont;
            color: #272525;
            font-weight: 600;
            font-size: 0.75rem;
            text-transform: uppercase;
            margin: 2rem auto;
        }
    }

    .form_submit {
        height: 44px;

        button {
            background-color: #ba2432;
            border: none;
            width: 8rem;
            height: 2.5rem;
            border-radius: 2px;

            &:hover {
                cursor: pointer;
            }

            span {
                background-color: #ba2432;
            }
        }
    }
}

#galleryLightboxWrapper {
    .swiper-button-next {
        background-image: url(../img/common.svg);
        background-size: 400% 400%;
        background-position: 66.66667% 0;
        width: 3.5em;
        height: 3.5em;
        right: 1vw !important;
        transform: rotate(90deg);
    }

    .swiper-button-prev {
        background-image: url(../img/common.svg);
        background-size: 400% 400%;
        background-position: 66.66667% 0;
        width: 3.5em;
        height: 3.5em;
        left: 1vw !important;
        transform: rotate(270deg) !important;
    }
}