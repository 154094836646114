/**
 * SLIDERS
 */
// Main slider home
.mainSliderHomeImg {
    width: 100%;
    height: calc(100vw * (750 / 1980));
    max-height: 750px;

    .swiper-slide img {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        height: auto;
    }

    .swiper-button-next,
    .swiper-button-prev {
        background-image: none;
        top: 44%;

        @include queries(tablet) {
            top: 38%;
        }
    }

    .swiper-button-prev {
        transform: rotate(0);
    }

    .swiper-button-next:after,
    .swiper-button-next:before,
    .swiper-button-prev:after,
    .swiper-button-prev:before {
        background-color: transparent;
    }

    .swiper-button-next:before {
        right: 1vw;
        left: auto;
        transform: rotate(90deg);
    }

    .swiper-button-prev:before {
        left: 1vw;
        right: auto;
        transform: rotate(-90deg);
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
        background-image: none;
        content: attr(data-text);
        @include wshopFont($dinFont, 8px, 0.2vw, $bold, $black);
        text-transform: uppercase;
        opacity: 0.4;
        transform: rotate(90deg);
        line-height: 3vw;
        margin: 1.5vw 0 0 0;
        transition: all 0.3s ease;
    }

    .swiper-button-next:after {
        right: 1vw;
        left: auto;

        @include queries(tablet) {
            right: 2vw;
            margin: 2.5vw 0 0 0;
        }
    }

    .swiper-button-prev:after {
        left: 1vw;
        right: auto;
        margin: 1.5vw 0 0 1.5vw;

        @include queries(tablet) {
            margin: 2.5vw 0 0 1.5vw;
        }
    }

    .swiper-button-next:hover:after,
    .swiper-button-prev:hover:after {
        letter-spacing: 0.3vw;
    }
}

#reservationHomeForm {
    > div.formItem.date {
        color: #dbdbdb;
    }
}

#homeReservationDate {
    color: #737272;
}

.mainSliderHomeText.swiper-container {
    position: absolute;
    top: -229px;
    right: 13%;
    z-index: 3;
    width: 40%;
    max-width: 548px;
    height: auto;
    background: $white;
    color: $black;

    @include queries(mediumDesk) {
        right: 8%;
    }

    &.swiper-container {
        overflow: visible;
    }

    .arrowMainSlider {
        content: '';
        position: absolute;
        z-index: 10;
        top: -3.3rem;
        left: 50%;
        transform: translate(-50%, 0);
        display: block;
        width: 6.5rem;
        height: 6.5rem;
        cursor: pointer;
        @include bgIconPath($pathIcon);
        @include bgIconCoord(3, $col, $row);
    }

    .arrowMainSlider-prev {
        display: none;
    }

    h3 {
        @include wshopFont($dinFont, 51px, $letterSpacing, $bold, $black);
        margin: 80px auto 0 auto;
        text-align: center;

        @include queries(tablet) {
            font-size: 40px;
            margin: 40px auto 0 auto;
        }
    }

    h4 {
        width: 70%;
        margin: 0 auto 2rem auto;
        text-align: center;

        @include queries(mediumDesk) {
            width: 85%;
        }

        span.subtitleLine {
            display: block;
            position: relative;
            text-align: center;

            &:after {
                content: '';
                display: inline-block;
                border-bottom: 1px solid #2a3235;
                width: 100%;
                position: absolute;
                left: 0;
                top: 50%;
                z-index: 1;
            }

            span {
                position: relative;
                z-index: 2;
                display: inline-block;
                background: $white;
                @include wshopFont($vidalokaFont,
                    19px,
                    $letterSpacing,
                    $regular,
                    #2a3235);
                font-style: italic;
                padding: 0 0.6rem;

                @include queries(tablet) {
                    font-size: 15px;
                }
            }
        }
    }

    p {
        @include wshopFont($latoFont, 13px, $letterSpacing, $regular, #737272);
        line-height: 20px;
        width: 70%;
        margin: 0 auto;
        text-align: justify;
        margin-bottom: 1rem;

        @include queries(mediumDesk) {
            width: 85%;
        }
    }

    .infosSlideWrapper {
        background: $white;
        color: $black;
        height: 100%;
        padding: 2.4rem 0 1.4rem;

        ul {
            width: 70%;
            margin: 1rem auto;
            font-size: 13px;
            font-family: $latoFont;
            letter-spacing: .02rem;
            font-weight: 400;
            color: #737272;
            line-height: 20px;
            padding: 0 40px;

            @include queries(mediumDesk) {
                width: 85%;
            }

            li {
                list-style: unset;

                &::marker {
                    font-size: 16px;
                }
            }
        }
    }
}

.mainSliderHomeTextBis.swiper-container {
    position: absolute;
    top: -30px;
    right: 7.5vw;
    z-index: 6;
    width: 120px;
    height: 200px;
    color: #fcfbf1;

    @include queries(mediumDesk) {
        display: none;
    }

    @media screen and (min-width: 0\0) {
        display: none;
    }

    .firstLetter {
        cursor: pointer;
        @include wshopFont($dinFont, 198px, false, $medium, #fcfbf1);
        line-height: 150px;
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            right: 15px;
            bottom: 26px;
            width: 100%;
            border-bottom: 2px solid #fcfbf1;
            margin: 0 auto;
        }
    }

    .titleCurrentSlide {
        display: block;
        @include wshopFont($dinFont, 13px, false, $regular, #fcfbf1);
        text-transform: uppercase;
        transform: rotate(-90deg);
        text-align: right;
        margin: 5rem 0 0 0;
        position: relative;
    }
}

// Lightbox Gallery
#galleyLightbox {
    width: 1024px;
    height: 99vh;
    left: 50%;
    text-align: center;

    .titleWithSubs {
        padding: 2rem 0;
    }

    @include queries(smallDesk) {
        width: 894px;
    }

    .sliderLightbox {
        width: 85%;
        margin: 0 auto 2rem auto;

        img {
            width: 99.9%;
            height: auto;
            vertical-align: top;
        }

        &:after {
            content: '';
            display: block;
            height: 2rem;
            width: 100%;
            border-bottom: 1px solid #dbdbdb;
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        top: 40%;
    }

    .swiper-button-next {
        right: -0.8rem;
    }

    .swiper-button-prev {
        transform: rotate(0);
        left: -0.8rem;
    }

    .swiper-button-next:after,
    .swiper-button-next:before,
    .swiper-button-prev:after,
    .swiper-button-prev:before {
        background-color: transparent;
    }

    .swiper-button-next:before {
        right: 1vw;
        left: auto;
        transform: rotate(90deg);
    }

    .swiper-button-prev:before {
        left: 1vw;
        right: auto;
        transform: rotate(-90deg);
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
        background-image: none;
        content: attr(data-text);
        @include wshopFont($dinFont, 8px, 0.2vw, $bold, $black);
        text-transform: uppercase;
        transform: rotate(90deg);
        line-height: 3vw;
        margin: 1.5vw 0 0 0;
        transition: all 0.3s ease;
    }

    .swiper-button-next:after {
        right: 1vw;
        left: auto;
    }

    .swiper-button-prev:after {
        left: 1vw;
        right: auto;
        margin: 1.5vw 0 0 1.5vw;
    }

    .swiper-button-next:hover:after,
    .swiper-button-prev:hover:after {
        letter-spacing: 0.3vw;
    }

    .sliderThumbs {
        margin: 0 auto 2rem;
        width: 85%;

        .swiper-wrapper {
            .swiper-slide {
                width: 149px;
                opacity: 0.4;

                img {
                    width: 100%;
                    height: auto;
                    vertical-align: top;
                }
            }

            .swiper-slide-thumb-active {
                opacity: 1;
            }
        }
    }
}