
.homeWrapper {
    position: relative;
    width: 100%;
    min-width: 990px;
    max-width: 1980px;
    margin: 0 auto;
    overflow-x: hidden;

    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    &:-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
    }

    .promo_code_exp40 {
        margin-bottom: 1rem;
    }

    .homeReservationWrapper {
        width: 100%;
        height: 76px;
        background: rgba(255, 255, 255, .8);
        text-transform: uppercase;
        margin: -76px 0 0 0;
        padding: 0 30px 0 0;
        position: relative;
        z-index: 3;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @include queries(mediumDesk) {
            .socialHomeWrapper {
                display: none;
            }
        }
        .reservationHomeFormWrapper {
            margin-left: 50%;
            transform: translateX(-100%);
            transition: opacity .3s ease;
            @media screen and (max-width: 1030px) and (min-width: 1020px){
                margin-left: 55.5%;
                transform: translateX(-100%) scale(0.9);
            }
            @media screen and (min-width:768px) and (max-width:1024px) and (orientation:portrait){
                margin-left: 55.75%;
                transform: translateX(-100%) scale(0.845);
            }
            form {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .reservation_hours_first_select option[disabled],
                .reservation_hours_select option[disabled] {
                    display: none;
                }

                label {
                    width: 64px;
                    @include wshopFont($dinFont, 10px, false, $bold, $black);
                    vertical-align: -1.2em;
                    text-align: right;
                }

                select,
                input,
                button {
                    text-transform: uppercase;
                }

                input {
                    text-indent: .5em;
                }


                select {
                    &::-ms-expand {
                        opacity: 0;
                        z-index: 5;
                    }
                }
                .formItem.select {
                    margin: 0 0 0 .5rem;
                    @include selectFormat($selectWidth: 338px);
                    @include queries(mediumDesk) {
                        @include selectFormat($selectWidth: 254px);
                    }
                    @include queries(smallDesk) {
                        @include selectFormat($selectWidth: 180px);
                    }
                }
                .formItem.select_hours {
                    margin: 0 0 0 .5rem;
                    @include selectFormat($selectWidth: 80px);
                    @include queries(mediumDesk) {
                        @include selectFormat($selectWidth: 80px);
                    }
                    @include queries(smallDesk) {
                        @include selectFormat($selectWidth: 80px);
                    }
                }
                .formItem.date {
                    width: 170px;
                    height: 40px;
                    border-radius: 3px;
                    border: 1px solid $black;
                    margin: 0 0 0 .5rem;
                    background: $white url("../img/calendar.svg") no-repeat 96% center;
                    input {
                        vertical-align: -.1rem;
                        border: none;
                        background: none;
                        cursor: pointer;
                    }
                    img {
                        position: absolute;
                        right: .5vw;
                        top: .4vw;
                    }
                }
                .formItem.submit {
                    margin: 0 0 0 .5rem;
                    width: 52px;
                    height: 40px;
                    button {
                        line-height: 40px;
                    }
                }
            }
            &.disabled {
                opacity: .3;
            }
        }
        .socialIcon {
            width: 1.6em;
            height: 1.6em;
            &.twitter,
            &.youtube {
                margin: 0 0 0 1em;
                @include queries(tablet) {
                    margin: 0 0 0 .4em;
                }
            }
            &.facebook {
                background-position: 0 67%;
            }
        }

        .homeReservationWrapper #reservation_hours option[disabled] {
            display: none;
        }
    }
    .middleHomeWrapper {
        position: relative;
        max-width: 1680px;
        margin: 0 auto;
        width: 100%;
    }
    .cardHome {
        .cardHomeWrapper {
            position: relative;
            margin: 87px 0 87px 50%;
            transform: translateX(-102%);
            max-width: 555px;
            .cardHomeTxt {
                position: absolute;
                z-index: 1;
                right: 95%;
                top: -8rem;
                @include queries(mediumDesk) {
                    display: none;
                }
                span {
                    @include wshopFont($dinFont,
                    260px,
                    $letterSpacing,
                    $medium,
                    $white);
                    line-height: .9;
                    display: block;
                    position: relative;
                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        right: 33px;
                        width: 68px;
                        border-bottom: 2px solid $white;
                        margin: 0 auto;
                    }
                }
                span+span {
                    @include wshopFont($dinFont,
                    13px,
                    $letterSpacing,
                    $bold,
                    $white);
                    text-transform: uppercase;
                    transform: rotate(-90deg);
                    height: 56px;
                    width: 80px;
                    position: absolute;
                    line-height: 1.2;
                    left: 2rem;
                    margin: .5rem 0 0 0;
                    &:after {
                        display: none;
                    }
                }
            }
            .cardShadeWrapper {
                position: relative;
                display: inline-block;
                width: 100%;
                img {
                    position: relative;
                    width: 100%;
                    height: auto;
                    max-width: 555px;
                }
                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(30, 30, 30, .5);
                    @include wshopFont($dinFont,
                    42px,
                    .3rem,
                    $bold,
                    $white);
                    text-transform: uppercase;
                    @include queriesWshop($milleDeuxQuatreVingt, max) {
                        font-size: 32px;
                    }
                    display: none;
                }
            }
        }
    }
    .gondoleHome {
        max-width: 1680px;
        margin: 0 auto;
    }
}

#reservationLightbox{
    #moveIt{
        position: absolute;
        right: 0.8rem;
        top: 0.797rem;
        z-index: 10051;
    }
}